export const FETCH_APP = 'FETCH_APP';
export const FETCH_APP_SUCCESS = 'FETCH_APP_SUCCESS';
export const FETCH_APP_FAIL = 'FETCH_APP_FAIL';
export const UPDATE_APP_STATUS = 'UPDATE_APP_STATUS';
export const SET_LOADING = 'SET_LOADING';
export const UPDATE_DARK_MODE = 'UPDATE_DARK_MODE';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const UPDATE_TOP_BANNER = 'UPDATE_TOP_BANNER';
