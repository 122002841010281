import { IonCol, IonRouterLink, IonRow } from "@ionic/react";

export const Action = props => (
    <IonRow className="ion-text-center ion-justify-content-center">
        <IonCol size="12">
            <p>
                { props.message }
                <IonRouterLink className={props.className} routerLink={ props.link }> { props.text }</IonRouterLink>
            </p>
        </IonCol>
    </IonRow>
);