import { IonContent, IonPage, IonImg, IonTitle, IonHeader, IonToolbar, IonFooter, IonGrid, IonCol, IonRow, IonLabel, IonRouterLink, IonLoading } from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { fetchApps, updateAppStatus, showLoading } from '../../actions';
import {connect} from 'react-redux';
import { Action } from '../../components/Action';
import CustomField from '../../components/CustomField';
import { useRegisterFields } from '../../data/fields';
import { validateForm, formInputed } from '../../data/utils';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import * as moment from 'moment';

const Register = (props) => {
	const fields = useRegisterFields();
	const [ errors, setErrors ] = useState(false);
	const [ lockStatus, setLockStatus ] = useState(false);
	
	const { appInfo, darkMode } = props.appReducer
	let { app_status } = props.appReducer
	app_status = app_status ?? {}
	console.log(app_status)

	const login = async () => {

		const errors = validateForm(fields);
		setErrors(errors);

		if (!errors.length) {
			props.showLoading(true)
			try {
				let response = await API.post_request({
					endPoint: 'auth/check/passcode',
					params: {
						passcode: fields[0].input.state.value,
						attempt: (app_status.register_attempt ?? 0) + 1,
						slug: appInfo.slug
					}
				})
				props.showLoading(false)
				if (response.status == 'validation_error') {
					setErrors(response.errors)

          return;
				} else {
          response = response.data
        }

				app_status = Object.assign(app_status, { can_register: true, email: undefined, id: undefined })
				props.updateAppStatus(app_status)
	
				Swal.fire({  
					icon: 'success',  
					text: response, 
					showConfirmButton: true,
					confirmButtonColor: "#2178C3",
				}); 
			} catch (error) {
				props.showLoading(false)
				Swal.fire({  
					icon: 'error',  
					text: error.message, 
					showConfirmButton: false,
					timer: 3000 
				}); 

				app_status = Object.assign(app_status, {
					register_attempt: (app_status.register_attempt ?? 0) + 1,
					last_register_attempt_at: moment()
				})
				props.updateAppStatus(app_status)
				checkLockStatus()
			}
		}
	}

  const checkLockStatus = () => {
		if ((app_status.register_attempt ?? 0) >= 5) {
			const now = moment()
			if (now.diff(app_status.last_register_attempt_at, 'minutes') <= 5) {
				setLockStatus(true)
			} else {
				props.updateAppStatus(Object.assign(app_status, { register_attempt: 0, last_register_attempt_at: moment() }))
				setLockStatus(false)
			}
		}
	}

  useEffect(() => {
		checkLockStatus()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color) ? (
				<>
					<IonHeader>
						<IonToolbar color="transparent" style={{backgroundColor: darkMode ? '#343A40' : appInfo.color.background_color}}>
							<IonTitle className="ion-text-center font-20 text-bold" style={{color: darkMode ? '#5ECBF7' : appInfo.color.color}}>{appInfo.name}</IonTitle>
						</IonToolbar>
					</IonHeader>
					<IonContent fullscreen>
						{app_status.can_register ? (
							<>
								<IonGrid className="ion-padding main-container d-flex ion-align-items-center ion-justify-content-center flex-direction-column mb-20">
									<IonLabel className="ion-text-center font-24 text-bold text-center px-10 mb-20 main-text-color">ようこそ<br/>{appInfo.name}へ</IonLabel>
									<IonLabel className="ion-text-center font-18 text-center px-10">このアプリを使って地域貢献、より良いまちを作りましょう！そのために、カンタンな設定を行います。</IonLabel>
									<IonRouterLink href={`/${appInfo.slug}/register/email`} className="btn btn-blue height-60 mt-40 border-radius-30 btn-block font-20 line-height-60 text-bold"><div className="w-100 h-100">次へ</div></IonRouterLink>
								</IonGrid>
							</>
						) : (
							<>
								{appInfo.can_register == 1 ? (
									<>
										{lockStatus ? (
											<IonGrid className="ion-padding main-container d-flex ion-align-items-center ion-justify-content-center">
												<IonLabel className="ion-text-center font-18 text-center px-10">現在この接続先からのパスコード認証はロックされています。しばらく時間をおいてから、再度アクセスしてください。</IonLabel>
											</IonGrid>
										) : (
											<IonGrid className="ion-padding main-container d-flex ion-align-items-center ion-justify-content-center">
												<IonRow className="w-100">
													<IonCol size="12" className="d-flex ion-align-items-center ion-justify-content-center mb-24">
														<IonImg src={appInfo.icon_url} className="height-75 width-75 app-icon" />
													</IonCol>
													<IonCol size="12">
														{ fields.map((field, index) => {
															return <CustomField field={ field } key={ index } errors={ errors } />;
														}) }
														<IonLabel className="ion-text-center font-16 text-center">新規利用者登録を行うには、代表利用者（アプリ管理者）より事前発行されている「パスコード」をご入力ください。</IonLabel>
														<button type="button" className={`btn btn-blue height-60 mt-20 border-radius-30 btn-block font-20 line-height-60 text-bold ${formInputed(fields) ? '' : 'btn-disabled'}`} disabled={!formInputed(fields)} onClick={login}>新規利用者登録</button>
													</IonCol>
												</IonRow>
											</IonGrid>
										)}
									</>
								) : (
									<IonGrid className="ion-padding main-container d-flex ion-align-items-center ion-justify-content-center">
										<IonLabel className="ion-text-center font-18 text-center px-10">現在「さくらまち町内会」への新規利用者登録は、管理者により制限中です。</IonLabel>
									</IonGrid>
								)}
							</>
						)}
						<IonFooter>
							<IonGrid className="ion-no-margin ion-no-padding d-flex ion-justify-content-center">
								<Action text="ログイン" className="custom-link mr-20" link={`/${appInfo.slug}/login`} />
								<Action text="パスワードを忘れた" className="custom-link mr-20" link={`/${appInfo.slug}/password/email`} />
							</IonGrid>
						</IonFooter>
					</IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
		appReducer: state.appReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
		showLoading: (value) => dispatch(showLoading(value))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);

