import {
  FETCH_APP,
  FETCH_APP_SUCCESS,
  FETCH_APP_FAIL,
  UPDATE_APP_STATUS,
  SET_LOADING,
  UPDATE_TOP_BANNER,
  UPDATE_DARK_MODE
} from '../config/constant';

const initialState = {
  appInfo: {},
  fetchingApps: false,
  error: false,
  app_status: {},
  showLoading: false,
  topBanner: null,
  darkMode: false
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_APP:
      return {
        ...state,
        // appInfo: {},
        fetchingApps: true,
      };
    case FETCH_APP_SUCCESS:
      return {
        ...state,
        fetchingApps: false,
        appInfo: action.appInfo,
      };
    case FETCH_APP_FAIL:
      return {
        ...state,
        fetchingApps: false,
        error: true,
      };
    case UPDATE_APP_STATUS:
      return {
        ...state,
        app_status: action.status,
        fetchingUser: false,
      }
    case SET_LOADING:
      return {
        ...state,
        showLoading: action.showLoading,
      }
    case UPDATE_TOP_BANNER:
      return {
        ...state,
        topBanner: action.topBanner
      }
    case UPDATE_DARK_MODE:
      return {
        ...state,
        darkMode: action.darkMode
      }
    default:
      return state;
  }
}
