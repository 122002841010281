import { IonContent, IonPage, IonIcon, IonGrid, IonCol, IonRow, IonButton, IonImg, IonBadge, IonSearchbar, IonModal, IonLabel, IonTextarea, IonRouterLink, IonLoading } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, showLoading, updateAuthUser } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import { pencilOutline, closeCircleSharp, addCircleSharp } from 'ionicons/icons';
import Swal from "sweetalert2";  
import Zoom from '../../components/Zoom';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import * as moment from 'moment';

const Detail = (props) => {
  const { category, id } = useParams()
  let history = useHistory();
  const { appInfo } = props.appReducer
  const { me } = props.userReducer
  const [ loading, setLoading ] = useState({ status: false, text: '読込中…' })
  const [ post, setPost ] = useState({})
  const [ categoryItem, setCategoryItem ] = useState({})
  const [ searchText, setSearchText ] = useState('')
  const [ showCommentModal, setShowCommentModal ] = useState(false);
  const [ comment, setComment ] = useState('')
  const [ comments, setComments ] = useState([])
  const [ commentsCount, setCommentsCount ] = useState(0)
  const [ commentLoading, setCommentLoading ] = useState({ status: false, text: '読込中…' })
  const [ page, setPage ] = useState(1)
  const [ hasMore, setHasMore ] = useState(true)
  const contentRef = useRef()
  const [ files, setFiles ] = useState([])
  let tempFile = {}
  const fileRef = useRef()
  const [ showZoomer, setShowZoomer ] = useState({ show: false, id: undefined })
  const [ images, setImages ] = useState([])
  const [ postImages, setPostImages ] = useState([])
  const [ replyImages, setReplyImages ] = useState([])
  let tempPostImages = []
  let firstLoading = true

	const loadPost = async () => {
    try {
      setLoading({ status: true, text: '読込中…' })

      let response = await API.post_request({
        endPoint: `posts/${category}/${id}`,
        params: {}
      })

      setLoading({ status: false, text: '' })
      response = response.data

      setPost(response.post)
      setCommentsCount(parseInt(response.replies_count))

      const postId = parseInt(response.post.id)

      if (!me.post_read_ids.includes(postId)) {
        props.updateAuthUser({...me, ...{ post_read_ids: [...me.post_read_ids, ...[postId]]}})

        API.post_request({
          endPoint: `posts/${category}/${postId}/read`,
          params: {}
        })
      }

      if (response.post.files.length > 0) {
        response.post.files.map(file => {
          if (file.type.indexOf('image/') >= 0 && !file.deleted_at) tempPostImages.push(file)
        })
        setPostImages(tempPostImages)
      }

      if (parseInt(response.replies_count) > 0) {
        setCommentLoading({ status: true, text: comments.length === 0 ? '読込中…' : 'さらに読込中…' })
        loadPostComments()
      } else {
        setImages(tempPostImages)
      }
    } catch (error) {
      history.push(`/${appInfo.slug}/posts/${category}`)
    }
  }

  const loadPostComments = async () => {
    try {
      setCommentLoading({ status: true, text: comments.length === 0 ? '読込中…' : 'さらに読込中…' })
      let lastCommentAt = undefined
      if (comments.length > 0) lastCommentAt = moment(comments[comments.length - 1].created_at).format('YYYY-MM-DD HH:mm:ss')

      let response = await API.post_request({
        endPoint: `posts/${category}/${id}/replies`,
        params: {
          page: 1,
          last_reply_at: lastCommentAt
        }
      })

      setCommentLoading({ status: false, text: '' })
      response = response.data

      setComments([...comments, ...response.data])

      setPage(response.current_page + 1)
      setHasMore(response.last_page > response.current_page)

      let tempImages = [...replyImages]
      response.data.map(reply => {
        if (reply.files.length > 0 && !reply.deleted_at) {
          reply.files.map(file => {
            if (file.type.indexOf('image/') >= 0 && !file.deleted_at) tempImages.push(file)
          })
        }
      })
      setReplyImages(tempImages)
      if (firstLoading) {
        setImages([...tempPostImages, ...tempImages])
        firstLoading = false
        tempPostImages = []
      } else {
        setImages([...postImages, ...tempImages])
      }
    } catch (error) {
      setLoading({ status: false, text: '' })
    }
  }

  const init = async () => {
    if (me.id) {
      let categories = me.profile.my_post_categories.filter(postCategory => postCategory.id === parseInt(category))
      if (categories.length > 0) {
        setCategoryItem({ ...categories[0] })
        loadPost()
      } else {
        history.push(`/${appInfo.slug}/top`)
      }
    }
  }

  const renderPostHead = () => {
    const style = {
      backgroundColor: `${categoryItem.background_color}`
    }
    return (
      <IonCol size="12" className="d-flex align-items-top ion-justify-content-between mb-40 px-20">
        <div className="d-flex align-items-top flex-1 ion-justify-content-between">
          <IonImg src={categoryItem.icon_url} style={style} className="width-50 height-50 px-10 py-10 mr-12 border-radius-round" />
          <div className="flex-1">
            <div className="color-black font-16 mb-10"><span className="text-bold">投稿日</span>：{post.created_at_label}</div>
            <div className="color-black font-16"><span className="text-bold">投稿者</span>：{post.user_name}</div>
          </div>
        </div>
        <div>
          <div className="ml-10 article-title max-height-40 overflow-hidden">
            {/* {me.post_read_ids.includes(post.id) ? (
              <IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-light-gray color-black vertical-align-middle">既読</IonBadge>
            ) : (
              <IonBadge color="success" className="px-8 py-4 border-radius-12 font-12 back-green vertical-align-middle color-white">未読</IonBadge>
            )} */}
            {post.is_new && (<IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-yellow color-black vertical-align-middle">新着</IonBadge>)}
          </div>
        </div>
      </IonCol>
    )
  }

  const renderFiles = (post, isReply = false) => {
    if (post.files.length === 0) {
      return ''
    }
    return (
      <IonCol size="12" className={`d-flex align-items-center ${isReply ? '' : 'px-20 py-10'}`}>
        {post.files.map((file, index) => {
          return (
            <IonImg key={index} className="height-45 width-45 mr-10 border-gray-1 cursor img-cover" src={file.thumb_url} onClick={() => handleFileClick(file)} />
          )
        })}
      </IonCol>
    )
  }

  const handleFileClick = (file) => {
    if (file.deleted_at) {
      Swal.fire({  
        icon: 'warning',  
        text: 'ファイルが管理画面から削除されました。',
        showConfirmButton: false,
      })
      return;
    }
    if (file.type.indexOf('video/') >= 0) {
      let html = `<video class="object-contain" controls controlsList="nodownload" style="max-width: 100%;"><source src="${file.full_url}" /></video>`
      Swal.fire({  
        html: html,
        showConfirmButton: true,
        confirmButtonColor: '#2178C3',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: '閉じる',
      })
    } else if (file.type.indexOf('image/') >= 0) {
      setShowZoomer({
        show: true,
        id: file.id
      })
    } else {
      let fileType = 'その他'
      if (file.type.indexOf('application/pdf') >= 0) 
        fileType = 'PDF'
      else if (file.type.indexOf('officedocument.wordprocessingml') >= 0)
        fileType = 'DOC'
      else if (file.type.indexOf('officedocument.spreadsheetml') >= 0)
        fileType = 'EXCEL'
      else if (file.type.indexOf('application/zip') >= 0)
        fileType = 'ZIP'
      else if (file.type.indexOf('audio/') >= 0)
        fileType = '画像'

      Swal.fire({  
        title: `<strong>添付ファイルの保存</strong>`,
        text: `お使いの端末に${fileType}ファイルをダウンロードしてよろしいですか？`,
        showConfirmButton: true,
        showCancelButton: true,
        customClass: {
          confirmButton: 'custom-cancel-button' //insert class here
        },
        cancelButtonColor: "#2178C3",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'いいえ',
        cancelButtonText: 'はい'
      }).then(async (result) => {
        if (!result.isConfirmed) {
          window.open(`${file.full_url}&d=1`)
        }
      })
    }
  }

  const handleSearch = async (e) => {
    const tempValue = await e.currentTarget.value;
    setSearchText(tempValue);
  }

  const changeComment = async (e) => {
    const tempValue = await e.currentTarget.value;
    setComment(tempValue);
  }

  const sendComment = async (e) => {
    if (comment !== '') {
      props.showLoading(true)
      try {
        let formData = new FormData();
        formData.append('content', comment)
        files.map(item => {
          formData.append('files[]', item.file)
          formData.append('names[]', item.fileName)
          formData.append('types[]', item.type)
          formData.append('sizes[]', item.size)
        })

        let response = await API.post_request({
          endPoint: `posts/${category}/${id}/reply`,
          params: formData
        })
        
        setComments([...[response.data.reply], ...comments])
        setCommentsCount(response.data.replies_count)
        setComment('')
        setFiles([])
        setShowCommentModal(false)

        let reply = response.data.reply
        if (reply.files.length > 0) {
          let imgs = []
          reply.files.map(file => {
            if (file.type.indexOf('image/') >= 0) imgs.push(file)
          })

          setReplyImages([...imgs, ...replyImages])
          setImages([...postImages, ...imgs, ...replyImages])
        }
        props.showLoading(false)

        Swal.fire({  
          icon: 'success',  
          title: `<strong class="sweet-alert-success">返信投稿！</strong>`,
          text: 'またひとつ、まちの活性化に貢献しました！',
          showConfirmButton: true,
          confirmButtonColor: "#2178C3",
        })
      } catch (error) {
        props.showLoading(false)

        Swal.fire({  
          icon: 'error',  
          text: error.message,
          showConfirmButton: false,
        })
      }
    }
  }

  const deleteComment = async (comment) => {
    Swal.fire({  
      title: `<strong>返信を削除</strong>`,
      text: '自分が投稿した返信を、本当に削除してよろしいですか？',
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-cancel-button' //insert class here
      },
      cancelButtonColor: "#2178C3",
      allowEscapeKey: false,
      confirmButtonText: 'いいえ',
      cancelButtonText: 'はい',
      allowOutsideClick: false
    }).then(async (result) => {
      if (!result.isConfirmed) {
        let response = await API.post_request({
          endPoint: `posts/${category}/${id}/${comment.id}/delete`,
          params: {}
        })

        setComments(comments.map(item => {
          if (item.id === comment.id) comment.deleted_at = response.data
          return item
        }))
      }
    })
  }

  const toggleCommentFavorite = async (comment) => {
    try {
      let response = await API.post_request({
        endPoint: `posts/${category}/${id}/${comment.id}/favorite`,
        params: {}
      })

      let newComments = comments.map(item => {
        if (item.id == comment.id) item.favorite_user_ids = response.data
        return item
      })
      
      setComments(newComments)
    } catch (error) {
    }
  }

  const toggleSave = async () => {
    let ids = [...me.post_save_ids]
    if (ids.includes(post.id)) {
      ids = ids.filter(item => item !== post.id)

      Swal.fire({  
        icon: 'success',  
        title: `<strong class="sweet-alert-success">保存をやめました。</strong>`,
        showConfirmButton: true,
        confirmButtonColor: "#2178C3",
      })
    } else {
      ids.push(post.id)

      Swal.fire({  
        icon: 'success',  
        title: `<strong class="sweet-alert-success">話題を保存しました。</strong>`,
        showConfirmButton: true,
        confirmButtonColor: "#2178C3",
      })
    }

    props.updateAuthUser({...me, ...{ post_save_ids: [...ids]}})

    API.post_request({
      endPoint: `posts/${category}/${id}/toggle/save`,
      params: {}
    })
  }

  const toggleFavorite = async () => {
    let ids = [...me.post_favorite_ids]
    if (ids.includes(post.id)) {
      ids = ids.filter(item => item !== post.id)
    } else {
      ids.push(post.id)
    }

    props.updateAuthUser({...me, ...{ post_favorite_ids: [...ids]}})

    try {
      let response = await API.post_request({
        endPoint: `posts/${category}/${id}/toggle/favorite`,
        params: {}
      })

      setPost({...post, ...{favorite_user_ids: response.data}})
    } catch (error) {

    }
  }

  const onScrollHandler = async (e) => {
    if (commentLoading.status) return;

    const scrollElement = await e.target.getScrollElement();

    const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
    const currentScrollDepth = scrollElement.scrollTop;
    const targetPercent = 80;

    let triggerDepth = ((scrollHeight / 100) * targetPercent);

    if(currentScrollDepth > triggerDepth) {
      loadPostComments()
    }
  }
  
  const deletePost = async (e) => {
    Swal.fire({  
      title: `<strong>話題を削除</strong>`,
      text: '自分が投稿した話題を、本当に削除してよろしいですか？',
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-cancel-button' //insert class here
      },
      cancelButtonColor: "#2178C3",
      allowEscapeKey: false,
      confirmButtonText: 'いいえ',
      cancelButtonText: 'はい',
      allowOutsideClick: false
    }).then(async (result) => {
      if (!result.isConfirmed) {
        props.showLoading(true)
        try {
          let response = await API.post_request({
            endPoint: `posts/${category}/${id}/destroy`,
            params: {}
          })

          props.showLoading(false)
          Swal.fire({  
            icon: 'success',  
            title: `<strong class="sweet-alert-success">${response.data.title}</strong>`,
            showConfirmButton: true,
            allowEscapeKey: false,
            confirmButtonColor: "#2178C3",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`/${appInfo.slug}/posts/${category}`)
            }
          })
        } catch (error) {
          props.showLoading(false)

          Swal.fire({  
            icon: 'error',  
            text: error.message,
            showConfirmButton: false,
          })
        }
      }
    })
  }

  const deleteFile = (e, index) => {
    Swal.fire({  
      title: `<strong>添付ファイルを削除</strong>`,
      text: '添付したファイルをアップロードせず、削除しますか？',
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-cancel-button' //insert class here
      },
      cancelButtonColor: "#2178C3",
      allowEscapeKey: false,
      confirmButtonText: 'いいえ',
      cancelButtonText: 'はい',
      allowOutsideClick: false
    }).then(async (result) => {
      if (!result.isConfirmed) {
        let tempFiles = [...files]
        tempFiles = tempFiles.filter((item, ind) => index !== ind)

        setFiles([...tempFiles])
      }
    })
  }

  const changeFile = async (e) => {
    let limit = parseInt(me.profile.my_app.plan.file_size_limit)
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    let size = files[0].size / 1024;
    if (size > limit * 1024) {
      Swal.fire({
        icon: 'warning',
        text: `${limit}MBまで選択してください。`,
        confirmButtonColor: "#2178C3",
      })

      removeFile()
      return;
    }

    tempFile = {
      fileName: files[0].name,
      type: files[0].type,
      size: parseInt(size),
      id: -1,
      image: '',
      file: files[0]
    }
    console.log(files[0].type)
    createFile(files[0])
  }

  const createFile = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (tempFile.type.includes('image')) {
        tempFile.image = e.target.result;
      } else if (tempFile.type.includes('video/')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/video.svg`
      } else if (tempFile.type.includes('application/pdf')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/pdf.svg`
      } else if (tempFile.type.includes('application/zip')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/zip.svg`
      } else if (tempFile.type.includes('officedocument.spreadsheetml')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/excel.svg`
      } else if (tempFile.type.includes('officedocument.wordprocessingml')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/doc.svg`
      } else if (tempFile.type.includes('audio/')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/audio.svg`
      } else {
        tempFile.image = `${appInfo.public_url}/img/thumbs/no-thumbnail.svg`
      }

      let tempFiles = [...files]
      tempFiles.push(tempFile)

      setFiles([...tempFiles])
      tempFile = Object.assign({})
      removeFile()
    };
    reader.readAsDataURL(file);
  }

  const removeFile = () => {
    fileRef.current.value = ''
  }

  const addFile = async (e) => {
    fileRef.current.click()
  }


  const getBreakpoints = () => {
    const { innerHeight: height } = window;
    return [
      0,
      525 / height
    ]
  }

  const getInitialBreakpoint = () => {
    const { innerHeight: height } = window;
    return 525 / height
  }

  useEffect(() => {
    init()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          {showZoomer.show === false && (
            <Header title={categoryItem.name}></Header>
          )}
          <IonContent ref={contentRef} fullscreen onIonScrollEnd={(e) => onScrollHandler(e)} scrollEvents={hasMore}>
            <IonGrid className="ion-padding px-0 py-0">
              <IonRow>
                <Loading loading={loading}></Loading>
                {(post && post.id) && (
                  <>
                    <IonCol size="12" className="font-22 text-bold color-black px-20 pt-20 mb-20 border-bottom-1" expands="block">{post.title}</IonCol>
                    
                    {renderPostHead()}

                    {post.user_id === me.id && (
                      <IonCol size="12" className="mb-0 text-right px-20">
                        <IonRouterLink className="color-orange cursor underline" onClick={(e) => deletePost(e)}>削除</IonRouterLink>
                      </IonCol>
                    )}
                    <IonCol size="12" className="mb-20 html-code px-20 pre-wrap" innerHTML={post.content}></IonCol>

                    {renderFiles(post)}

                    <IonCol size="12" className="d-flex mb-10 align-items-center ion-justify-content-center px-20">
                      {me.post_save_ids.includes(post.id) ? (
                        <IonButton color="transparent" class="btn btn-yellow height-45 line-height-45 flex-1 mr-10 font-20 text-bold border-radius-24" onClick={() => toggleSave()}>保存をやめる</IonButton>
                      ) : (
                        <IonButton color="transparent" class="btn btn-yellow height-45 line-height-45 flex-1 mr-10 font-20 text-bold border-radius-24" onClick={() => toggleSave()}>話題を保存</IonButton>
                      )}
                      {post.user_id === me.id ? (
                        <IonButton color="transparent" class="btn btn-light-blue btn-block height-45 line-height-45 flex-1 font-20 text-bold border-radius-24" disabled={true}>
                          いいね！
                          {post.favorite_user_ids.length > 0 && <IonBadge color="secondary" className="back-blue border-radius-round ml-10 width-24 height-24 text-center line-height-24 py-0 px-0">{post.favorite_user_ids.length}</IonBadge>}
                        </IonButton>
                      ) : (
                        <IonButton color="transparent" class="btn btn-light-blue btn-block height-45 line-height-45 flex-1 font-20 text-bold border-radius-24" onClick={() => toggleFavorite()}>
                          いいね！
                          {post.favorite_user_ids.length > 0 && <IonBadge color="secondary" className="back-blue border-radius-round ml-10 width-24 height-24 text-center line-height-24 py-0 px-0">{post.favorite_user_ids.length}</IonBadge>}
                        </IonButton>
                      )}
                    </IonCol>

                    {commentsCount === 0 ? (
                      <>
                        <IonCol size="12" className="px-20">
                          <IonRow>
                            <IonCol size="12" className="back-light-blue px-20 py-20">
                              <IonRow>
                                <IonCol size="12" className="color-black text-bold mb-12 ion-text-center font-18">
                                  最初の返信をしましょう！
                                </IonCol>
                                <IonCol size="12" className="my-0">
                                  <IonButton className="btn btn-blue btn-block height-60 line-height-60 py-0 ion-text-center border-radius-30 font-20 text-bold" color="transparent" onClick={() => setShowCommentModal(true)}>返信を書く</IonButton>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </>
                    ) : (
                      <>
                        <IonCol size="12" className="font-24 text-bold color-black px-20">話題への返信</IonCol>
                        <IonCol size="12" className="px-20 py-10 back-light-gray mb-10 search-bar">
                          <IonSearchbar value={searchText} onIonChange={e => handleSearch(e)} showCancelButton="never" placeholder="返信をキーワード検索" color="light" className="mx-0 px-0 py-0 height-45"></IonSearchbar>
                        </IonCol>
                        {comments.map((comment, index) => {
                          if (searchText !== '') {
                            if (comment.deleted_at) return ''

                            if (comment.content.indexOf(searchText) < 0 && comment.user_name.indexOf(searchText) < 0 && comment.created_at_label.indexOf(searchText) < 0) {
                              return ''
                            }
                          }
                          if (comment.deleted_at) {
                            if (comment.delete_by == 1) {
                              return (
                                <IonCol key={index} size="12" className="px-20 py-20 back-light-gray ion-text-center my-4 deleted-item">この返信は管理者により削除されました。</IonCol>
                              )
                            }
                            return (
                              <IonCol key={index} size="12" className="px-20 py-20 back-light-gray ion-text-center my-4 deleted-item">この返信は投稿者により削除されました。</IonCol>
                            )
                          }

                          return (
                            <IonCol key={index} size="12" className="px-20 py-10">
                              <IonRow>
                                <IonCol size="12" className="ion-text-right">
                                  {(comment.read_user_ids && comment.read_user_ids.includes(me.id)) ? (
                                    <IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-light-gray color-black vertical-align-middle">既読</IonBadge>
                                  ) : (
                                    <IonBadge color="success" className="px-8 py-4 border-radius-12 font-12 back-green vertical-align-middle color-white">未読</IonBadge>
                                  )}
                                </IonCol>
                                <IonCol size="12" className="color-black pre-wrap" innerHTML={comment.content}></IonCol>
                                {renderFiles(comment, true)}
                                <IonCol size="7" className="d-flex align-items-center">
                                  {comment.user_id !== me.id && (
                                    <div className="max-width-120 mr-8">
                                      <IonButton color="transparent" class="btn btn-light-blue btn-block height-30 line-height-30 flex-1 font-14 text-bold border-radius-24" onClick={() => toggleCommentFavorite(comment)}>
                                        いいね！
                                        {(comment.favorite_user_ids && comment.favorite_user_ids.length > 0) && <IonBadge color="secondary" className="back-blue border-radius-round ml-10 width-20 height-20 text-center line-height-20 py-0 px-0 font-12">{comment.favorite_user_ids.length}</IonBadge>}
                                      </IonButton>
                                    </div>
                                  )}
                                  {comment.user_id === me.id && (
                                    <>
                                      <div className="max-width-120 mr-8">
                                        <IonButton color="transparent" class="btn btn-blue btn-block height-30 line-height-30 flex-1 font-14 text-bold border-radius-24" disabled={true}>
                                          いいね！
                                          {(comment.favorite_user_ids && comment.favorite_user_ids.length > 0) && <IonBadge color="secondary" className="back-blue border-radius-round ml-10 width-20 height-20 text-center line-height-20 py-0 px-0 font-12">{comment.favorite_user_ids.length}</IonBadge>}
                                        </IonButton>
                                      </div>
                                      <div className="max-width-60">
                                        <IonButton color="transparent" class="btn btn-orange btn-block height-30 line-height-30 flex-1 font-14 text-bold border-radius-24" onClick={() => deleteComment(comment)}>削除</IonButton>
                                      </div>
                                    </>
                                  )}
                                </IonCol>
                                <IonCol size="5" className="ion-text-right color-black font-11">
                                  <strong>投稿者</strong>：{comment.user_name}<br/>
                                  <strong>日時</strong>：{comment.created_at_label}<br/>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          )
                        })}
                        <Loading loading={commentLoading}></Loading>
                        {(!commentLoading.status && !hasMore) && (
                          <>
                            {comments.length === 0 ? (
                              <IonCol size="12" className="ion-text-center py-20 px-20">表示できる返信が、見つかりませんでした。</IonCol>
                            ) : (
                              <IonCol size="12" className="ion-text-center py-20 px-20">表示できる返信が、これ以上見つかりませんでした。</IonCol>
                            )}
                          </>
                        )}
                        {hasMore && (
                          <div className="height-140">&nbsp;</div>
                        )}
                      </>
                    )}

                  </>
                )}
              </IonRow>
              {(commentsCount > 0 && showZoomer.show === false) && <IonButton className="btn btn-blue border-radius-round width-56 height-56 ion-text-center py-0 line-height-56 font-10 text-bold btn-comment" color="transparent" onClick={() => setShowCommentModal(true)}>返信</IonButton>}
            </IonGrid>

            <IonModal
              isOpen={showCommentModal}
              cssClass='my-custom-modal'
              swipeToClose={true}
              onDidDismiss={() => setShowCommentModal(false)}
              onIonModalDidDismiss={() => setComment('')}
              breakpoints={getBreakpoints()}
              initialBreakpoint={getInitialBreakpoint()}
              handle={false}>
              <IonRow>
                <IonCol size="12" className="d-flex align-items-center justify-content-center px-20 py-10 main-text-color">
                  <IonIcon icon={pencilOutline} className="mr-10 font-28" />
                  <IonLabel className="font-20 text-bold flex-1">この話題に返信する</IonLabel>
                  <IonIcon icon={closeCircleSharp} className="ml-10 font-28 color-light-gray mx-0 px-0" onClick={() => setShowCommentModal(false)} />
                </IonCol>
                <IonCol size="12" className="flex-1">
                  <IonRow>
                    <IonCol size="12" className="color-black text-bold">返信</IonCol>
                    <IonCol size="12">
                      <IonTextarea placeholder="返信を入力..." rows={10} className="border-gray-1 color-black px-10" value={comment} onIonChange={e => changeComment(e)} onKeyUp={e => changeComment(e)}></IonTextarea>
                    </IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size="12">
                  <IonRow>
                    <IonCol size="12" className="color-black text-bold">添付ファイル</IonCol>
                    {files.length === 0 ? (
                      <IonButton className="height-45 line-height-45 py-0 px-0 border-radius-24 back-light-blue2 color-white font-18 text-bold" color="transparent" onClick={(e) => addFile(e)}>
                        <IonIcon icon={addCircleSharp} className="mr-8" />添付ファイル
                      </IonButton>
                    ) : (
                      <>
                        <IonCol size="12" className="d-flex align-items-center">
                          {files.map((file, index) => {
                            return (
                              <IonImg key={index} className="height-45 width-45 mr-10 border-gray-1 cursor img-cover" onClick={(e) => deleteFile(e, index)} src={file.image} />
                            )
                          })}
                          {files.length < 5 && (
                            <div className="height-45 py-0 px-0 border-radius-0 width-45 back-light-blue2 color-white font-24 text-bold cursor d-flex align-items-center ion-justify-content-center" color="transparent" onClick={(e) => addFile(e)}>
                              <IonIcon icon={addCircleSharp} className="font-24 text-bold" />
                            </div>
                          )}
                        </IonCol>
                      </>
                    )}
                  </IonRow>
                </IonCol>
                <IonCol size="12" className="d-flex mt-20 ion-justify-content-end">
                  <IonButton className={`btn btn-blue height-45 line-height-45 py-0 px-20 ion-text-center border-radius-24 font-20 text-bold ${comment !== '' ? '' : 'btn-disabled'}`} disabled={comment === ''} color="transparent" onClick={(e) => sendComment(e)}>返信を投稿</IonButton>
                </IonCol>
              </IonRow>
            </IonModal>

            <input type="file" ref={fileRef} style={{display: 'none'}} onChange={(e) => changeFile(e)} />

            {showZoomer.show && (
              <Zoom onHideClick={() => setShowZoomer({ show: false, id: undefined})} images={images} id={showZoomer.id}/>
            )}
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Detail);

