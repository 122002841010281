import { IonInput, IonLabel, IonBadge, IonTextarea } from "@ionic/react";

const CustomField = ({field, errors}) => {

    if (field.isLabel) {
        return (
            <>
                <IonLabel className={field.class}>{ field.label }</IonLabel>
            </>
        )
    }

    const error = errors && errors.filter(e => e.id === field.id)[0];
    const errorMessage = error && errors.filter(e => e.id === field.id)[0].message;

    if (field.type === 'select') {
        return (
            <div className={field.class}>
                <div className="flex-1 align-items-center d-flex ion-justify-content-between mr-20">
                    <IonLabel className="font-18 text-bold">{field.label}</IonLabel>
                    {field.showOptionalLabel && <IonBadge color="draft border-radius-4 back-light-gray color-blue">任意</IonBadge>}
                </div>
                <div className="flex-1 height-60">
                    <select className="custom-select" {...field.state}>
                        <option disabled>{field.placeholder}</option>
                        {field.options.map((item, index) => {
                            return <option key={index} value={item}>{item}</option>
                        })}
                    </select>
                </div>
            </div>
        )
    }

    if (field.inputs) {
        const fieldErrors = errors && errors.filter(e => field.ids.includes(e.id))
        let fieldErrorMessage = fieldErrors && errors.filter(e => field.ids.includes(e.id))
        if (fieldErrorMessage.length > 0) {
            fieldErrorMessage = fieldErrorMessage[0].message
        } else {
            fieldErrorMessage = ''
        }

        return (
            <div className="mb-20">
                <div className="mb-10 d-flex align-items-center ion-justify-content-between">
                    <IonLabel className="color-black text-bold font-18">
                        { field.label }
                    </IonLabel>
                    {field.showRequiredLabel && (
                        <IonBadge color="danger" className="border-radius-4 back-red">必須</IonBadge>
                    )}
                </div>
                <div className="d-flex align-items-center ion-justify-content-between">
                    {field.inputs.map(inputField => {
                        return <IonInput key={inputField.id} className={ fieldErrors && fieldErrors.filter(e => e.id == inputField.id).length > 0 ? `height-60 border-radius-30 border-2 px-20 border-orange ${inputField.class && inputField.class}` : `height-60 border-radius-30 border-2 px-20 ${inputField.class && inputField.class}`} { ...inputField.input.props } { ...inputField.input.state } />
                    })}
                </div>
                { fieldErrors && (
                    <p className="animate__animated animate__bounceIn color-orange mt-4 font-14">{ fieldErrorMessage }</p>
                )}
            </div>
        )
    }

    if (field.type === 'textarea') {
        return (
            <div className="mb-20">
                <div className="mb-10 d-flex align-items-center ion-justify-content-between">
                    <IonLabel className="color-black text-bold font-18">
                        { field.label }
                    </IonLabel>
                    {field.showRequiredLabel && (
                        <IonBadge color="danger" className="border-radius-4 back-red">必須</IonBadge>
                    )}
                </div>
                <IonTextarea className={ error ? 'border-radius-30 border-2 px-20 border-orange' : 'border-radius-30 border-2 px-20'} { ...field.input.props } { ...field.input.state }></IonTextarea>
                { error && (
                    <p className="animate__animated animate__bounceIn color-orange mt-4 font-14">{ errorMessage }</p>
                )}
            </div>
        )
    }

    return (
        <div className="mb-20">
            <div className="mb-10 d-flex align-items-center ion-justify-content-between">
                <IonLabel className="color-black text-bold font-18">
                    { field.label }
                </IonLabel>
                {field.showRequiredLabel && (
                    <IonBadge color="danger" className="border-radius-4 back-red">必須</IonBadge>
                )}
            </div>
            <IonInput className={ error ? 'height-60 border-radius-30 border-2 px-20 border-orange' : 'height-60 border-radius-30 border-2 px-20'} { ...field.input.props } { ...field.input.state } />
            { error && (
                <p className="animate__animated animate__bounceIn color-orange mt-4 font-14">{ errorMessage }</p>
            )}
        </div>
    );
};

export default CustomField;