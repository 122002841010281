import { IonContent, IonPage, IonGrid, IonCol, IonRow, IonButton, IonLoading, IonImg } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { fetchApps, updateAppStatus, showLoading, updateAuthUser } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import Header from '../../components/Header';

const Register = (props) => {
  const [ business, setBusiness ] = useState(false);
  let history = useHistory();
  const { id } = useParams()
	
  const { appInfo } = props.appReducer
  const { me } = props.userReducer

	const loadBusiness = async () => {
    props.showLoading(true)
    try {
      let response = await API.post_request({
        endPoint: `business/${id}/show`,
        params: {}
      })
      props.showLoading(false)
      
      setBusiness(response.data)
    } catch (error) {
      props.showLoading(false)

      history.push(`/${appInfo.slug}/business`)
    }
  }

  useEffect(() => {
    loadBusiness()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          <Header title="地域サポート事業者"></Header>
          <IonContent fullscreen>
            <IonGrid className="ion-padding px-16 py-20">
              {business && (
                <IonRow>
                  {business.url && business.url !== '' ? (
                    <IonCol size="12" className="mb-12 px-0 py-0">
                      <a href={business.url} className="business-logo">
                        <IonImg src={business.image_url} />
                      </a>
                    </IonCol>
                  ) : (
                    <IonCol size="12" className="mb-12 px-0 py-0">
                      <div className="business-logo">
                        <IonImg src={business.image_url} />
                      </div>
                    </IonCol>
                  )}
                  <IonCol size="12" className="font-14 px-0 py-0">
                    <span className="text-bold mr-10">事業者：</span>
                    <span className="text-bold">{business.name}</span>
                  </IonCol>
                  <IonCol size="12" className="font-14 px-0 py-0">
                    <span className="text-bold mr-10">所在地：</span>
                    <span>{business.address}</span>
                  </IonCol>
                  <IonCol size="12" className="font-14 px-0 py-0">
                    <span className="text-bold mr-10">代表者：</span>
                    <span>{business.rep_name}</span>
                  </IonCol>
                  <IonCol size="12" className="font-14 px-0 py-0 mt-12 pre-wrap" innerHTML={business.content}></IonCol>
                  {business.url && (
                    <IonCol size="12" className="mt-24 px-0 py-0">
                      <IonButton href={business.url} target="_blank" color="transparent" class="btn btn-green height-60 line-height-60 font-22 text-bold border-radius-30 w-100">ホームページへ</IonButton>
                    </IonCol>
                  )}
                </IonRow>
              )}
            </IonGrid>
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);

