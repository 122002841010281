import { IonCol, IonSpinner, IonText } from "@ionic/react";
import {connect} from 'react-redux';

const Loading = (props) => {
  const { appInfo } = props.appReducer

  return (
    <>
      {(appInfo.slug && appInfo.color) && (
        <>
          {props.loading.status && (
            <IonCol size="12" className="d-flex flex-direction-column ion-justify-content-center align-items-center mt-20">
              <IonSpinner name="crescent" color="secondary main-text-color" />
              <IonText className="ion-text-center mt-10 main-text-color">{props.loading.text}</IonText>
            </IonCol>
          )}
        </>
      )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Loading);

