import { IonContent, IonPage, IonLoading, IonGrid, IonCol, IonRow, IonLabel } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { fetchApps, updateAppStatus, showLoading, updateAuthUser } from '../../actions';
import {connect} from 'react-redux';
import CustomField from '../../components/CustomField';
import { validateForm, formInputed, useFormInput, useHtmlFormInput } from '../../data/utils';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import Header from '../../components/Header';

const RegisterInfo = (props) => {
  const [ errors, setErrors ] = useState(false);
  const [ options, setOptions ] = useState([])
  let history = useHistory();
	
  const { appInfo } = props.appReducer
  const { me } = props.userReducer

  const fields = [
    {
      label: "氏名（姓 / 名）",
      showRequiredLabel: true,
      ids: ['first_name', 'last_name'],
      inputs: [
          {
              id: "first_name",
              required: true,
              input: {
                  props: {
                      type: "text",
                      placeholder: "例）山田"
                  },
                  state: useFormInput(me.profile.first_name)
              },
              class: 'no-border-top-right-radius no-border-bottom-right-radius'
          },
          {
              id: "last_name",
              required: true,
              input: {
                  props: {
                      type: "text",
                      placeholder: "例）太郎"
                  },
                  state: useFormInput(me.profile.last_name)
              },
              class: 'no-border-top-left-radius no-border-bottom-left-radius no-border-left'
          },
      ]
    },
    {
        label: "フリガナ（姓 / 名）",
        showRequiredLabel: true,
        ids: ['first_name_kana', 'last_name_kana'],
        inputs: [
            {
                id: "first_name_kana",
                required: true,
                input: {
                    props: {
                        type: "text",
                        placeholder: "例）ヤマダ"
                    },
                    state: useFormInput("")
                },
                regex: {
                    pattern: /^[ァ-ヶー 　ー]+$/i,
                    message: 'カタカナで入力してください。'
                },
                class: 'no-border-top-right-radius no-border-bottom-right-radius'
            },
            {
                id: "last_name_kana",
                required: true,
                input: {
                    props: {
                        type: "text",
                        placeholder: "例）タロウ"
                    },
                    state: useFormInput("")
                },
                regex: {
                    pattern: /^[ァ-ヶー 　ー]+$/i,
                    message: 'カタカナで入力してください。'
                },
                class: 'no-border-top-left-radius no-border-bottom-left-radius no-border-left'
            },
        ]
    },
    {
        label: 'よろしければ、以下のことも教えて下さい。',
        class: 'font-18 mb-20 text-normal',
        isLabel: true
    },
    {
        label: '年齢',
        class: 'd-flex align-items-center ion-justify-content-between mt-20',
        id: 'age',
        showOptionalLabel: true,
        options: ['２０代未満', '２０代', '３０代', '４０代', '５０代', '６０代', '７０代以上', '答えたくない'],
        placeholder: '選択してください。',
        type: 'select',
        state: useHtmlFormInput("")
    },
    {
        label: '性別',
        class: 'd-flex align-items-center ion-justify-content-between mt-20',
        id: 'gender',
        showOptionalLabel: true,
        options: ['男性', '女性', '答えたくない'],
        placeholder: '選択してください。',
        type: 'select',
        state: useHtmlFormInput("")
    }
  ]

	const setPassword = async () => {

		const errors = validateForm(fields);
		setErrors(errors);
		if (!errors.length) {
      let params = {
        first_name: fields[0].inputs[0].input.state.value,
        last_name: fields[0].inputs[1].input.state.value,
        first_name_kana: fields[1].inputs[0].input.state.value,
        last_name_kana: fields[1].inputs[1].input.state.value,
        age: fields[3].state.value,
        gender: fields[4].state.value,
        options: options,
        user_id: me.id
      }

      props.showLoading(true)
			try {
				let response = await API.post_request({
					endPoint: 'auth/register/info',
					params: params
				})
        props.showLoading(false)

        response = response.data

        const user = response.user

        props.updateAuthUser(user)
        Swal.fire({  
          icon: 'success',  
          title: `<strong class="sweet-alert-success">${response.title}</strong>`,
          text: response.message, 
          showConfirmButton: true,
          allowEscapeKey: false,
          confirmButtonColor: "#2178C3",
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            props.updateAppStatus({})
            if (user.status === 'done') {
              history.push(`/${appInfo.slug}/top`)
            } else {
              history.push(`/${appInfo.slug}/register/organization`)
            }
          }
        })
			} catch (error) {
        props.showLoading(false)

				Swal.fire({  
					icon: 'error',  
					text: error.message, 
					showConfirmButton: false,
					timer: 3000 
				}); 
      }
    }
  }

  const changeOptionCheck = (e, value) => {
    let temp = [...options]
    if (options.includes(value)) {
      temp = temp.filter(item => item != value)
    } else {
      temp.push(value)
    }
    setOptions(temp)
  }
  
  useEffect(() => {
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          <Header title={appInfo.name} href={`/${appInfo.slug}`}></Header>
          <IonContent fullscreen>
            <IonGrid className="ion-padding px-30 py-30">
              <IonRow>
                <IonCol size="12" className="mb-20">
                  <IonLabel className="ion-text-center font-18 px-10">アプリ管理者（{me.profile.my_app.master_name}）へ、あなたのことをお知らせするため、あなたの氏名を教えて下さい。</IonLabel>
                </IonCol>
                <IonCol size="12">
                  { fields.map((field, index) => {
                    return <CustomField field={ field } key={ index } errors={ errors } />;
                  }) }
                  <div className="mt-10">
                    {appInfo.options.map(option => {
                      return (
                        <div className="toggleWrapper mb-10" key={`option_${option.id}`}>
                          <input type="checkbox" className="mobileToggle" id={`option${option.id}`} value={option.id} onChange={e => changeOptionCheck(e, option.id)} />
                          <label htmlFor={`option${option.id}`}>{option.name}</label>
                        </div>
                      )
                    })}
                  </div>
                  <button type="button" className={`btn btn-blue height-60 mt-20 border-radius-30 btn-block font-20 line-height-60 text-bold ${formInputed(fields) ? '' : 'btn-disabled'}`} disabled={!formInputed(fields)} onClick={setPassword}>次へ</button>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterInfo);

