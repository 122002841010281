import { useFormInput, useHtmlFormInput } from "./utils";

export const useSignupFields = () => {

    return [
        {
            id: "password",
            label: "新しいパスワード",
            required: true,
            showRequiredLabel: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "*********"
                },
                state: useFormInput("")
            }
        },
        {
            id: "password_confirmation",
            label: "確認用再入力",
            required: true,
            showRequiredLabel: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "*********"
                },
                state: useFormInput("")
            }
        },
        {
            id: "email",
            label: "メールアドレス",
            required: false,
            input: {
                props: {
                    type: "email",
                    placeholder: "example@example.com"
                },
                state: useFormInput("")
            },
            regex: {
                pattern: /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i,
                message: '正しいメールアドレスを入力してください。'
            }
        },
    ];
}

export const useInviteSignupFields = () => {

    return [
        {
            id: "password",
            label: "新しいパスワード",
            required: true,
            showRequiredLabel: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "*********"
                },
                state: useFormInput("")
            }
        },
        {
            id: "password_confirmation",
            label: "確認用再入力",
            required: true,
            showRequiredLabel: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "*********"
                },
                state: useFormInput("")
            }
        },
        {
            id: "email",
            label: "メールアドレス",
            required: false,
            input: {
                props: {
                    type: "email",
                    placeholder: "example@example.com"
                },
                state: useFormInput("")
            },
            regex: {
                pattern: /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i,
                message: '正しいメールアドレスを入力してください。'
            }
        },
    ];
}

export const useLoginFields = () => {

    return [

        {
            id: "email",
            label: "利用者ID",
            required: true,
            input: {
                props: {
                    type: "email",
                    placeholder: "利用者ID"
                },
                state: useFormInput("")
            }
        },
        {
            id: "password",
            label: "パスワード",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "*******"
                },
                state: useFormInput("")
            }
        }
    ];
}

export const useRegisterFields = () => {

    return [
        {
            id: "passcode",
            label: "パスコード",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "登録に必要なパスコードを入力"
                },
                state: useFormInput("")
            }
        },
    ];
}

export const useRegisterInviteFields = () => {

    return [
        {
            id: "id",
            label: "利用者ID",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "登録に必要な利用者IDを入力"
                },
                state: useFormInput("")
            }
        },
    ];
}

export const useRegisterInfoFields = () => {

    return [
        {
            label: "氏名（姓 / 名）",
            showRequiredLabel: true,
            ids: ['first_name', 'last_name'],
            inputs: [
                {
                    id: "first_name",
                    required: true,
                    input: {
                        props: {
                            type: "text",
                            placeholder: "例）山田"
                        },
                        state: useFormInput("")
                    },
                    class: 'no-border-top-right-radius no-border-bottom-right-radius'
                },
                {
                    id: "last_name",
                    required: true,
                    input: {
                        props: {
                            type: "text",
                            placeholder: "例）太郎"
                        },
                        state: useFormInput("")
                    },
                    class: 'no-border-top-left-radius no-border-bottom-left-radius no-border-left'
                },
            ]
        },
        {
            label: "フリガナ（姓 / 名）",
            showRequiredLabel: true,
            ids: ['first_name_kana', 'last_name_kana'],
            inputs: [
                {
                    id: "first_name_kana",
                    required: true,
                    input: {
                        props: {
                            type: "text",
                            placeholder: "例）ヤマダ"
                        },
                        state: useFormInput("")
                    },
                    regex: {
                        pattern: /^[ァ-ヶー 　ー]+$/i,
                        message: 'カタカナで入力してください。'
                    },
                    class: 'no-border-top-right-radius no-border-bottom-right-radius'
                },
                {
                    id: "last_name_kana",
                    required: true,
                    input: {
                        props: {
                            type: "text",
                            placeholder: "例）タロウ"
                        },
                        state: useFormInput("")
                    },
                    regex: {
                        pattern: /^[ァ-ヶー 　ー]+$/i,
                        message: 'カタカナで入力してください。'
                    },
                    class: 'no-border-top-left-radius no-border-bottom-left-radius no-border-left'
                },
            ]
        },
        {
            label: 'よろしければ、以下のことも教えて下さい。',
            class: 'font-18 mb-20 text-normal',
            isLabel: true
        },
        {
            label: '年齢',
            class: 'd-flex align-items-center ion-justify-content-between mt-20',
            id: 'age',
            showOptionalLabel: true,
            options: ['２０代未満', '２０代', '３０代', '４０代', '５０代', '６０代', '７０代以上', '答えたくない'],
            placeholder: '選択してください。',
            type: 'select',
            state: useHtmlFormInput("")
        },
        {
            label: '性別',
            class: 'd-flex align-items-center ion-justify-content-between mt-20',
            id: 'gender',
            showOptionalLabel: true,
            options: ['男性', '女性', '答えたくない'],
            placeholder: '選択してください。',
            type: 'select',
            state: useHtmlFormInput("")
        }
    ];
}

export const usePasswordEmailFields = () => {

    return [

        {
            id: "email",
            label: "メールアドレス",
            required: true,
            input: {
                props: {
                    type: "email",
                    placeholder: "example@example.com"
                },
                state: useFormInput("")
            },
            regex: {
                pattern: /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i,
                message: '正しいメールアドレスを入力してください。'
            }
        }
    ];
}

export const usePasswordResetFields = () => {

    return [
        {
            id: "password",
            label: "新しいパスワード",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "*********"
                },
                state: useFormInput("")
            }
        },
        {
            id: "password_confirmation",
            label: "確認用再入力",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "*********"
                },
                state: useFormInput("")
            }
        },
    ];
}


export const useProfileEditFirstFields = () => {

    return [
        {
            label: "氏名（姓 / 名）",
            showRequiredLabel: true,
            ids: ['first_name', 'last_name'],
            inputs: [
                {
                    id: "first_name",
                    required: true,
                    input: {
                        props: {
                            type: "text",
                            placeholder: "例）山田"
                        },
                        state: useFormInput("")
                    },
                    class: 'no-border-top-right-radius no-border-bottom-right-radius'
                },
                {
                    id: "last_name",
                    required: true,
                    input: {
                        props: {
                            type: "text",
                            placeholder: "例）太郎"
                        },
                        state: useFormInput("")
                    },
                    class: 'no-border-top-left-radius no-border-bottom-left-radius no-border-left'
                },
            ]
        },
        {
            label: "フリガナ（姓 / 名）",
            showRequiredLabel: true,
            ids: ['first_name_kana', 'last_name_kana'],
            inputs: [
                {
                    id: "first_name_kana",
                    required: true,
                    input: {
                        props: {
                            type: "text",
                            placeholder: "例）ヤマダ"
                        },
                        state: useFormInput("")
                    },
                    regex: {
                        pattern: /^[ァ-ヶー 　ー]+$/i,
                        message: 'カタカナで入力してください。'
                    },
                    class: 'no-border-top-right-radius no-border-bottom-right-radius'
                },
                {
                    id: "last_name_kana",
                    required: true,
                    input: {
                        props: {
                            type: "text",
                            placeholder: "例）タロウ"
                        },
                        state: useFormInput("")
                    },
                    regex: {
                        pattern: /^[ァ-ヶー 　ー]+$/i,
                        message: 'カタカナで入力してください。'
                    },
                    class: 'no-border-top-left-radius no-border-bottom-left-radius no-border-left'
                },
            ]
        },
        {
            label: '年齢',
            class: 'd-flex align-items-center ion-justify-content-between mt-20',
            id: 'age',
            showOptionalLabel: true,
            options: ['２０代未満', '２０代', '３０代', '４０代', '５０代', '６０代', '７０代以上', '答えたくない'],
            placeholder: '選択してください。',
            type: 'select',
            state: useHtmlFormInput("")
        },
        {
            label: '性別',
            class: 'd-flex align-items-center ion-justify-content-between mt-20',
            id: 'gender',
            showOptionalLabel: true,
            options: ['男性', '女性', '答えたくない'],
            placeholder: '選択してください。',
            type: 'select',
            state: useHtmlFormInput("")
        }
    ];
}

export const useProfileEditFields = () => {

    return [
        {
            id: "phone",
            label: "連絡先電話番号",
            required: false,
            input: {
                props: {
                    type: "text",
                    placeholder: "090-0000-0000"
                },
                state: useFormInput("")
            }
        },
        {
            id: "password",
            label: "新しいパスワード",
            required: false,
            input: {
                props: {
                    type: "password",
                    placeholder: "*******"
                },
                state: useFormInput("")
            }
        },
        {
            id: "password_confirmation",
            label: "確認用再入力",
            required: false,
            input: {
                props: {
                    type: "password",
                    placeholder: "*******"
                },
                state: useFormInput("")
            }
        },
        {
            id: "email",
            label: "メールアドレス",
            required: false,
            input: {
                props: {
                    type: "email",
                    placeholder: "*******"
                },
                state: useFormInput("")
            }
        }
    ];
}


export const useBusinessRegisterFields = () => {

    return [
        {
            id: 'name',
            label: '事業者名',
            required: true,
            showRequiredLabel: true,
            input: {
                props: {
                    type: 'text',
                    placeholder: ''
                },
                state: useFormInput('')
            }
        },
        {
            id: 'address',
            label: '事業者所在地',
            required: true,
            showRequiredLabel: true,
            input: {
                props: {
                    type: 'text',
                    placeholder: ''
                },
                state: useFormInput('')
            }
        },
        {
            id: 'content',
            label: '事業者内容',
            required: true,
            showRequiredLabel: true,
            type: 'textarea',
            input: {
                props: {
                    placeholder: '',
                    rows: 10
                },
                state: useFormInput('')
            }
        },
        {
            id: 'rep_name',
            label: '事業代表者名',
            required: true,
            showRequiredLabel: true,
            input: {
                props: {
                    type: 'text',
                    placeholder: ''
                },
                state: useFormInput('')
            }
        },
        {
            id: 'rep_phone',
            label: '事業者連絡先（電話番号）',
            required: true,
            showRequiredLabel: true,
            input: {
                props: {
                    type: 'text',
                    placeholder: "090-0000-0000"
                },
                state: useFormInput('')
            }
        },
        {
            id: 'url',
            label: 'ホームページURL',
            required: false,
            input: {
                props: {
                    type: 'text',
                    placeholder: ""
                },
                state: useFormInput('')
            }
        },
    ];
}