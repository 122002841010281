import { useState } from "react";

export const useFormInput = (initialValue = "") => {

    const [ value, setValue ] = useState(initialValue);
    
    const handleChange = async e => {
			const tempValue = await e.currentTarget.value;
			setValue(tempValue);
    }

    return {
			value,
			reset: (newValue) => setValue(newValue),
			onIonChange: handleChange,
			onKeyUp: handleChange
    };
}

export const useHtmlFormInput = (initialValue = "") => {

	const [ value, setValue ] = useState(initialValue);
	
	const handleChange = async e => {
		const tempValue = await e.currentTarget.value;
		setValue(tempValue);
	}

	return {
		value,
		onChange: handleChange,
		onKeyUp: handleChange
	};
}

export const validateForm = fields => {

	let errors = [];

	fields.forEach(field => {
		if (field.inputs) {
			field.inputs.forEach(subField => {
				if (subField.required) {
					const fieldValue = subField.input.state.value;
					if (fieldValue === "") {
						const error = {
							id: subField.id,
							message: `${ subField.label }は、必須です。`,
						};

						errors.push(error);
					}
				}

				if (subField.regex) {
					const fieldValue = subField.input.state.value;
					if (fieldValue !== '' && !subField.regex.pattern.test(fieldValue)) {
						const error = {
							id: subField.id,
							message: subField.regex.message
						}

						errors.push(error)
					}
				}
			})
		} else {
			if (field.required) {
				const fieldValue = field.input.state.value;
				if (fieldValue === "") {
					const error = {
						id: field.id,
						message: `${ field.label }は、必須です。`,
					};

					errors.push(error);
				}
			}
			if (field.regex) {
				const fieldValue = field.input.state.value;
				if (fieldValue !== '' && !field.regex.pattern.test(fieldValue)) {
					const error = {
						id: field.id,
						message: field.regex.message
					}

					errors.push(error)
				}
			}
		}
	});

	return errors;
}

export const formInputed = fields => {
	let hasAllInputed = true
	fields.forEach(field => {
		if (field.inputs) {
			field.inputs.forEach(subField => {
				if (subField.required) {
					const fieldValue = subField.input.state.value;

					if (fieldValue === "") hasAllInputed = false
				}
			})
		} else {
			if (field.required) {
				const fieldValue = field.input.state.value;
				if (fieldValue === "") hasAllInputed = false
			}
		}
	})

	return hasAllInputed
}