import { API_URL } from '../config/url';
export const axios = require('axios');
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';

export class RemoteService {

  getRequest(url, body = {}) {
    // Get JsonWebToken
    let token = sessionStorage.getItem('auth_token');
    if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return axios.get(url, { params: body })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == 'failed') {
            return {
              status: 'error', 
              message: response.data.message
            }
          }
          return {
            status: 'success', 
            data: response.data.data
          }
        } else {
          return {
            status: 'error',
            message: 'エラーが発生しました。'
          }
        }
      })
      .catch((error) => {
        return {
          status: 'error',
          message: error.message
        }
      })
  }

  postRequest(url, body = {}) {
    // Get JsonWebToken
    let token = sessionStorage.getItem('auth_token');
    if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return axios.post(url, body)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.status == 'failed') {
            return {
              status: 'error', 
              message: response.data.message
            }
          }
          if (response.data.status == 'validation_error') {
            return {
              status: 'validation_error', 
              message: response.data.message
            }
          }
          return {
            status: 'success', 
            data: response.data.data
          }
        } else {
          return {
            status: 'error',
            message: 'エラーが発生しました。'
          }
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          return {
            status: 'validation_error',
            errors: error.response.data.details
          }
        }

        return {
          status: 'error',
          message: error.message
        }
      })
  }

}
