import { IonContent, IonPage, IonIcon, IonGrid, IonCol, IonRow, IonText, IonRouterLink, IonItemDivider, IonLabel, IonLoading } from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, showLoading } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import { chevronForwardOutline } from 'ionicons/icons';
import * as moment from 'moment';
import Header from '../../components/Header';
import Loading from '../../components/Loading';

const Index = (props) => {
  const { appInfo } = props.appReducer
  const { me } = props.userReducer
  const [ loading, setLoading ] = useState({ status: false, text: '読込中…' })
  const [ page, setPage ] = useState(1)
  const [ hasMore, setHasMore ] = useState(true)
  const [ articles, setArticles ] = useState([])
  const contentRef = useRef()
  let lastArticle = undefined
  let lastMonthArticle = undefined
  let weekDays = ['日', '月', '火', '水', '木', '金', '土']

	const loadArticles = async () => {
    try {
      setLoading({ status: true, text: articles.length === 0 ? '読込中…' : 'さらに読込中…' })

      let response = await API.post_request({
        endPoint: `calendar/articles`,
        params: {
          page: page
        }
      })

      setLoading({ status: false, text: '' })
      response = response.data

      setArticles([...articles, ...response.data])

      setPage(response.current_page + 1)
      setHasMore(response.last_page > response.current_page)
    } catch (error) {
      setLoading({ status: false, text: '' })

      Swal.fire({  
        icon: 'error',  
        text: error.message, 
        showConfirmButton: false,
        timer: 3000 
      }); 
    }
  }

  const onScrollHandler = async (e) => {
    if (loading.status) return;

    const scrollElement = await e.target.getScrollElement();

    const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
    const currentScrollDepth = scrollElement.scrollTop;
    const targetPercent = 80;

    let triggerDepth = ((scrollHeight / 100) * targetPercent);

    if(currentScrollDepth > triggerDepth) {
      loadArticles()
    }
  }

  const init = async () => {
    if (me.id) {
      setArticles([])
      setPage(1)
      setHasMore(false)

      loadArticles()
    }
  }

  const renderArticle = (article) => {
    let day = undefined
    let dayOfWeek = undefined
    if (lastArticle === undefined || moment(lastArticle.calendar_at).format('YYYY-MM-DD') !== moment(article.calendar_at).format('YYYY-MM-DD')) {
      day = moment(article.calendar_at).format('DD')
      dayOfWeek = moment(article.calendar_at).day()
    }
    lastArticle = article

    if (lastMonthArticle === undefined || moment(lastMonthArticle.calendar_at).format('YYYY-MM') !== moment(article.calendar_at).format('YYYY-MM')) {
      lastMonthArticle = article;
      let month = moment(lastMonthArticle.calendar_at).format('YYYY年MM月')
      return (
        <>
          <IonItemDivider color="light" className="py-0 color-black-light mb-0 back-light-gray2">
            <IonLabel className="my-0 color-black-light">{month}</IonLabel>
          </IonItemDivider>

          <IonRouterLink routerLink={`/${appInfo.slug}/articles/${article.category_id}/${article.id}`}>
            <div className="d-flex align-items-center ion-justify-content-between">
              <div className="width-50 align-items-top d-flex ion-justify-content-between mx-16">
                {day && (
                  <>
                    <IonText className="font-26 text-bold color-black">{day}</IonText>
                    <IonText className={`font-14 text-bold pt-12 ${dayOfWeek === 0 ? 'color-orange' : 'color-light-gray'}`}>{weekDays[dayOfWeek]}</IonText>
                  </>
                )}
              </div>
              <div className="flex-1 d-flex border-bottom-1 align-items-center justify-content-between py-10 pr-16">
                <IonText className="mr-10 text-bold font-16 color-black word-break-all line-height-20 flex-1 height-20 overflow-hidden">{article.title}</IonText>
                <IonIcon icon={chevronForwardOutline} className="height-32 width-32 color-light-gray ml-10" />
              </div>
            </div>
          </IonRouterLink>
        </>
      )
    }

    return (
      <IonRouterLink routerLink={`/${appInfo.slug}/articles/${article.category_id}/${article.id}`}>
        <div className="d-flex align-items-center ion-justify-content-between">
          <div className="width-50 align-items-top d-flex ion-justify-content-between mx-16">
            {day && (
              <>
                <IonText className="font-26 text-bold color-black">{day}</IonText>
                <IonText className={`font-14 text-bold pt-12 ${dayOfWeek === 0 ? 'color-orange' : 'color-light-gray'}`}>{weekDays[dayOfWeek]}</IonText>
              </>
            )}
          </div>
          <div className="flex-1 d-flex border-bottom-1 align-items-center justify-content-between py-10 pr-16">
            <IonText className="mr-10 text-bold font-16 color-black word-break-all line-height-20 flex-1 height-20 overflow-hidden">{article.title}</IonText>
            <IonIcon icon={chevronForwardOutline} className="height-32 width-32 color-light-gray ml-10" />
          </div>
        </div>
      </IonRouterLink>
    )
  }

  useEffect(() => {
    init()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? ( 
				<>
          <Header title="カレンダー"></Header>
          <IonContent ref={contentRef} fullscreen onIonScrollEnd={(e) => onScrollHandler(e)} scrollEvents={hasMore}>
            <IonGrid className="ion-padding px-0 py-0">
              <IonRow>
                {articles.map((article, index) => {
                  return (
                    <IonCol size="12" key={index} className="mb-0 py-0 px-0">
                      {renderArticle(article)}
                    </IonCol>
                  )
                })}
                <Loading loading={loading}></Loading>
                {(!loading.status && !hasMore) && (
                  <>
                    {articles.length === 0 ? (
                      <IonCol size="12" className="ion-text-center py-20 px-20">表示できる記事が、見つかりませんでした。</IonCol>
                    ) : (
                      <IonCol size="12" className="ion-text-center py-20 px-20">表示できる記事が、これ以上見つかりませんでした。</IonCol>
                    )}
                  </>
                )}
                {hasMore && (
                  <div className="height-100">&nbsp;</div>
                )}
              </IonRow>
            </IonGrid>
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
    showLoading: (value) => dispatch(showLoading(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);

