import { IonContent, IonPage, IonIcon, IonTitle, IonGrid, IonCol, IonRow, IonButton, IonImg, IonBadge, IonSearchbar, IonModal, IonLabel, IonTextarea, IonLoading, IonInput } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, showLoading, updateAuthUser } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import { pencilOutline, closeCircleSharp } from 'ionicons/icons';
import Swal from "sweetalert2";  
import Zoom from '../../components/Zoom';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import * as moment from 'moment';
import styles from './Detail.module.scss';
import { useFormInput } from '../../data/utils';

const Detail = (props) => {
  const { category, id } = useParams()
  let history = useHistory();
  const { appInfo } = props.appReducer
  const { me } = props.userReducer
  const [ loading, setLoading ] = useState({ status: false, text: '読込中…' })
  const [ meeting, setMeeting ] = useState({})
  const [ categoryItem, setCategoryItem ] = useState({})
  const contentRef = useRef()
  const [ showZoomer, setShowZoomer ] = useState({ show: false, id: undefined })
  const [ images, setImages ] = useState([])
  const [ answerType, setAnswerType ] = useState('')
  const [ passCode, setPassCode ] = useState('')
  const [ fieldErrors , setFieldErrors ] = useState([])
  const [ delegationOffice, setDelegationOffice ] = useState('')
  const [ delegationName, setDelegationName ] = useState('')
  const [ subjectAnswers, setSubjectAnswers ] = useState([])

	const loadMeeting = async () => {
    try {
      setLoading({ status: true, text: '読込中…' })

      let response = await API.post_request({
        endPoint: `meetings/${category}/${id}`,
        params: {}
      })

      setLoading({ status: false, text: '' })
      response = response.data

      setMeeting(response.meeting)

      const meetingId = parseInt(response.meeting.id)

      setSubjectAnswers(response.meeting.subjects.map(subject => {
        return {
          type: 'agree',
          comment: ''
        }
      }))

      if (!me.meeting_read_ids.includes(meetingId)) {
        props.updateAuthUser({...me, ...{ meeting_read_ids: [...me.meeting_read_ids, ...[meetingId]]}})

        API.post_request({
          endPoint: `meetings/${category}/${meetingId}/read`,
          params: {}
        })
      }

      let tempPostImages = []
      if (response.meeting.files.length > 0) {
        response.meeting.files.map(file => {
          if (file.type.indexOf('image/') >= 0 && !file.deleted_at) tempPostImages.push(file)
        })
      }
      response.meeting.subjects.map(subject => {
        if (subject.files.length > 0) {
          subject.files.map(file => {
            if (file.type.indexOf('image/') >= 0 && !file.deleted_at) tempPostImages.push(file)
          })
        }
      })
      setImages(tempPostImages)

      if (response.answer) {
        setAnswerType(response.answer.type)
        if (response.answer.type === 'vote') {
          setSubjectAnswers(response.answer.answer)
        }
        if (response.answer.type === 'delegation') {
          setDelegationOffice(response.answer.answer.office)
          setDelegationName(response.answer.answer.name)
        }
      }

    } catch (error) {
      history.push(`/${appInfo.slug}/meetings/${category}`)
    }
  }

  const init = async () => {
    if (me.id) {
      let categories = me.profile.my_meeting_categories.filter(meetingCategory => meetingCategory.id === parseInt(category))
      if (categories.length > 0) {
        setCategoryItem({ ...categories[0] })
        loadMeeting()
      } else {
        history.push(`/${appInfo.slug}/top`)
      }
    }
  }

  const renderMeetingHead = () => {
    const style = {
      backgroundColor: `${categoryItem.background_color}`
    }
    let type = 'unread'
    me.meeting_answers.map(answer => {
      if (answer.meeting_id == meeting.id) {
        if (answer.type === 'delegation') {
          type = 'delegation'
        } else {
          type = 'read'
        }
      }
    })

    return (
      <IonCol size="12" className="d-flex align-items-top ion-justify-content-between mb-40 px-20">
        <div className="d-flex align-items-top flex-1 ion-justify-content-between">
          <IonImg src={categoryItem.icon_url} style={style} className="width-50 height-50 px-10 py-10 mr-12 border-radius-round" />
          {/* <IonImg src={categoryItem.icon_url} style={style} className="width-50 height-50 px-10 py-10 mr-12" /> */}
          <div className="flex-1">
            <div className="color-black font-16"><span className="text-bold">投稿日</span>：{meeting.publish_at_label}</div>
            <div className="color-black font-16"><span className="text-bold">投稿者</span>：{meeting.user_name}</div>
            <div className="color-black font-16"><span className="text-bold">返答期限</span>：{meeting.reply_at_label}</div>
          </div>
        </div>
        <div>
          <div className="ml-10 article-title max-height-40 overflow-hidden">
            {type === 'read' && (
              <IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-light-gray color-black vertical-align-middle">回答済</IonBadge>
            )}
            {type === 'delegation' && (
              <IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-library color-black vertical-align-middle color-white">委　任</IonBadge>
            )}
            {type === 'unread' && (
              <IonBadge color="success" className="px-8 py-4 border-radius-12 font-12 back-pink vertical-align-middle color-white">未回答</IonBadge>
            )}
          </div>
        </div>
      </IonCol>
    )
  }

  const renderFiles = (meeting) => {
    if (meeting.files.length === 0) {
      return ''
    }
    return (
      <IonCol size="12" className="d-flex align-items-center px-20 py-10">
        {meeting.files.map((file, index) => {
          return (
            <IonImg key={index} className="height-45 width-45 mr-10 border-gray-1 cursor img-cover" src={file.thumb_url} onClick={() => handleFileClick(file)} />
          )
        })}
      </IonCol>
    )
  }

  const renderSubjectFiles = (subject) => {
    if (subject.files.length === 0) {
      return ''
    }
    return (
      <IonCol size="12" className="d-flex align-items-center px-20 py-10">
        {subject.files.map((file, index) => {
          return (
            <IonImg key={index} className="height-45 width-45 mr-10 border-gray-1 cursor img-cover" src={file.thumb_url} onClick={() => handleFileClick(file)} />
          )
        })}
      </IonCol>
    )
  }

  const handleFileClick = (file) => {
    if (file.deleted_at) {
      Swal.fire({  
        icon: 'warning',  
        text: 'ファイルが管理画面から削除されました。',
        showConfirmButton: false,
      })
      return;
    }
    if (file.type.indexOf('video/') >= 0) {
      let html = `<video class="object-contain" controls controlsList="nodownload" style="max-width: 100%;"><source src="${file.full_url}" /></video>`
      Swal.fire({  
        html: html,
        showConfirmButton: true,
        confirmButtonColor: '#2178C3',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: '閉じる',
      })
    } else if (file.type.indexOf('image/') >= 0) {
      setShowZoomer({
        show: true,
        id: file.id
      })
    } else {
      let fileType = 'その他'
      if (file.type.indexOf('application/pdf') >= 0) 
        fileType = 'PDF'
      else if (file.type.indexOf('officedocument.wordprocessingml') >= 0)
        fileType = 'DOC'
      else if (file.type.indexOf('officedocument.spreadsheetml') >= 0)
        fileType = 'EXCEL'
      else if (file.type.indexOf('application/zip') >= 0)
        fileType = 'ZIP'
      else if (file.type.indexOf('audio/') >= 0)
        fileType = '画像'

      Swal.fire({  
        title: `<strong>添付ファイルの保存</strong>`,
        text: `お使いの端末に${fileType}ファイルをダウンロードしてよろしいですか？`,
        showConfirmButton: true,
        showCancelButton: true,
        customClass: {
          confirmButton: 'custom-cancel-button' //insert class here
        },
        cancelButtonColor: "#2178C3",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'いいえ',
        cancelButtonText: 'はい'
      }).then(async (result) => {
        if (!result.isConfirmed) {
          window.open(`${file.full_url}&d=1`)
        }
      })
    }
  }

  const handleChangePasscode = async (e) => {
    const tempValue = await e.currentTarget.value;
    setPassCode(tempValue);
  }

  const handleChangeDelegationOffice = async (e) => {
    const tempValue = await e.currentTarget.value;
    setDelegationOffice(tempValue);
  }

  const handleChangeDelegationName = async (e) => {
    const tempValue = await e.currentTarget.value;
    setDelegationName(tempValue);
  }

  const fieldErrorMessage = (key) => {
    const temp = fieldErrors.filter(e => e.id === key)
    if (temp.length > 0) {
      return temp[0].message
    }
    
    return ''
  }
  
  const handleSubmit = async () => {
    let temp = []
    if (answerType === 'delegation') {
      if (delegationOffice === '') {
        temp.push({
          id: 'delegationOffice',
          message: '代理人の所属等が未入力です。'
        })
      }

      if (delegationName === '') {
        temp.push({
          id: 'delegationName',
          message: '代理人の氏名が未入力です。'
        })
      }

      if (meeting.need_verify === 1 && appInfo.passcode !== passCode) {
        temp.push({
          id: 'passcode',
          message: 'パスワードが違います。'
        })
      }
    }

    if (answerType === 'vote') {
      if (meeting.need_verify === 1 && appInfo.passcode != passCode) {
        temp.push({
          id: 'passcode',
          message: 'パスワードが違います。'
        })
      }
    }

    if (temp.length > 0) {
      setFieldErrors(temp)
      return
    }

    setFieldErrors([])

    let params = {
      type: answerType,
      answer: []
    }

    if (answerType === 'vote') params.answer = subjectAnswers
    if (answerType === 'delegation') {
      params.answer = {
        office: delegationOffice,
        name: delegationName
      }
    }

    try {
      props.showLoading(true)
      let response = await API.post_request({
        endPoint: `meetings/${category}/${meeting.id}/answer`,
        params: params
      })

      props.showLoading(false)

      const user = response.data.user

      props.updateAuthUser(user)

      Swal.fire({
        icon: 'success',  
        title: `<strong class="sweet-alert-success">回答を投稿！</strong>`,
        text: 'またひとつ、まちの活性化に貢献しました！',
        showConfirmButton: true,
        confirmButtonColor: "#2178C3",
      })
    } catch (error) {
      props.showLoading(false)

      Swal.fire({  
        icon: 'error',  
        text: error.message,
        showConfirmButton: false,
      })
    }
  }

  const handleSubjectAnswerType = (index, type) => {
    setSubjectAnswers(subjectAnswers.map((answer, ind) => {
      if (ind === index) answer.type = type
      return answer
    }))
  }

  const changeComment = async (e, index) => {
    const tempValue = await e.currentTarget.value;
    setSubjectAnswers(subjectAnswers.map((answer, ind) => {
      if (ind === index) answer.comment = tempValue
      return answer
    }))
  }

  useEffect(() => {
    init()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? ( 
				<>
          {showZoomer.show === false && (
            <Header title={categoryItem.name}></Header>
          )}
          <IonContent ref={contentRef} fullscreen>
            <IonGrid className="ion-padding px-0 py-0">
              <IonRow>
                <Loading loading={loading}></Loading>
                {(meeting && meeting.id) && (
                  <>
                    <IonCol size="12" className="font-22 text-bold color-black px-20 py-20 mb-20 border-bottom-1" expands="block">{meeting.title}</IonCol>
                    
                    {renderMeetingHead()}

                    <IonCol size="12" className="mb-20 html-code px-20" innerHTML={meeting.content}></IonCol>

                    {renderFiles(meeting)}

                    <IonCol size="12" className="px-20 font-24 text-bold mb-10">記事への回答</IonCol>
                    
                    <IonCol size="12" className="px-20 mb-20">
                      {meeting.is_option1 === 1 && (
                        <div className="d-flex align-items-center mb-10 cursor" onClick={(e) => setAnswerType('attend')}>
                          <IonImg className={styles.checkImage} src={`/assets/${answerType === 'attend' ? 'check.png' : 'uncheck.png'}`} />
                          <div className={`text-bold font-16 ${answerType === 'attend' ? 'color-blue' : 'color-black'} border-bottom-1 py-10 flex-1`}>総会出席</div>
                        </div>
                      )}
                      {meeting.is_option2 === 1 && (
                        <div className="d-flex align-items-center mb-10 cursor" onClick={(e) => setAnswerType('vote')}>
                          <IonImg className={styles.checkImage} src={`/assets/${answerType === 'vote' ? 'check.png' : 'uncheck.png'}`} />
                          <div className={`text-bold font-16 ${answerType === 'vote' ? 'color-blue' : 'color-black'} border-bottom-1 py-10 flex-1`}>議決権行使</div>
                        </div>
                      )}
                      {meeting.is_option3 === 1 && (
                        <div className="d-flex align-items-center mb-10 cursor" onClick={(e) => setAnswerType('delegation')}>
                          <IonImg className={styles.checkImage} src={`/assets/${answerType === 'delegation' ? 'check.png' : 'uncheck.png'}`} />
                          <div className={`text-bold font-16 ${answerType === 'delegation' ? 'color-blue' : 'color-black'} border-bottom-1 py-10 flex-1`}>委　任</div>
                        </div>
                      )}
                    </IonCol>

                    {!showZoomer.show && (
                      <>
                        {answerType === 'vote' && (
                          <>
                            {meeting.need_verify === 1 && (
                              <IonCol size="12" className="mb-20 px-20">
                                <div className="px-20 py-20 border-1 color-contact border-yellow">
                                  議決に回答するには、本人確認のため、パスワードを入力する必要があります。
                                </div>
                              </IonCol>
                            )}

                            {meeting.subjects.map((subject, index) => {
                              return (
                                <IonCol size="12" className="mb-20 px-20" key={`subject_${index}`}>
                                  <IonRow>
                                    <IonCol size="12" className="mb-10 text-bold font-16">
                                      {index + 1}. {subject.title}
                                    </IonCol>
                                    {renderSubjectFiles(subject)}
                                    <IonCol size="12" className="d-flex align-items-center justify-content-center">
                                      <div className={subjectAnswers[index].type === 'agree' ? styles.agree : styles.disagree} onClick={() => handleSubjectAnswerType(index, 'agree')}>賛成</div>
                                      <div className={subjectAnswers[index].type === 'disagree' ? styles.agree : styles.disagree} onClick={() => handleSubjectAnswerType(index, 'disagree')}>反対</div>
                                    </IonCol>
                                    {meeting.need_comment === 1 && (
                                      <>
                                        <IonCol size="12" className="mt-10 font-16 text-bold mb-10">コメント（任意）</IonCol>
                                        <IonTextarea placeholder="コメントを入力..." rows={4} className="border-gray-1 color-black px-10" value={subjectAnswers[index].comment} onIonChange={e => changeComment(e, index)} onKeyUp={e => changeComment(e, index)}></IonTextarea>
                                      </>
                                    )}
                                  </IonRow>
                                </IonCol>
                              )
                            })}

                            {meeting.need_verify === 1 && (
                              <>
                                <IonCol size="12" className="mb-20 px-20 font-16 mb-10 text-bold">
                                  本人確認のためのパスワード
                                </IonCol>

                                <IonCol size="12" className="mb-20 px-20 font-16 mb-10">
                                  <IonInput className={ fieldErrors && fieldErrors.filter(e => e.id == 'passcode').length > 0 ? `height-60 border-gray-1 px-20 border-orange` : `height-60 border-gray-1 px-20`} type="text" value={passCode} onIonChange={(e) => handleChangePasscode(e)} onKeyUp={e => handleChangePasscode(e)} />

                                  { fieldErrorMessage('passcode') !== '' && (
                                    <p className="animate__animated animate__bounceIn color-orange mt-4 font-14">{ fieldErrorMessage('passcode') }</p>
                                  )}
                                </IonCol>
                              </>
                            )}

                            <IonCol size="12" className="px-20 font-12 color-gray">
                              まちにてぃにログインするためのパスワードを入力してください。パスワードをお忘れの方は、こちらまたは代表利用者へお尋ねください。
                            </IonCol>
                          </>
                        )}

                        {answerType === 'delegation' && (
                          <>
                            {meeting.need_verify === 1 && (
                              <IonCol size="12" className="mb-20 px-20">
                                <div className="px-20 py-20 border-1 color-contact border-yellow">
                                  議決に回答するには、本人確認のため、パスワードを入力する必要があります。
                                </div>
                              </IonCol>
                            )}

                            <IonCol size="12" className="mb-20 px-20 font-16 mb-10 text-bold">
                              代理人の所属等
                            </IonCol>

                            <IonCol size="12" className="mb-20 px-20 font-16 mb-10">
                              <IonInput className={ fieldErrors && fieldErrors.filter(e => e.id == 'delegationOffice').length > 0 ? `height-60 border-gray-1 px-20 border-orange` : `height-60 border-gray-1 px-20`} type="text" value={delegationOffice} onIonChange={(e) => handleChangeDelegationOffice(e)} onKeyUp={e => handleChangeDelegationOffice(e)} />
                              
                              { fieldErrorMessage('delegationOffice') !== '' && (
                                  <p className="animate__animated animate__bounceIn color-orange mt-4 font-14">{ fieldErrorMessage('delegationOffice') }</p>
                              )}
                            </IonCol>

                            <IonCol size="12" className="mb-20 px-20 font-16 mb-10 text-bold">
                              代理人の氏名
                            </IonCol>

                            <IonCol size="12" className="mb-20 px-20 font-16 mb-10">
                              <IonInput className={ fieldErrors && fieldErrors.filter(e => e.id == 'delegationName').length > 0 ? `height-60 border-gray-1 px-20 border-orange` : `height-60 border-gray-1 px-20`} type="text" value={delegationName} onIonChange={(e) => handleChangeDelegationName(e)} onKeyUp={e => handleChangeDelegationName(e)} />
                              
                              { fieldErrorMessage('delegationName') !== '' && (
                                  <p className="animate__animated animate__bounceIn color-orange mt-4 font-14">{ fieldErrorMessage('delegationName') }</p>
                              )}
                            </IonCol>

                            {meeting.need_verify === 1 && (
                              <>
                                <IonCol size="12" className="mb-20 px-20 font-16 mb-10 text-bold">
                                  本人確認のためのパスワード
                                </IonCol>

                                <IonCol size="12" className="mb-20 px-20 font-16 mb-10">
                                  <IonInput className={ fieldErrors && fieldErrors.filter(e => e.id == 'passcode').length > 0 ? `height-60 border-gray-1 px-20 border-orange` : `height-60 border-gray-1 px-20`} type="text" value={passCode} onIonChange={(e) => handleChangePasscode(e)} onKeyUp={e => handleChangePasscode(e)} />

                                  { fieldErrorMessage('passcode') !== '' && (
                                      <p className="animate__animated animate__bounceIn color-orange mt-4 font-14">{ fieldErrorMessage('passcode') }</p>
                                  )}
                                </IonCol>
                              </>
                            )}

                            <IonCol size="12" className="px-20 font-12 color-gray">
                              まちにてぃにログインするためのパスワードを入力してください。パスワードをお忘れの方は、こちらまたは代表利用者へお尋ねください。
                            </IonCol>
                          </>
                        )}
                      </>
                    )}

                    {meeting.can_reply && (
                      <IonCol size="12" className="px-20">
                        <IonButton className={`btn btn-blue height-60 mt-20 border-radius-30 btn-block font-20 line-height-60 text-bold ${answerType !== '' ? '' : 'btn-disabled'}`} disabled={answerType === ''} onClick={handleSubmit} color="transparent">回答を送信</IonButton>
                      </IonCol>
                    )}
                    <IonCol size="12" className="mb-40"></IonCol>
                  </>
                )}
              </IonRow>
            </IonGrid>

            {showZoomer.show && (
              <Zoom onHideClick={() => setShowZoomer({ show: false, id: undefined})} images={images} id={showZoomer.id}/>
            )}
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Detail);

