import { IonContent, IonPage, IonIcon, IonGrid, IonCol, IonRow, IonButton, IonText, IonRouterLink, IonBadge, IonImg, IonLabel, IonModal, IonTextarea, IonInput, IonLoading } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, showLoading } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import { chevronForwardOutline, closeCircleSharp, pencilOutline, addCircleSharp   } from 'ionicons/icons';
import Header from '../../components/Header';
import Loading from '../../components/Loading';

const Index = (props) => {
  const { category } = useParams()
  let history = useHistory();
  const { appInfo } = props.appReducer
  const { me } = props.userReducer
  const [ loading, setLoading ] = useState({ status: false, text: '読込中…' })
  const [ page, setPage ] = useState(1)
  const [ hasMore, setHasMore ] = useState(true)
  const [ categoryItem, setCategoryItem ] = useState({})
  const [ posts, setPosts ] = useState([])
  const contentRef = useRef()
  const fileRef = useRef()
  const [ categoryItemStyle, setCategoryItemStyle ] = useState({})
  const [ summary, setSummary ] = useState({})
  const [ showPostModal, setShowPostModal ] = useState(false)
  const [ content, setContent ] = useState('')
  const [ title, setTitle ] = useState('')
  const [ files, setFiles ] = useState([])
  let tempFile = {}

	const loadPosts = async () => {
    try {
      setLoading({ status: true, text: posts.length === 0 ? '読込中…' : 'さらに読込中…' })

      let response = await API.post_request({
        endPoint: `posts/${category}`,
        params: {
          page: page
        }
      })

      setLoading({ status: false, text: '' })
      response = response.data

      setPosts([...posts, ...response.data])

      setPage(response.current_page + 1)
      setHasMore(response.last_page > response.current_page)
    } catch (error) {
      setLoading({ status: false, text: '' })

      Swal.fire({  
        icon: 'error',  
        text: error.message, 
        showConfirmButton: false,
        timer: 3000 
      }); 
    }
  }

  const loadSummary = async () => {
    try {
      let response = await API.post_request({
        endPoint: `summary/posts/${category}`,
        params: {
          page: page
        }
      })
      
      setSummary(response.data)
    } catch (error) {
    }
  }

  const onScrollHandler = async (e) => {
    if (loading.status) return;

    const scrollElement = await e.target.getScrollElement();

    const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
    const currentScrollDepth = scrollElement.scrollTop;
    const targetPercent = 80;

    let triggerDepth = ((scrollHeight / 100) * targetPercent);

    if(currentScrollDepth > triggerDepth) {
      loadPosts()
    }
  }

  const init = async () => {
    if (me.id) {
      let categories = me.profile.my_post_categories.filter(item => item.id == category)
      if (categories.length > 0) {
        setCategoryItem({ ...categories[0] })
        setPosts([])
        setPage(1)
        setHasMore(false)

        setCategoryItemStyle({
          backgroundColor: `${categories[0].background_color}`
        })

        loadSummary()
        loadPosts()
      } else {
        history.push(`/${appInfo.slug}/top`)
      }
    }
  }

  const sendPost = async () => {
    if (content !== '' && title !== '') {
      props.showLoading(true)
      try {
        let formData = new FormData();
        formData.append('title', title)
        formData.append('content', content)
        files.map(item => {
          formData.append('files[]', item.file)
          formData.append('names[]', item.fileName)
          formData.append('types[]', item.type)
          formData.append('sizes[]', item.size)
        })
        
        let response = await API.post_request({
          endPoint: `store/posts/${category}`,
          params: formData
        })
        
        setPosts([...[response.data.post], ...posts])
        props.showLoading(false)
        setContent('')
        setTitle('')
        setShowPostModal(false)

        Swal.fire({  
          icon: 'success',  
          title: `<strong class="sweet-alert-success">${response.data.title}</strong>`,
          text: response.data.message,
          showConfirmButton: true,
          confirmButtonColor: "#2178C3",
        })
      } catch (error) {
        props.showLoading(false)

        Swal.fire({  
          icon: 'error',  
          text: error.message,
          showConfirmButton: false,
        })
      }
    }
  }

  const resetPost = () => {
    setTitle('')
    setContent('')
    setFiles([])
  }

  const changeContent = async (e) => {
    const tempValue = await e.currentTarget.value;
    setContent(tempValue);
  }

  const changeTitle = async (e) => {
    const tempValue = await e.currentTarget.value;
    setTitle(tempValue);
  }

  const renderPost = (post) => {
    // const style = {
    //   backgroundColor: `${categoryItem.background_color}`
    // }
    return (
      <IonRouterLink routerLink={`/${appInfo.slug}/posts/${category}/${post.id}`}>
        <div className="d-flex align-items-center ion-justify-content-between border-bottom-1 py-10">
          <div className="d-flex align-items-top flex-1 ion-justify-content-between">
            {/* <IonImg src={categoryItem.icon_url} style={style} className="width-50 height-50 px-10 py-10 mr-12 border-radius-round" /> */}
            <div className="flex-1">
              <div className="mb-10 article-title max-height-40 overflow-hidden">
                {/* {me.post_read_ids.includes(post.id) ? (
                  <IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-light-gray color-black vertical-align-middle">既読</IonBadge>
                ) : (
                  <IonBadge color="success" className="px-8 py-4 border-radius-12 font-12 back-green vertical-align-middle color-white">未読</IonBadge>
                )} */}
                {post.is_new && (
                  <IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-yellow color-black vertical-align-middle">新着</IonBadge>
                )}
                <IonText className="ml-8 text-bold font-16 color-black word-break-all line-height-20">{post.title}</IonText>
              </div>
              <div className="d-flex align-items-center justify-content-between color-black-light font-12 w-100">
                <div className="flex-1">投稿日：{post.created_at_label}</div>
                <div>投稿者：{post.user_name}</div>
              </div>
            </div>
          </div>
          <div>
            <IonIcon icon={chevronForwardOutline} className="height-32 width-32 color-light-gray ml-10" />
          </div>
        </div>
      </IonRouterLink>
    )
  }

  const deleteFile = (e, index) => {
    Swal.fire({  
      title: `<strong>添付ファイルを削除</strong>`,
      text: '添付したファイルをアップロードせず、削除しますか？',
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-cancel-button' //insert class here
      },
      cancelButtonColor: "#2178C3",
      allowEscapeKey: false,
      confirmButtonText: 'いいえ',
      cancelButtonText: 'はい',
      allowOutsideClick: false
    }).then(async (result) => {
      if (!result.isConfirmed) {
        let tempFiles = [...files]
        tempFiles = tempFiles.filter((item, ind) => index !== ind)

        setFiles([...tempFiles])
      }
    })
  }

  const changeFile = async (e) => {
    let limit = parseInt(me.profile.my_app.plan.file_size_limit)
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    let size = files[0].size / 1024;
    if (size > limit * 1024) {
      Swal.fire({
        icon: 'warning',
        text: `${limit}MBまで選択してください。`,
        confirmButtonColor: "#2178C3",
      })

      removeFile()
      return;
    }

    tempFile = {
      fileName: files[0].name,
      type: files[0].type,
      size: parseInt(size),
      id: -1,
      image: '',
      file: files[0]
    }
    createFile(files[0])
  }

  const createFile = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (tempFile.type.includes('image')) {
        tempFile.image = e.target.result;
      } else if (tempFile.type.includes('video/')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/video.svg`
      } else if (tempFile.type.includes('application/pdf')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/pdf.svg`
      } else if (tempFile.type.includes('application/zip')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/zip.svg`
      } else if (tempFile.type.includes('officedocument.spreadsheetml')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/excel.svg`
      } else if (tempFile.type.includes('officedocument.wordprocessingml')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/doc.svg`
      } else if (tempFile.type.includes('audio/')) {
        tempFile.image = `${appInfo.public_url}/img/thumbs/audio.svg`
      } else {
        tempFile.image = `${appInfo.public_url}/img/thumbs/no-thumbnail.svg`
      }

      let tempFiles = [...files]
      tempFiles.push(tempFile)

      setFiles([...tempFiles])
      tempFile = Object.assign({})
      removeFile()
    };
    reader.readAsDataURL(file);
  }

  const removeFile = () => {
    fileRef.current.value = ''
  }

  const addFile = async (e) => {
    fileRef.current.click()
  }

  const getBreakpoints = () => {
    const { innerHeight: height } = window;
    return [
      0,
      610 / height
    ]
  }

  const getInitialBreakpoint = () => {
    const { innerHeight: height } = window;
    return 610 / height
  }

  useEffect(() => {
    init()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? ( 
				<>
          <Header title={categoryItem.name}></Header>
          <IonContent ref={contentRef} fullscreen onIonScrollEnd={(e) => onScrollHandler(e)} scrollEvents={hasMore}>
            <IonGrid className="ion-padding px-10 py-10">
              <IonRow>
                {categoryItem.id && (
                  <>
                    <IonCol size="12" className="d-flex align-items-top ion-justify-content-between">
                      <IonImg src={categoryItem.icon_url} className="width-100 height-100 mr-10 px-10 py-10" style={categoryItemStyle} />
                      <IonRow className="flex-1">
                        <IonCol size="12" className="color-black font-18 text-bold mb-10 px-0 py-0">{categoryItem.name}</IonCol>
                        {summary.posts_count && (
                          <>
                            <IonCol size="12" className="color-black font-14 mb-0 px-0 py-0"><strong>話題数</strong>：{summary.posts_count}件</IonCol>
                            <IonCol size="12" className="color-black font-14 mb-0 px-0 py-0"><strong>返信数</strong>：{summary.replies_count}件</IonCol>
                            <IonCol size="12" className="color-black font-14 mb-0 px-0 py-0"><strong>最終日</strong>：{summary.last_at}</IonCol>
                          </>
                        )}
                      </IonRow>
                    </IonCol>
                    {categoryItem.content && (
                      <IonCol size="12" className="html-code mb-10" innerHTML={categoryItem.content}></IonCol>
                    )}
                  </>
                )}
                {posts.map((post, index) => {
                  return (
                    <IonCol size="12" key={index}>
                      {renderPost(post)}
                    </IonCol>
                  )
                })}
                <Loading loading={loading}></Loading>
                {(!loading.status && !hasMore) && (
                  <>
                    {posts.length === 0 ? (
                      <IonCol size="12" className="ion-text-center py-20 px-20">表示できる話題が、見つかりませんでした。</IonCol>
                    ) : (
                      <IonCol size="12" className="ion-text-center py-20 px-20">表示できる話題が、これ以上見つかりませんでした。</IonCol>
                    )}
                  </>
                )}
                {hasMore && (
                  <div className="height-100">&nbsp;</div>
                )}
              </IonRow>
            </IonGrid>
            <IonButton className="btn btn-blue border-radius-round width-56 height-56 ion-text-center py-0 line-height-56 font-10 text-bold btn-comment" color="transparent" onClick={() => setShowPostModal(true)}>新規話題</IonButton>

            <IonModal
              isOpen={showPostModal}
              cssClass='my-custom-modal'
              swipeToClose={true}
              onDidDismiss={() => setShowPostModal(false)}
              onIonModalDidDismiss={() => resetPost()}
              breakpoints={getBreakpoints()}
              initialBreakpoint={getInitialBreakpoint()}
              handle={false}>
              <IonRow>
                <IonCol size="12" className="d-flex align-items-center justify-content-center px-20 py-10 main-text-color">
                  <IonIcon icon={pencilOutline} className="mr-10 font-28" />
                  <IonLabel className="font-20 text-bold flex-1">{categoryItem.name}に話題を投稿</IonLabel>
                  <IonIcon icon={closeCircleSharp} className="ml-10 font-28 color-light-gray mx-0 px-0" onClick={() => setShowPostModal(false)} />
                </IonCol>
                <IonCol size="12" className="flex-1">
                  <IonRow>
                    <IonCol size="12" className="color-black text-bold">タイトル</IonCol>
                    <IonCol size="12" className="mb-20">
                      <IonInput placeholder="（例）公園でみかけた風景です！..." className="border-gray-1 color-black px-10" value={title} onIonChange={e => changeTitle(e)} onKeyUp={e => changeTitle(e)}></IonInput>
                    </IonCol>
                    <IonCol size="12" className="color-black text-bold">メッセージ</IonCol>
                    <IonCol size="12">
                      <IonTextarea placeholder="メッセージを入力..." rows={10} className="border-gray-1 color-black px-10" value={content} onIonChange={e => changeContent(e)} onKeyUp={e => changeContent(e)}></IonTextarea>
                    </IonCol>
                    <IonCol size="12" className="color-black text-bold">添付ファイル</IonCol>
                    {files.length === 0 ? (
                      <IonButton className="height-45 line-height-45 py-0 px-0 border-radius-24 back-light-blue2 color-white font-18 text-bold" color="transparent" onClick={(e) => addFile(e)}>
                        <IonIcon icon={addCircleSharp} className="mr-8" />添付ファイル
                      </IonButton>
                    ) : (
                      <>
                        <IonCol size="12" className="d-flex align-items-center">
                          {files.map((file, index) => {
                            return (
                              <IonImg key={index} className="height-45 width-45 mr-10 border-gray-1 cursor" onClick={(e) => deleteFile(e, index)} src={file.image} />
                            )
                          })}
                          {files.length < 5 && (
                            <div className="height-45 py-0 px-0 border-radius-0 width-45 back-light-blue2 color-white font-24 text-bold cursor d-flex align-items-center ion-justify-content-center" color="transparent" onClick={(e) => addFile(e)}>
                              <IonIcon icon={addCircleSharp} className="font-24 text-bold" />
                            </div>
                          )}
                        </IonCol>
                      </>
                    )}
                  </IonRow>
                </IonCol>
                <IonCol size="12" className="d-flex mt-20 ion-justify-content-end">
                  <IonButton className={`btn btn-blue height-45 line-height-45 py-0 px-20 ion-text-center border-radius-24 font-20 text-bold ${(content !== '' && title !== '') ? '' : 'btn-disabled'}`} disabled={content === '' || title === ''} color="transparent" onClick={(e) => sendPost(e)}>話題を投稿</IonButton>
                </IonCol>
              </IonRow>
            </IonModal>

            <input type="file" ref={fileRef} style={{display: 'none'}} onChange={(e) => changeFile(e)} />
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
    showLoading: (value) => dispatch(showLoading(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);

