import {all, fork} from 'redux-saga/effects';

import {watchApps} from './app';
import {watchUser} from './user';

export default function* rootSaga() {
  yield all([
    fork(watchApps),
    fork(watchUser),
  ]);
}
