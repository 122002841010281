import { IonContent, IonPage, IonImg, IonTitle, IonRouterLink, IonLoading } from '@ionic/react';

import React from 'react';
import { useParams } from 'react-router-dom';
import {fetchApps} from '../../actions';
import {connect} from 'react-redux';

const Welcome = (props) => {
  const { app } = useParams()
	const {appInfo} = props.appReducer;
	return (
		<IonPage>
			<IonContent fullscreen>
				{appInfo.slug == app ? (
					<div className="home-content">
						<div className="d-flex align-items-center flex-direction-column">
							<IonRouterLink href={`/${appInfo.slug}/login`}>
								<IonImg src={appInfo.icon_url} className="height-200 width-200 app-icon" />
							</IonRouterLink>
							<IonTitle className="font-30 text-bold mt-24 mb-20 main-text-color">{appInfo.name}</IonTitle>
							<IonTitle className="font-16 color-light-gray">組織体ID：{appInfo.slug}</IonTitle>
						</div>
					</div>
				) : (
					<IonContent fullscreen>
						<IonLoading
							cssClass="custom-loading-css"
							isOpen={true}
							spinner="crescent" />
					</IonContent>
				)}
			</IonContent>
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchApps: () => dispatch(fetchApps()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Welcome);

