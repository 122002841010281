import {takeLatest, put} from 'redux-saga/effects';
import * as API from '../apis/api';

import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL
} from '../config/constant';

function* getUserInfo(action) {
  try {
    // const apps = yield API.get_apps();
    let options = {
      endPoint: `auth/me`
    }
    const me = yield API.get_request(options)
    yield put({
      type: FETCH_USER_SUCCESS,
      me: me,
    });
  } catch (error) {
    yield put({type: FETCH_USER_FAIL});
  }
}
export function* watchUser() {
  yield takeLatest(FETCH_USER, getUserInfo);
}
