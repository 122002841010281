import { IonContent, IonCol, IonGrid, IonRow, IonIcon, IonText, IonImg, IonSlides, IonSlide } from "@ionic/react";
import { cloudDownloadSharp, closeSharp, arrowForwardSharp, arrowBackSharp } from 'ionicons/icons';
import { useEffect, useState, useRef } from 'react';

const Zoom = (props) => {
  const [ images, setImages ] = useState([])
  const [ currentIndex, setCurrentIndex ] = useState(0)
  const slideRef = useRef()
  let tempIndex = 0
  const [ visible, setVisible ] = useState(false)

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.onHideClick()
  }

  const handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    window.open(`${images[currentIndex].full_url}&d=1`)
  }

  const handlePlus = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setImages(props.images.map((item, index) => {
      if (index === currentIndex && item.zoom <= 5.0) item.zoom += 0.2

      return item
    }))
  }

  const handleMinus = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setImages(props.images.map((item, index) => {
      if (index === currentIndex && item.zoom > 0.4) item.zoom -= 0.2

      return item
    }))
  }

  const handlePrev = (e) => {
    e.preventDefault();
    e.stopPropagation();

    slideRef.current.slidePrev()
  }

  const handleNext = (e) => {
    e.preventDefault();
    e.stopPropagation();

    slideRef.current.slideNext()
  }

  const onSlideDidChange = async (e) => {
    const tempValue = await e.currentTarget.getActiveIndex();
    setCurrentIndex(tempValue)
  }

  const init = () => {
    setImages(props.images.map((item, index) => {
      item.zoom = 1.0
      if (parseInt(item.id) === parseInt(props.id)) tempIndex = index 
      return item
    }))

    setTimeout(function() {
      slideRef.current.slideTo(tempIndex, 0)
      setVisible(true)
    }, 50)
  }

  useEffect(() => {
    init()
  }, []);

  return (
    <IonContent>
      <IonGrid className="ion-padding px-0 py-0 zoomer">
        {images.length > 0 && (
          <>
            <IonRow>
              <IonCol size="12" className="zoomer-content px-0 py-0">
                <IonSlides pager={false} className={`zoomer-slider ${visible ? 'visible' : 'visible-hidden'}`} onIonSlideDidChange={(e) => onSlideDidChange(e)} ref={slideRef}>
                  {images.map(item => {
                    const style = {
                      transform: `scale(${item.zoom})`
                    }
                    return (
                      <IonSlide key={item.id} className="slider-item">
                        <IonImg src={item.full_url} style={style} />
                      </IonSlide>
                    )
                  })}
                </IonSlides>

                {currentIndex !== 0 && (<IonIcon icon={arrowBackSharp} className="font-28 color-white px-10 py-10 icon-prev cursor" onClick={(e) => handlePrev(e)} />)}
                {(currentIndex !== images.length - 1) && (<IonIcon icon={arrowForwardSharp} className="font-28 color-white px-10 py-10 icon-next cursor" onClick={(e) => handleNext(e)} />)}
              </IonCol>
            </IonRow>
            <div className="zoom-overlay-1">
              <IonText className="d-flex align-items-center cursor px-16 py-16" onClick={(e) => handleSave(e)}>
                <IonIcon icon={cloudDownloadSharp} className="mr-8 font-24" />
                <IonText className="color-white">保存</IonText>
              </IonText>
              <div className="flex-1 align-items-center ion-justify-content-end d-flex">
                <div className="width-45 height-45 px-0 py-0"><IonImg src="/assets/plus.png" className="px-10 py-10 cursor" onClick={(e) => handlePlus(e)} /></div>
                <div className="width-45 height-45 px-0 py-0"><IonImg src="/assets/minus.png" className="px-10 py-10 cursor" onClick={(e) => handleMinus(e)} /></div>
                <IonIcon icon={closeSharp} className="font-32 cursor px-16 py-16" onClick={(e) => handleClose(e)} />
              </div>
            </div>
            <div className="zoom-overlay-2">
              <IonRow>
                <IonCol size="12" className="ion-text-center color-white">{images[currentIndex].name}</IonCol>
              </IonRow>
            </div>
          </>
        )}
      </IonGrid>
    </IonContent>
  )
}

export default Zoom;