import { IonContent, IonPage, IonIcon, IonGrid, IonCol, IonRow, IonButton, IonText, IonRouterLink, IonModal, IonTextarea, IonLabel, IonInput, IonImg, IonLoading } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, showLoading } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import { closeCircleSharp, pencilOutline, chevronForwardOutline } from 'ionicons/icons';
import Header from '../../components/Header';
import Loading from '../../components/Loading';

const Index = (props) => {
  let history = useHistory();
  const { appInfo } = props.appReducer
  const { me } = props.userReducer
  const contentRef = useRef()
  const fileRef = useRef()
  const [ showModal, setShowModal ] = useState(false);
  const [ title, setTitle ] = useState('')
  const [ content, setContent ] = useState('')
  const [ file, setFile ] = useState({})
  let tempFile = {}
  const [ libraries, setLibraries ] = useState([])
  const [ loading, setLoading ] = useState({ status: false, text: '読込中…' })
  const [ page, setPage ] = useState(1)
  const [ hasMore, setHasMore ] = useState(true)

  const loadLibraries = async () => {
    try {
      setLoading({ status: true, text: libraries.length === 0 ? '読込中…' : 'さらに読込中…' })

      let response = await API.post_request({
        endPoint: `libraries`,
        params: {
          page: page
        }
      })

      setLoading({ status: false, text: '' })
      response = response.data

      setLibraries([...libraries, ...response.data])

      setPage(response.current_page + 1)
      setHasMore(response.last_page > response.current_page)
    } catch (error) {
      setLoading({ status: false, text: '' })

      Swal.fire({  
        icon: 'error',  
        text: error.message, 
        showConfirmButton: false,
        timer: 3000 
      }); 
    }
  }

  const changeContent = async (e) => {
    const tempValue = await e.currentTarget.value;
    setContent(tempValue);
  }

  const changeTitle = async (e) => {
    const tempValue = await e.currentTarget.value;
    setTitle(tempValue);
  }

  const sendContact = async (e) => {
    if (title !== '' && content !== '') {
      props.showLoading(true)
      try {
        let formData = new FormData();
        formData.append('title', title)
        formData.append('content', content)
        formData.append('file', file.file)
        formData.append('name', file.name)
        formData.append('type', file.type)
        formData.append('size', file.size)

        let response = await API.post_request({
          endPoint: `libraries/store`,
          params: formData
        })
        
        props.showLoading(false)
        setShowModal(false)
        resetContent()

        response = response.data

        setLibraries([...[response.library], ...libraries])

        Swal.fire({  
          icon: 'success',  
          title: `<strong class="sweet-alert-success">${response.title}</strong>`,
          text: response.message,
          showConfirmButton: true,
          confirmButtonColor: "#2178C3",
        })
      } catch (error) {
        props.showLoading(false)

        Swal.fire({  
          icon: 'error',  
          text: error.message,
          showConfirmButton: false,
        })
      }
    }
  }

  const getBreakpoints = () => {
    const { innerHeight: height } = window;
    return [
      0,
      590 / height
    ]
  }

  const getInitialBreakpoint = () => {
    const { innerHeight: height } = window;
    return 590 / height
  }

  const resetContent = () => {
    setContent('')
    setTitle('')
    setFile({ name: '' })
  }

  const addFile = () => {
    fileRef.current.click()
  }

  const changeFile = async (e) => {
    let limit = parseInt(me.profile.my_app.plan.file_size_limit)
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    let size = files[0].size / 1024;
    if (size > limit * 1024) {
      Swal.fire({
        icon: 'warning',
        text: `${limit}MBまで選択してください。`,
        confirmButtonColor: "#2178C3",
      })

      removeFile()
      return;
    }

    tempFile = {
      name: files[0].name,
      type: files[0].type,
      size: parseInt(size),
      id: -1,
      image: '',
      file: files[0]
    }
    createFile(files[0])
  }

  const createFile = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setFile(tempFile)
      tempFile = Object.assign({ name: '' })
      removeFile()
    };
    reader.readAsDataURL(file);
  }

  const removeFile = () => {
    fileRef.current.value = ''
  }

  const canSubmit = () => {
    return title !== '' && content !== '' && file.file !== undefined
  }

  const renderLibrary = (library) => {
    return (
      <IonRouterLink routerLink={`/${appInfo.slug}/library/${library.id}`}>
        <div className="d-flex align-items-center ion-justify-content-between border-bottom-1 py-10">
          <div className="d-flex align-items-top flex-1 ion-justify-content-between">
            <IonImg src={library.file.thumb_url} className="width-56 height-56 mr-12 img-cover" />
            <div className="flex-1">
              <div className="mb-10 article-title max-height-40 overflow-hidden">
                <IonText className="ml-8 text-bold font-16 color-black word-break-all line-height-20">{library.title}</IonText>
              </div>
              <div className="d-flex align-items-center ion-justify-content-end color-black-light font-12 w-100">
                <div>登録日時：{library.created_at_label} @ {library.user_name}</div>
              </div>
            </div>
          </div>
          <div>
            <IonIcon icon={chevronForwardOutline} className="height-32 width-32 color-light-gray ml-10" />
          </div>
        </div>
      </IonRouterLink>
    )
  }

  const init = async () => {
    if (me.id) {
      if (me.role_id === 3) {
        history.push(`/${appInfo.slug}/top`)
        return
      }
      setLibraries([])
      setPage(1)
      setHasMore(false)

      loadLibraries()
    }
  }

  useEffect(() => {
    init()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          <Header title="役員引継書庫"></Header>
          <IonContent ref={contentRef} fullscreen>
            <IonGrid className="ion-padding px-10 py-10">
              <IonRow>
                {libraries.map((library, index) => {
                  return (
                    <IonCol size="12" key={index}>
                      {renderLibrary(library)}
                    </IonCol>
                  )
                })}
                <Loading loading={loading}></Loading>
                {(!loading.status && !hasMore) && (
                  <>
                    {libraries.length === 0 ? (
                      <IonCol size="12" className="ion-text-center py-20 px-20">これ以上表示できる引継資料はありません。</IonCol>
                    ) : (
                      <IonCol size="12" className="ion-text-center py-20 px-20">これ以上表示できる引継資料はありません。</IonCol>
                    )}
                  </>
                )}
                {hasMore && (
                  <div className="height-100">&nbsp;</div>
                )}
              </IonRow>
            </IonGrid>
            <IonButton className="btn btn-blue border-radius-round width-56 height-56 ion-text-center py-0 line-height-56 font-10 text-bold btn-comment" color="transparent" onClick={() => setShowModal(true)}>登録</IonButton>

            <IonModal
              isOpen={showModal}
              cssClass='my-custom-modal'
              swipeToClose={true}
              onDidDismiss={() => setShowModal(false)}
              onIonModalDidDismiss={() => resetContent()}
              breakpoints={getBreakpoints()}
              initialBreakpoint={getInitialBreakpoint()}
              handle={false}>
              <IonRow>
                <IonCol size="12" className="d-flex align-items-center justify-content-center px-20 py-10 main-text-color">
                  <IonIcon icon={pencilOutline} className="mr-10 font-28" />
                  <IonLabel className="font-20 text-bold flex-1">役員引継資料を登録</IonLabel>
                  <IonIcon icon={closeCircleSharp} className="ml-10 font-28 color-light-gray mx-0 px-0" onClick={() => setShowModal(false)} />
                </IonCol>
                <IonCol size="12" className="flex-1">
                  <IonRow>
                    <IonCol size="12" className="color-black text-bold">ファイル</IonCol>
                    <IonCol size="12">
                      <IonInput placeholder="デバイスから選択…" className="border-gray-1 color-black px-10" value={file.name} onClick={() => addFile()} disabled={true}></IonInput>
                    </IonCol>
                    <IonCol size="12" className="color-black text-bold">タイトル</IonCol>
                    <IonCol size="12">
                      <IonInput placeholder="（例）公園でみかけた風景です！" className="border-gray-1 color-black px-10" value={title} onIonChange={e => changeTitle(e)} onKeyUp={e => changeTitle(e)}></IonInput>
                    </IonCol>
                    <IonCol size="12" className="color-black text-bold">メッセージ</IonCol>
                    <IonCol size="12">
                      <IonTextarea placeholder="メッセージを入力..." rows={10} className="border-gray-1 color-black px-10" value={content} onIonChange={e => changeContent(e)} onKeyUp={e => changeContent(e)}></IonTextarea>
                    </IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size="12" className="d-flex mt-20 ion-justify-content-end">
                  <IonButton className={`btn btn-blue height-45 line-height-45 py-0 px-20 ion-text-center border-radius-24 font-20 text-bold ${canSubmit() ? '' : 'btn-disabled'}`} disabled={!canSubmit()} color="transparent" onClick={(e) => sendContact(e)}>アップロード</IonButton>
                </IonCol>
              </IonRow>
            </IonModal>
            <input type="file" ref={fileRef} style={{display: 'none'}} onChange={(e) => changeFile(e)} />
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
    showLoading: (value) => dispatch(showLoading(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);

