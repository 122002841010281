import { IonContent, IonPage, IonGrid, IonCol, IonRow, IonButton, IonLoading } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, updateAppStatus, showLoading, updateAuthUser } from '../../actions';
import {connect} from 'react-redux';
import CustomField from '../../components/CustomField';
import { useProfileEditFirstFields, useProfileEditFields } from '../../data/fields';
import { validateForm, formInputed, useFormInput, useHtmlFormInput } from '../../data/utils';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import Header from '../../components/Header';

const Profile = (props) => {
  const fields = useProfileEditFirstFields();
  const extraFields = useProfileEditFields();
  const [ errors, setErrors ] = useState(false);
  const [ options, setOptions ] = useState([])
  let history = useHistory();
  const contentRef = useRef()
	
  const { appInfo } = props.appReducer
  const { me } = props.userReducer

	let { app_status } = props.appReducer
	app_status = app_status ?? {}

	const updateProfile = async () => {
    const errors = validateForm(fields);

    setErrors(errors);
		if (!errors.length) {
      let params = {
        first_name: fields[0].inputs[0].input.state.value,
        last_name: fields[0].inputs[1].input.state.value,
        first_name_kana: fields[1].inputs[0].input.state.value,
        last_name_kana: fields[1].inputs[1].input.state.value,
        age: fields[2].state.value,
        gender: fields[3].state.value,
        options: options,
        phone: extraFields[0].input.state.value,
        password: extraFields[1].input.state.value,
        password_confirmation: extraFields[2].input.state.value,
        email: extraFields[3].input.state.value,
        user_id: me.id
      }

      props.showLoading(true)
			try {
				let response = await API.post_request({
					endPoint: 'profile/update',
					params: params
				})
        props.showLoading(false)

        if (response.status == 'validation_error') {
          setErrors(response.errors)
          return;
        } else {
          response = response.data
        }

        const user = response.user

        props.updateAuthUser(user)
        Swal.fire({  
          icon: 'success',  
          title: `<strong class="sweet-alert-success">${response.title}</strong>`,
          text: response.message, 
          showConfirmButton: true,
          confirmButtonColor: "#2178C3",
        }).then((result) => {
          if (result.isConfirmed) {
          }
        })
			} catch (error) {
        props.showLoading(false)

				Swal.fire({  
					icon: 'error',  
					text: error.message, 
					showConfirmButton: false,
					timer: 3000 
				}); 
      }
    } else {
      contentRef.current && contentRef.current.scrollToTop(500);
    }
  }

  const changeOptionCheck = (e, value) => {
    let temp = [...options]
    if (options.includes(value)) {
      temp = temp.filter(item => item != value)
    } else {
      temp.push(value)
    }
    setOptions(temp)
  }

  const initOptions = () => {
    if (me.profile.options && me.profile.options.length > 0) setOptions(me.profile.options)
  }

  useEffect(() => {
    initOptions()
  }, []);

  fields[0].inputs[0].input.state = useFormInput(me.id && (me.profile.first_name ?? ''))
  fields[0].inputs[1].input.state = useFormInput(me.id && (me.profile.last_name ?? ''))
  fields[1].inputs[0].input.state = useFormInput(me.id && (me.profile.first_name_kana ?? ''))
  fields[1].inputs[1].input.state = useFormInput(me.id && (me.profile.last_name_kana ?? ''))
  fields[2].state = useHtmlFormInput(me.id && (me.profile.age ?? ''))
  fields[3].state = useHtmlFormInput(me.id && (me.profile.gender ?? ''))

  extraFields[0].input.state = useFormInput(me.id && (me.profile.phone ?? ''))
  extraFields[3].input.state = useFormInput(me.id && (me.profile.email ?? ''))

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          <Header title="利用者情報"></Header>
          <IonContent fullscreen ref={contentRef}>
            <IonGrid className="ion-padding px-30 py-30">
              <IonRow>
                <IonCol size="12">
                  { fields.map((field, index) => {
                    return <CustomField field={ field } key={ index } errors={ errors } />;
                  }) }
                  <div className="mt-20 mb-20">
                    {appInfo.options.map(option => {
                      return (
                        <div className="toggleWrapper mb-10" key={`option_${option.id}`}>
                          <input type="checkbox" className="mobileToggle" id={`option${option.id}`} value={option.id} onChange={e => changeOptionCheck(e, option.id)} checked={options.includes(option.id)} />
                          <label htmlFor={`option${option.id}`}>{option.name}</label>
                        </div>
                      )
                    })}
                  </div>
                  { extraFields.map((field, index) => {
                    return <CustomField field={ field } key={ index } errors={ errors } />;
                  }) }
                  <IonButton className={`btn btn-green height-60 mt-20 border-radius-30 btn-block font-20 line-height-60 text-bold ${formInputed(fields) ? '' : 'btn-disabled'}`} disabled={!formInputed(fields)} onClick={updateProfile} color="transparent">更新</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);

