import { RemoteService } from '../services/remote.services';
const remoteService = new RemoteService();

export const get_app_info = async options => {
  const response = await remoteService.getRequest(options.endPoint, options.params)
  
  if (response.status == 'success') return response.data

  throw new Error(response.message);
};

export const post_request = async options => {
  const response = await remoteService.postRequest(options.endPoint, options.params)

  if (response.status == 'success' || response.status == 'validation_error') return response

  throw new Error(response.message);
};

export const get_request = async options => {
  const response = await remoteService.getRequest(options.endPoint, options.params)

  if (response.status == 'success') return response.data

  throw new Error(response.message);
};