import { IonContent, IonPage, IonIcon, IonGrid, IonCol, IonRow, IonButton, IonRouterLink, IonModal, IonTextarea, IonLabel, IonInput, IonLoading } from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, showLoading } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import { closeCircleSharp, pencilOutline } from 'ionicons/icons';
import Header from '../../components/Header';

const Index = (props) => {
  const { appInfo } = props.appReducer
  const { me } = props.userReducer
  const contentRef = useRef()
  const [ showModal, setShowModal ] = useState(false);
  const [ title, setTitle ] = useState('')
  const [ content, setContent ] = useState('')
  const [ useNickname, setUseNickname ] = useState(false)

  const changeContent = async (e) => {
    const tempValue = await e.currentTarget.value;
    setContent(tempValue);
  }

  const changeTitle = async (e) => {
    const tempValue = await e.currentTarget.value;
    setTitle(tempValue);
  }

  const sendContact = async (e) => {
    if (title !== '' && content !== '') {
      props.showLoading(true)
      try {
        let response = await API.post_request({
          endPoint: `contact/send`,
          params: {
            content: content,
            title: title,
            use_nickname: useNickname ? 1 : 0
          }
        })
        
        props.showLoading(false)
        setShowModal(false)
        resetContent()

        response = response.data

        Swal.fire({  
          icon: 'success',  
          title: `<strong class="sweet-alert-success">${response.title}</strong>`,
          showConfirmButton: true,
          confirmButtonColor: "#2178C3",
        })
      } catch (error) {
        props.showLoading(false)

        Swal.fire({  
          icon: 'error',  
          text: error.message,
          showConfirmButton: false,
        })
      }
    }
  }

  const getBreakpoints = () => {
    const { innerHeight: height } = window;
    return [
      0,
      550 / height
    ]
  }

  const getInitialBreakpoint = () => {
    const { innerHeight: height } = window;
    return 550 / height
  }

  const resetContent = () => {
    setContent('')
    setTitle('')
    setUseNickname(false)
  }

  useEffect(() => {
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          <Header title="目安箱"></Header>
          <IonContent ref={contentRef} fullscreen>
            <IonGrid className="ion-padding px-30 py-30">
              <IonRow>
                <IonCol size="12" className="color-black font-20 mb-20">
                  目安箱機能を使って、代表利用者や役員利用者に非公開メッセージを送信することができます。
                </IonCol>
                <IonCol size="12" className="color-black font-20 mb-40">
                  利用するには、事前に
                  <IonRouterLink routerLink={`/${appInfo.slug}/profile`} className="color-blue underline">利用者情報画面</IonRouterLink>から受信可能なメールアドレスを設定してください。
                </IonCol>
                <IonCol size="12" className="mb-40">
                  <IonButton className="btn btn-blue height-60 border-radius-30 btn-block font-20 line-height-60 text-bold" color="transparent" onClick={() => setShowModal(true)}>目安箱からメールを送信</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonModal
              isOpen={showModal}
              cssClass='my-custom-modal'
              swipeToClose={true}
              onDidDismiss={() => setShowModal(false)}
              onIonModalDidDismiss={() => resetContent()}
              breakpoints={getBreakpoints()}
              initialBreakpoint={getInitialBreakpoint()}
              handle={false}>
              <IonRow>
                <IonCol size="12" className="d-flex align-items-center justify-content-center px-20 py-10 main-text-color">
                  <IonIcon icon={pencilOutline} className="mr-10 font-28" />
                  <IonLabel className="font-20 text-bold flex-1">非公開メールを送信</IonLabel>
                  <IonIcon icon={closeCircleSharp} className="ml-10 font-28 color-light-gray mx-0 px-0" onClick={() => setShowModal(false)} />
                </IonCol>
                <IonCol size="12" className="flex-1">
                  <IonRow>
                    <IonCol size="12" className="toggleWrapper mb-10">
                      <input type="checkbox" className="mobileToggle" id="option" value={true} onChange={() => setUseNickname(!useNickname)} checked={useNickname} />
                      <label htmlFor={`option`} className="color-black">匿名で送信する</label>
                    </IonCol>
                    <IonCol size="12" className="color-black text-bold">件名</IonCol>
                    <IonCol size="12">
                      <IonInput placeholder="件名" className="border-gray-1 color-black px-10" value={title} onIonChange={e => changeTitle(e)} onKeyUp={e => changeTitle(e)}></IonInput>
                    </IonCol>
                    <IonCol size="12" className="color-black text-bold">本文</IonCol>
                    <IonCol size="12">
                      <IonTextarea placeholder="本文を入力..." rows={10} className="border-gray-1 color-black px-10" value={content} onIonChange={e => changeContent(e)} onKeyUp={e => changeContent(e)}></IonTextarea>
                    </IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size="12" className="d-flex mt-20 ion-justify-content-end">
                  <IonButton className={`btn btn-blue height-45 line-height-45 py-0 px-20 ion-text-center border-radius-24 font-20 text-bold ${(content !== '' && title !== '') ? '' : 'btn-disabled'}`} disabled={content === '' || title === ''} color="transparent" onClick={(e) => sendContact(e)}>送信</IonButton>
                </IonCol>
              </IonRow>
            </IonModal>
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
    showLoading: (value) => dispatch(showLoading(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);

