import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { PersistGate } from "redux-persist/lib/integration/react";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.scss';

// Components
import AppCheck from './components/AppCheck';
import MainMenu from './components/MainMenu';

// Pages
import Home from './pages/home/Home';
import Error404 from './pages/errors/404';

import Welcome from './pages/welcome/Welcome';
import LoginForm from './pages/auth/Login';
import RegisterForm from './pages/auth/Register';
import RegisterEmail from './pages/auth/RegisterEmail';
import RegisterInfo from './pages/auth/RegisterInfo';
import RegisterOrganization from './pages/auth/RegisterOrganization';
import RegisterInvite from './pages/auth/RegisterInvite';
import RegisterInvite2 from './pages/auth/RegisterInvite2';

import PasswordEmail from './pages/auth/PasswordEmail';
import PasswordReset from './pages/auth/PasswordReset';

import Top from './pages/top/Top';
import Start from './pages/auth/Start';
import Profile from './pages/profile/Profile';

// Articles
import ArticleIndex from './pages/article/Index';
import ArticleDetail from './pages/article/Detail';
import SaveIndex from './pages/article/SaveIndex';

// Meetings
import MeetingIndex from './pages/meeting/Index';
import MeetingDetail from './pages/meeting/Detail';

// Calendar
import Calendar from './pages/calendar/Index';

// Contact
import Contact from './pages/contact/Index';

// Library
import Library from './pages/library/Index';
import LibraryDetail from './pages/library/Detail';

// Post
import PostIndex from './pages/post/Index';
import PostDetail from './pages/post/Detail';
import PostSaves from './pages/post/SaveIndex';

// Business
import BusinessRegister from './pages/business/Register';
import BusinessIndex from './pages/business/Index';
import BusinessDetail from './pages/business/Detail';

// Banner
import BannerTop from './pages/banner/Top';

import {Provider} from 'react-redux';
import configureStore from './store/store';
const {store, persistor} = configureStore();

setupIonicReact();

const App = () => (
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<IonApp>
				<IonReactRouter>
					<MainMenu disabled={false} />

					<IonRouterOutlet id="myMenuOutlet">
						
						<Route exact path="/">
							<Home />
						</Route>

						<Route exact path="/:app">
							<AppCheck />
							<Welcome />
						</Route>

						<Route exact path="/:app/start/:unique_id">
							<AppCheck both />
							<Start />
						</Route>

						<Route exact path="/:app/login">
							<AppCheck />
							<LoginForm />
						</Route>

						<Route exact path="/:app/password/email">
							<AppCheck />
							<PasswordEmail />
						</Route>

						<Route exact path="/:app/password/reset/:token">
							<AppCheck />
							<PasswordReset />
						</Route>

						<Route exact path="/:app/register">
							<AppCheck />
							<RegisterForm />
						</Route>

						<Route exact path="/:app/register/invite">
							<AppCheck />
							<RegisterInvite />
						</Route>

						<Route exact path="/:app/register/invite/:id">
							<AppCheck />
							<RegisterInvite2 />
						</Route>

						<Route exact path="/:app/register/email">
							<AppCheck />
							<RegisterEmail />
						</Route>

						<Route exact path="/:app/register/info" name="auth.register.info">
							<AppCheck needAuth />
							<RegisterInfo />
						</Route>

						<Route exact path="/:app/register/organization" name="auth.register.organization">
							<AppCheck needAuth />
							<RegisterOrganization />
						</Route>

						<Route exact path="/:app/top">
							<AppCheck needAuth />
							<Top />
						</Route>

						<Route exact path="/:app/profile">
							<AppCheck needAuth />
							<Profile />
						</Route>

						<Route exact path="/:app/articles/:category">
							<AppCheck needAuth />
							<ArticleIndex />
						</Route>

						<Route exact path="/:app/articles/:category/:id">
							<AppCheck needAuth />
							<ArticleDetail />
						</Route>

						<Route exact path="/:app/saved/articles">
							<AppCheck needAuth />
							<SaveIndex />
						</Route>

						<Route exact path="/:app/posts/:category">
							<AppCheck needAuth />
							<PostIndex />
						</Route>

						<Route exact path="/:app/posts/:category/:id">
							<AppCheck needAuth />
							<PostDetail />
						</Route>

						<Route exact path="/:app/saved/posts">
							<AppCheck needAuth />
							<PostSaves />
						</Route>

						<Route exact path="/:app/calendar">
							<AppCheck needAuth />
							<Calendar />
						</Route>

						<Route exact path="/:app/contact">
							<AppCheck needAuth />
							<Contact />
						</Route>

						<Route exact path="/:app/library">
							<AppCheck needAuth />
							<Library />
						</Route>

						<Route exact path="/:app/library/:id">
							<AppCheck needAuth />
							<LibraryDetail />
						</Route>

						<Route exact path="/:app/create/business">
							<AppCheck needAuth />
							<BusinessRegister />
						</Route>
						
						<Route exact path="/:app/business">
							<AppCheck needAuth />
							<BusinessIndex />
						</Route>

						<Route exact path="/:app/business/:id">
							<AppCheck needAuth />
							<BusinessDetail />
						</Route>

						<Route exact path="/:app/banner/top">
							<AppCheck needAuth />
							<BannerTop />
						</Route>

						<Route exact path="/:app/meetings/:category">
							<AppCheck needAuth />
							<MeetingIndex />
						</Route>

						<Route exact path="/:app/meetings/:category/:id">
							<AppCheck needAuth />
							<MeetingDetail />
						</Route>

						<Route exact path="/:app/404">
							<Error404 />
						</Route>

						{/* <Route exact path="/">
							<Redirect to="/home" />
						</Route> */}
					</IonRouterOutlet>
				</IonReactRouter>
			</IonApp>
		</PersistGate>
	</Provider>
);

export default App;
