import { IonContent, IonPage, IonLoading } from '@ionic/react';

import React, { useEffect } from 'react';
import { fetchApps, updateAppStatus, showLoading } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import { useHistory, useParams } from 'react-router-dom';

const RegisterInvite2 = (props) => {
	let history = useHistory();
	const { id, app } = useParams()

	const { appInfo } = props.appReducer
	let { app_status } = props.appReducer
	app_status = app_status ?? {}

	const checkInviteCode = async () => {

		props.showLoading(true)
		try {
			let response = await API.post_request({
				endPoint: 'auth/check/invite',
				params: {
					id: id,
					slug: app
				}
			})
			props.showLoading(false)

			response = response.data.id

			app_status = Object.assign(app_status, { can_register: true, email: 'checked', id: response })
			props.updateAppStatus(app_status)

			setTimeout(function() {
				history.push(`/${app}/register/email`)
			}, 500)

		} catch (error) {
			props.showLoading(false)
			history.push(`/${app}/register`)

		}
	}

  useEffect(() => {
		checkInviteCode()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color) ? ( 
				<>
					
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
		appReducer: state.appReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
		showLoading: (value) => dispatch(showLoading(value))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterInvite2);

