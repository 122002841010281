import { IonContent, IonPage, IonImg } from '@ionic/react';

import styles from './Home.module.scss';
import React from 'react';

const Home = () => {
	
	return (
		<IonPage>
			<IonContent fullscreen>
				<div className="home-content">
          <IonImg className={styles.logoImg} src="/assets/machinity_logo-512x512.png" />
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Home;

