import { IonContent, IonPage, IonImg, IonTitle, IonHeader, IonToolbar, IonFooter, IonGrid, IonCol, IonRow, IonLabel, IonLoading } from '@ionic/react';
import { Redirect } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { fetchApps, updateAppStatus, showLoading, updateAuthUser, updateTopBanner, updateDarkMode } from '../../actions';
import { connect } from 'react-redux';
import { Action } from '../../components/Action';
import CustomField from '../../components/CustomField';
import { useLoginFields } from '../../data/fields';
import { validateForm, formInputed } from '../../data/utils';
import * as API from '../../apis/api';
import * as moment from 'moment';
import styles from './Login.module.scss';

const Login = (props) => {
	const fields = useLoginFields();
	const [ errors, setErrors ] = useState(false);
	const [ lockStatus, setLockStatus ] = useState(false);

	const [ redirect, setRedirect ] = useState('');

	
	const { appInfo, darkMode } = props.appReducer

	let { app_status } = props.appReducer
	app_status = app_status ?? {}

	const login = async () => {

		const errors = validateForm(fields);
		setErrors(errors);

		if (!errors.length) {
			props.showLoading(true)
			try {
				let response = await API.post_request({
					endPoint: 'login',
					params: {
						email: fields[0].input.state.value,
						password: fields[1].input.state.value
					}
				})
				
				props.showLoading(false)

				if (response.status == 'failed') {
					throw new Error(response.message)
				}

				response = response.data
				const user = response.user
				props.updateTopBanner(response.topBanner)
				sessionStorage.setItem('auth_token', response.token)
				props.updateAuthUser(user)
				props.updateDarkMode(user.is_dark === 1)
				
				setRedirect(`/${appInfo.slug}/top`)
			} catch (error) {
				props.showLoading(false)
				setErrors([
					{
						id: 'email',
						message: error.message
					}
				])
				
				app_status = Object.assign(app_status, {
					login_attempt: (app_status.login_attempt ?? 0) + 1,
					last_login_attempt_at: moment()
				})
				props.updateAppStatus(app_status)
				checkLockStatus()
			}
		}
	}

	const checkLockStatus = () => {
		if ((app_status.login_attempt ?? 0) >= 5) {
			const now = moment()
			if (now.diff(app_status.last_login_attempt_at, 'minutes') <= 5) {
				setLockStatus(true)
			} else {
				props.updateAppStatus(Object.assign(app_status, { login_attempt: 0, last_login_attempt_at: moment() }))
				setLockStatus(false)
			}
		}
	}

  useEffect(() => {
		checkLockStatus()
  }, []);

	return (
		<IonPage className={ styles.loginPage }>
			{appInfo.slug && appInfo.color ? (
				<>
					<IonHeader>
						<IonToolbar color="transparent" style={{backgroundColor: darkMode ? '#343A40' : appInfo.color.background_color}}>
							<IonTitle className="ion-text-center font-20 text-bold" style={{color: darkMode ? '#5ECBF7' : appInfo.color.color}}>{appInfo.name}</IonTitle>
						</IonToolbar>
					</IonHeader>
					<IonContent fullscreen>
						{lockStatus ? (
							<IonGrid className="ion-padding main-container d-flex ion-align-items-center ion-justify-content-center">
								<IonLabel className="ion-text-center font-18 text-center px-10">現在この接続先からのログインはロックされています。しばらく時間をおいてから、再度アクセスしてください。</IonLabel>
							</IonGrid>
						) : (
							<IonGrid className="ion-padding main-container d-flex ion-align-items-center ion-justify-content-center">
								<IonRow className="w-100">
									<IonCol size="12" className="d-flex ion-align-items-center ion-justify-content-center mb-24">
										<IonImg src={appInfo.icon_url} className="height-75 width-75 app-icon" />
									</IonCol>
									<IonCol size="12">
										{ fields.map((field, index) => {
											return <CustomField field={ field } key={ index } errors={ errors } />;
										}) }
										<button type="button" className={`btn btn-blue height-60 border-radius-30 btn-block font-20 line-height-60 text-bold ${formInputed(fields) ? '' : 'btn-disabled'}`} disabled={!formInputed(fields)} onClick={login}>ログイン</button>
									</IonCol>
								</IonRow>
							</IonGrid>
						)}
						<IonFooter>
							<IonGrid className="ion-no-margin ion-no-padding d-flex ion-justify-content-center">
								<Action text="新規登録" className="custom-link mx-10 text-center" link={`/${appInfo.slug}/register`} />
								<Action text="パスワードを忘れた" className="custom-link mx-10 text-center" link={`/${appInfo.slug}/password/email`} />
							</IonGrid>
						</IonFooter>
					</IonContent>

					{redirect != '' && <Redirect to={redirect} />}
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
		showLoading: (value) => dispatch(showLoading(value)),
		updateAuthUser: (value) => dispatch(updateAuthUser(value)),
		updateTopBanner: (value) => dispatch(updateTopBanner(value)),
		updateDarkMode: (value) => dispatch(updateDarkMode(value))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);

