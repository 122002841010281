import {takeLatest, put} from 'redux-saga/effects';
import * as API from '../apis/api';

import {
  FETCH_APP,
  FETCH_APP_SUCCESS,
  FETCH_APP_FAIL
} from '../config/constant';

function* getApps(action) {
  try {
    // const apps = yield API.get_apps();
    let options = {
      endPoint: `apps`,
      params: {
        slug: action.slug
      }
    }
    const app_info = yield API.get_app_info(options)
    yield put({
      type: FETCH_APP_SUCCESS,
      appInfo: app_info,
    });
  } catch (error) {
    yield put({type: FETCH_APP_FAIL});
  }
}
export function* watchApps() {
  yield takeLatest(FETCH_APP, getApps);
}
