import { IonContent, IonPage, IonGrid, IonCol, IonRow, IonLabel, IonLoading, IonButton } from '@ionic/react';
import { Redirect } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { fetchApps, updateAppStatus, showLoading, updateAuthUser, updateDarkMode } from '../../actions';
import {connect} from 'react-redux';
import CustomField from '../../components/CustomField';
import { useSignupFields, useInviteSignupFields } from '../../data/fields';
import { validateForm, formInputed } from '../../data/utils';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import Header from '../../components/Header';
import styles from './RegisterEmail.module.scss';

const RegisterEmail = (props) => {
  const fields = useSignupFields();
  const inviteFields = useInviteSignupFields();
  const [ errors, setErrors ] = useState(false);
  const [ needRedirect, setNeedRedirect ] = useState(false);
  const [ showDarkModeModal, setShowDarkModeModal ] = useState(true);
	
	const { appInfo, darkMode } = props.appReducer
	let { app_status } = props.appReducer
  app_status = app_status ?? {}

	const setPassword = async () => {

		const errors = app_status.email && app_status.id ? validateForm(inviteFields) : validateForm(fields);
		setErrors(errors);

		if (!errors.length) {
			props.showLoading(true)
			try {
        let response = undefined
        if (app_status.email && app_status.id) {
          response = await API.post_request({
            endPoint: 'auth/register/invite',
            params: {
              password: inviteFields[0].input.state.value,
              password_confirmation: inviteFields[1].input.state.value,
              email: inviteFields[2].input.state.value,
              app: appInfo.slug,
              id: app_status.id
            }
          })
        } else {
          response = await API.post_request({
            endPoint: 'auth/register',
            params: {
              password: fields[0].input.state.value,
              password_confirmation: fields[1].input.state.value,
              email: fields[2].input.state.value,
              app: appInfo.slug,
              is_dark: darkMode ? 1 : 0
            }
          })
        }
        props.showLoading(false)

        if (response.status == 'validation_error') {
          setErrors(response.errors)
          return;
        } else {
          response = response.data
        }
        const user = response.user
        sessionStorage.setItem('auth_token', response.token)

        props.updateAuthUser(user)
        Swal.fire({  
          icon: 'success',  
          title: `<strong class="sweet-alert-success">${response.title}</strong>`,
          text: response.message, 
          showConfirmButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonColor: "#2178C3",
        }).then((result) => {
          if (result.isConfirmed) {
            setNeedRedirect(true)
          }
        })
			} catch (error) {
        props.showLoading(false)

				Swal.fire({  
					icon: 'error',  
					text: error.message, 
					showConfirmButton: false,
					timer: 3000 
				}); 
      }
      
		}
  }
  
  const updateMode = () => {
    setShowDarkModeModal(false)
  }

  const changeDarkMode = () => {
    props.updateDarkMode(!darkMode)
  }

  useEffect(() => {
  }, []);

	return (
		<IonPage className={`${darkMode ? 'dark-mode' : ''}`}>
			{(appInfo.slug && appInfo.color) ? ( 
				<>
          {app_status.can_register ? (
            <>
              <Header title={appInfo.name} href={`/${appInfo.slug}`}></Header>
              <IonContent fullscreen>
                <IonGrid className="ion-padding px-30 py-30">
                  <IonRow>
                    <IonCol size="12" className="mb-20">
                      <IonLabel className="ion-text-center font-18 px-10">まずは、ログインパスワードを設定します。万が一パスワード忘れた際のために、メールアドレスの登録も推奨しています。</IonLabel>
                    </IonCol>
                    <IonCol size="12">
                      { app_status.email && app_status.id ? (
                        <>
                          { inviteFields.map((field, index) => {
                            return <CustomField field={ field } key={ index } errors={ errors } />;
                          }) }
                          <button type="button" className={`btn btn-blue height-60 mt-20 border-radius-30 btn-block font-20 line-height-60 text-bold ${formInputed(inviteFields) ? '' : 'btn-disabled'}`} disabled={!formInputed(inviteFields)} onClick={setPassword}>次へ</button>
                        </>
                      ) : (
                        <>
                          { fields.map((field, index) => {
                            return <CustomField field={ field } key={ index } errors={ errors } />;
                          }) }
                          <button type="button" className={`btn btn-blue height-60 mt-20 border-radius-30 btn-block font-20 line-height-60 text-bold ${formInputed(fields) ? '' : 'btn-disabled'}`} disabled={!formInputed(fields)} onClick={setPassword}>次へ</button>
                        </>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
              {showDarkModeModal && (
                <IonGrid className={styles.mode}>
                  <div className={`${styles.modeModal} ${darkMode ? styles.dark : ''}`}>
                    <label className={styles.modeModalTitle}>画面がまぶしいですか？</label>
                    <p className={styles.modeModalDesc}>下のボタンをチェックして、ダークモードをお試しください。</p>
                    <div className="toggleWrapper mb-20">
                      <input type="checkbox" className="mobileToggle" id="checkMode" onChange={changeDarkMode} checked={darkMode} />
                      <label htmlFor="checkMode" className={styles.checkLabel}>ダークモードにする</label>
                    </div>
                    <div className="d-flex justify-content-center">
                      <IonButton className={`btn btn-blue height-40 m-0 border-radius-20 px-60 font-20 line-height-40 text-bold`} onClick={updateMode} color="transparent">OK</IonButton>
                    </div>
                  </div>
                </IonGrid>
              )}
            </>
          ) : (
            <Redirect to={`/${appInfo.slug}/register`} />
          )}

          {needRedirect && <Redirect to={`/${appInfo.slug}/register/info`} />}
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
		appReducer: state.appReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
    updateDarkMode: (value) => dispatch(updateDarkMode(value))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterEmail);

