import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonList,
    IonItem,
    IonIcon,
    IonMenuToggle,
    IonLabel,
    IonItemGroup,
    IonItemDivider,
    IonImg
} from '@ionic/react';
import { hammerOutline, logOutOutline, chevronForwardOutline, accessibilityOutline, bookmarksOutline, chatbubblesOutline, calendarNumberOutline, appsOutline, mailOutline, folderOutline, copyOutline } from 'ionicons/icons';
import { updateAuthUser, updateAppStatus } from '../actions';
import {connect} from 'react-redux';
import * as API from '../apis/api';
import { MANAGE_SITE_URL } from '../config/url';
import styles from './MainMenu.module.scss';

const MainMenu = (props) => {
  const { disabled } = props
  let history = useHistory();

  const { appInfo, darkMode } = props.appReducer
  const { me } = props.userReducer

  const appPages = [
    { title: '自己管理' },
    { title: '利用者情報', url: `/${appInfo.slug}/profile`, icon: accessibilityOutline },
    { title: '保存した記事', url: `/${appInfo.slug}/saved/articles`, icon: bookmarksOutline },
    { title: '保存した話題', url: `/${appInfo.slug}/saved/posts`, icon: chatbubblesOutline },
    { title: 'アプリ管理' },
    { title: 'トップ', url: `/${appInfo.slug}/top`, icon: appsOutline },
    // { title: 'カメラから記事を投稿', url: `/${appInfo.slug}/profile/edit`, icon: cameraOutline },
    // { title: '記事を書いて投稿', url: `/${appInfo.slug}/saved/articles`, icon: pencilOutline },
    { title: 'アプリ管理画面へ', url: `${MANAGE_SITE_URL}/management/${me.unique_id}`, icon: hammerOutline, onlyManager: true },
    { title: 'ログアウト', click: 'logout', icon: logOutOutline },
    { title: 'アプリメニュー' },
    { title: 'カレンダー', url: `/${appInfo.slug}/calendar`, icon: calendarNumberOutline, class: 'color-calendar' },
  ];

  const logout = async () => {
    try {
      await API.post_request({
        endPoint: 'auth/logout',
        params: {}
      })
    } catch (error) {

    }
    sessionStorage.removeItem('auth_token');
    props.updateAppStatus({})
    props.updateAuthUser({})

    history.push(`/${appInfo.slug}/login`)
  }

  const clickBusiness = () => {
    history.push(`/${appInfo.slug}/business`)
  }

  const backgroundColor = (value) => {
    return {
      backgroundColor: `${value}`,
      borderRadius: "24px"
    }
  }

  return (
      <>
        {(appInfo.slug && appInfo.color) && (
          <IonMenu menuId="first" contentId="myMenuOutlet" disabled={disabled}>
              <IonHeader>
                <IonToolbar color="transparent" style={{color: darkMode ? '#5ECBF7' : appInfo.color.color, backgroundColor: darkMode ? '#343A40' : appInfo.color.background_color}}>
                  <IonTitle className="ion-text-center text-bold">メニュー</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                {me.id && (
                  <IonList className="py-0">
                    <IonItemGroup>
                      {appInfo.valid_businesses > 0 && (
                        <IonMenuToggle auto-hide="false" onClick={clickBusiness}>
                          <div className={styles.businessBanner} >
                            <IonLabel>
                              <div>地域サポート事業者紹介</div>
                              <div>多くの人々によって支えられています！</div>
                            </IonLabel>
                            <IonIcon icon={chevronForwardOutline} slot="end" className="color-white" />
                          </div>
                        </IonMenuToggle>
                      )}
                      {
                        appPages.map((appPage, index) => {
                          if (appPage.url) {
                            if (appPage.onlyManager) {
                              if (me.can_access_management) {
                                return (
                                  <IonMenuToggle key={index} auto-hide="false">
                                    <IonItem href={appPage.url} detail={false}>
                                      {appPage.icon && <IonIcon icon={appPage.icon} slot="start" className={`color-black-light ${appPage.class}`} />}
                                      <IonLabel>{appPage.title}</IonLabel>
                                      <IonIcon icon={chevronForwardOutline} slot="end" className="color-black-light" />
                                    </IonItem>
                                  </IonMenuToggle>
                                )
                              }
                              return ''
                            }
                            return (
                              <IonMenuToggle key={index} auto-hide="false">
                                <IonItem routerLink={appPage.url} detail={false}>
                                  {appPage.icon && <IonIcon icon={appPage.icon} slot="start" className={`color-black-light ${appPage.class}`} />}
                                  <IonLabel>{appPage.title}</IonLabel>
                                  <IonIcon icon={chevronForwardOutline} slot="end" className="color-black-light" />
                                </IonItem>
                              </IonMenuToggle>
                            )
                          } else if (appPage.click) {
                            return (
                                <IonMenuToggle key={index} auto-hide="false" onClick={logout}>
                                  <IonItem href="javascript:void(0);" detail={false}>
                                    {appPage.icon && <IonIcon icon={appPage.icon} slot="start" className="color-black-light" />}
                                    <IonLabel>{appPage.title}</IonLabel>
                                    <IonIcon icon={chevronForwardOutline} slot="end" className="color-black-light" />
                                  </IonItem>
                                </IonMenuToggle>
                            )
                          }

                          return (
                            <IonItemDivider color="light" key={index} className="py-0 color-black-light back-light-gray2">
                              <IonLabel className="my-0 color-black-light">{appPage.title}</IonLabel>
                            </IonItemDivider>
                          );
                        })
                      }
                      {me.profile.my_article_categories && (
                        <>
                          {me.profile.my_article_categories.map(category => {
                            return (
                              <IonMenuToggle key={`category_${category.id}`} auto-hide="false">
                                <IonItem routerLink={`/${appInfo.slug}/articles/${category.id}`} detail={false}>
                                  <IonImg src={category.icon_url} className="width-24 height-24" slot="start" style={backgroundColor(category.background_color)} />
                                  <IonLabel>{category.name}</IonLabel>
                                  <IonIcon icon={chevronForwardOutline} slot="end" className="color-black-light" />
                                </IonItem>
                              </IonMenuToggle>
                            )
                          })}
                        </>
                      )}
                      {me.profile.can_vote === 1 && me.profile.my_meeting_categories && (
                        <>
                          {me.profile.my_meeting_categories.map(category => {
                            return (
                              <IonMenuToggle key={`meeting_${category.id}`} auto-hide="false">
                                <IonItem routerLink={`/${appInfo.slug}/meetings/${category.id}`} detail={false}>
                                  <IonImg src={category.icon_url} className="width-24 height-24" slot="start" style={backgroundColor(category.background_color)} />
                                  <IonLabel>{category.name}</IonLabel>
                                  <IonIcon icon={chevronForwardOutline} slot="end" className="color-black-light" />
                                </IonItem>
                              </IonMenuToggle>
                            )
                          })}
                        </>
                      )}
                      {me.profile.my_post_categories && (
                        <>
                          {me.profile.my_post_categories.map(category => {
                            return (
                              <IonMenuToggle key={`category_${category.id}`} auto-hide="false">
                                <IonItem routerLink={`/${appInfo.slug}/posts/${category.id}`} detail={false}>
                                  <IonImg src={category.icon_url} className="width-24 height-24" slot="start" style={backgroundColor(category.background_color)} />
                                  <IonLabel>{category.name}</IonLabel>
                                  <IonIcon icon={chevronForwardOutline} slot="end" className="color-black-light" />
                                </IonItem>
                              </IonMenuToggle>
                            )
                          })}
                        </>
                      )}
                      {(me.role_id !== 3 && me.can_access_management && me.profile.my_app.has_library === 1) && (
                        <IonMenuToggle auto-hide="false">
                          <IonItem routerLink={`/${appInfo.slug}/library`} detail={false}>
                            <IonIcon icon={folderOutline} className="color-library icon-class mb-10" slot="start" />
                            <IonLabel>役員引継資料</IonLabel>
                            <IonIcon icon={chevronForwardOutline} slot="end" className="color-black-light" />
                          </IonItem>
                        </IonMenuToggle>
                      )}
                      {me.profile.my_app.has_contact === 1 && (
                        <IonMenuToggle auto-hide="false">
                          <IonItem routerLink={`/${appInfo.slug}/contact`} detail={false}>
                            <IonIcon icon={mailOutline} className="color-contact icon-class mb-10" slot="start" />
                            <IonLabel>目安箱</IonLabel>
                            <IonIcon icon={chevronForwardOutline} slot="end" className="color-black-light" />
                          </IonItem>
                        </IonMenuToggle>
                      )}

                      <IonItemDivider color="light" className="py-0 color-black-light back-light-gray2">
                        <IonLabel className="my-0 color-black-light">事業者メニュー</IonLabel>
                      </IonItemDivider>

                      {me.business ? (
                        <IonMenuToggle auto-hide="false">
                          <IonItem href="mailto:admin@machinity.com">
                            <IonIcon icon={copyOutline} slot="start" className={`color-light-gray`} />
                            <IonLabel>広告出稿（メール）</IonLabel>
                            <IonIcon icon={chevronForwardOutline} slot="end" className="color-black-light" />
                          </IonItem>
                        </IonMenuToggle>
                      ) : (
                        <IonMenuToggle auto-hide="false">
                          <IonItem routerLink={`/${appInfo.slug}/create/business`} detail={false}>
                            <IonIcon icon={copyOutline} slot="start" className={`color-light-gray`} />
                            <IonLabel>事業者登録</IonLabel>
                            <IonIcon icon={chevronForwardOutline} slot="end" className="color-black-light" />
                          </IonItem>
                        </IonMenuToggle>
                      )}
                    </IonItemGroup>
                  </IonList>
                )}
              </IonContent>
          </IonMenu>
        )}
      </>
  )
}

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);