import { IonContent, IonPage, IonImg, IonTitle, IonHeader, IonToolbar, IonFooter, IonGrid, IonCol, IonRow, IonLabel, IonLoading } from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { showLoading } from '../../actions';
import {connect} from 'react-redux';
import { Action } from '../../components/Action';
import CustomField from '../../components/CustomField';
import { usePasswordEmailFields } from '../../data/fields';
import { validateForm, formInputed } from '../../data/utils';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  

const PasswordEmail = (props) => {
	const fields = usePasswordEmailFields();
	const [ errors, setErrors ] = useState(false);
	
	const { appInfo, darkMode } = props.appReducer

	const sendEmail = async () => {

		const errors = validateForm(fields);
		setErrors(errors);

		if (!errors.length) {
			props.showLoading(true)
			try {
				let response = await API.post_request({
					endPoint: 'auth/password/email',
					params: {
						email: fields[0].input.state.value,
					}
        })

        props.showLoading(false)
        console.log(response)

        response = response.data

        Swal.fire({  
          icon: 'success',  
          title: `<strong class="sweet-alert-success">${response.title}</strong>`,
          text: response.message, 
          showConfirmButton: true,
          allowEscapeKey: false,
          confirmButtonColor: "#2178C3",
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
          }
        })
			} catch (error) {
        props.showLoading(false)
        setErrors([{
          id: 'email',
          message: error.message
        }])
			}
		}
	}

  useEffect(() => {
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color) ? ( 
				<>
					<IonHeader>
						<IonToolbar color="transparent" style={{backgroundColor: darkMode ? '#343A40' : appInfo.color.background_color}}>
							<IonTitle className="ion-text-center font-20 text-bold" style={{color: darkMode ? '#5ECBF7' : appInfo.color.color}}>{appInfo.name}</IonTitle>
						</IonToolbar>
					</IonHeader>
					<IonContent fullscreen>
            <IonGrid className="ion-padding main-container d-flex ion-align-items-center ion-justify-content-center">
              <IonRow className="w-100">
                <IonCol size="12" className="d-flex ion-align-items-center ion-justify-content-center mb-24">
                  <IonImg src={appInfo.icon_url} className="height-75 width-75 app-icon" />
                </IonCol>
                <IonCol size="12">
                  { fields.map((field, index) => {
                    return <CustomField field={ field } key={ index } errors={ errors } />;
                  }) }
                  <IonLabel className="ion-text-center font-16 text-center">利用者登録後、アプリ内から利用者IDに対して受信可能なメールアドレスを設定しましたか？設定済みの場合、メールアドレス宛にパスワードリセットコードを送信し、利用者IDのパスワードをリセットすることができます。</IonLabel>
                  <button type="button" className={`btn btn-blue height-60 mt-20 border-radius-30 btn-block font-20 line-height-60 text-bold ${formInputed(fields) ? '' : 'btn-disabled'}`} disabled={!formInputed(fields)} onClick={sendEmail}>パスワードリセット</button>
                </IonCol>
              </IonRow>
            </IonGrid>

						<IonFooter>
							<IonGrid className="ion-no-margin ion-no-padding d-flex ion-justify-content-center">
								<Action text="新規登録" className="custom-link" link={`/${appInfo.slug}/register`} />
								<Action text="ログイン" className="custom-link ml-20" link={`/${appInfo.slug}/login`} />
							</IonGrid>
						</IonFooter>
					</IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
		appReducer: state.appReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		showLoading: (value) => dispatch(showLoading(value))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PasswordEmail);

