import { IonContent, IonPage, IonLoading, IonGrid, IonCol, IonRow, IonLabel, IonBadge } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { showLoading, updateAuthUser } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import Header from '../../components/Header';

const RegisterOrganization = (props) => {
  const [ needRedirect, setNeedRedirect ] = useState(false);
  const [ organization, setOrganization ] = useState('');
	
	const { appInfo } = props.appReducer

	const setPassword = async () => {
    props.showLoading(true)
    try {
      let response = await API.post_request({
        endPoint: 'auth/register/organization',
        params: {
          organization_id: organization
        }
      })
      props.showLoading(false)

      response = response.data

      const user = response.user

      props.updateAuthUser(user)
      Swal.fire({  
        icon: 'success',  
        title: `<strong class="sweet-alert-success">${response.title}</strong>`,
        text: response.message, 
        showConfirmButton: true,
        allowEscapeKey: false,
        confirmButtonColor: "#2178C3",
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          setNeedRedirect(true)
        }
      })
    } catch (error) {
      props.showLoading(false)

      Swal.fire({  
        icon: 'error',  
        text: error.message, 
        showConfirmButton: false,
        timer: 3000 
      }); 
    }
      
  }
  
  const changeOptionSelect = (e) => {
    setOrganization(e.currentTarget.value)
  }


  useEffect(() => {
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color) ? (
				<>
          <Header title={appInfo.name} href={`/${appInfo.slug}`}></Header>
          <IonContent fullscreen>
            {needRedirect ? (
              <IonGrid className="ion-padding px-30 py-30">
                <IonRow>
                  <IonCol size="12" className="mb-20">
                    <IonLabel className="ion-text-center font-18 px-10">
                      <div className="mb-40 font-20 text-bold color-green text-center">利用者設定が完了しました。</div>
                      <div className="text-center">ご入力、お疲れさまでございました。<br/>このアプリを使って、みんなで住み良いまちを作りましょう。</div>
                    </IonLabel>
                  </IonCol>
                  <IonCol size="12">
                    <a href={`/${appInfo.slug}/top`} className={`btn btn-blue height-60 mt-40 border-radius-30 btn-block font-20 line-height-60 text-bold d-block no-decoration`}>はじめる</a>
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : (
              <IonGrid className="ion-padding px-30 py-30">
                <IonRow>
                  <IonCol size="12" className="mb-20">
                    <IonLabel className="ion-text-center font-18 px-10">最後に、さくらまち町内会内での所属を選択してください。ご不明の場合「わからない」を選択してください。後ほど代表利用者により入力が行われます。</IonLabel>
                  </IonCol>
                  <IonCol size="12">
                    <div className="d-flex align-items-center ion-justify-content-between mt-20">
                      <div className="flex-1 align-items-center d-flex ion-justify-content-between mr-20">
                        <IonLabel className="font-18 text-bold">所属</IonLabel>
                        <IonBadge color="draft border-radius-4 back-light-gray color-blue">任意</IonBadge>
                      </div>
                      <div className="flex-1 height-60">
                        <select className="custom-select" onChange={e => changeOptionSelect(e)}>
                          <option value="">わからない</option>
                          {appInfo.organizations.map(item => {
                            return <option key={item.id} value={item.id}>{item.name}</option>
                          })}
                        </select>
                      </div>
                    </div>
                    <button type="button" className={`btn btn-blue height-60 mt-20 border-radius-30 btn-block font-20 line-height-60 text-bold`} onClick={setPassword}>次へ</button>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}
            
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
		appReducer: state.appReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterOrganization);

