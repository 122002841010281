import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reducer from '../reducer';
import dataSaga from '../saga';

const persistConfig = {
  key: 'apps',
  storage,
}
const persistedReducer = persistReducer(persistConfig, reducer)

const sagaMiddleware = createSagaMiddleware();

export default function configureStore() {
  const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
  let persistor = persistStore(store)
  sagaMiddleware.run(dataSaga);
  return {store, persistor}
  // return store;
}
