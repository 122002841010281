import { IonContent, IonPage, IonImg, IonGrid, IonCol, IonRow, IonRouterLink, IonIcon, IonLabel, IonLoading } from '@ionic/react';
import React, { useEffect } from 'react';
import { fetchApps, updateAppStatus, showLoading, updateAuthUser } from '../../actions';
import {connect} from 'react-redux';
import { calendarNumberOutline, folderOutline, hammerOutline, mailOutline } from 'ionicons/icons';
import { MANAGE_SITE_URL } from '../../config/url';
import Header from '../../components/Header';
import styles from './Top.module.scss';

const Top = (props) => {
  const { appInfo } = props.appReducer
  const { me } = props.userReducer

  useEffect(() => {
  }, []);

  const renderLinkItem = (type, category) => {
    const style = {
      backgroundColor: `${category.background_color}`,
      color: `${category.color}`
    }
    return (
      <div className="top-link-item">
        <IonRouterLink routerLink={`/${appInfo.slug}/${type}/${category.id}`} className="top-link-item-tab" style={style}>
          <div className="d-flex align-items-center justify-content-center"><IonImg src={category.icon_url} className="icon-class mb-10" /></div>
          <div className="ion-text-center"><IonLabel className="font-16 text-bold">{category.name}</IonLabel></div>
        </IonRouterLink>
      </div>
    )
  }
  
  const {layout_custom_icons, layout_default_icons, custom_icon} = appInfo
  const icons = custom_icon && layout_custom_icons.length ? layout_custom_icons:layout_default_icons

	return (
		<IonPage className={styles.topPage}>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          <Header title={appInfo.name} isTop={true}></Header>
          <IonContent fullscreen>
            <IonGrid className="ion-padding px-4 py-4">
              <IonRow>
                {icons.map((icon, index) => {
                  const style = {
                    backgroundColor: `${icon.background_color}`,
                    color: `${icon.color}`
                  }
                  if (icon.link.match( /^https?:\/\// )) {
                    return (
                      <IonCol sizeLg="3" size="4" key={index}>
                        <div className="top-link-item">
                          <IonRouterLink href={icon.link} target="_blank" className="top-link-item-tab" style={style}>
                            <div className="d-flex align-items-center justify-content-center">
                              <IonImg src={icon.icon_url} className="icon-class mb-10" />
                            </div>
                            <div className="ion-text-center">
                              <IonLabel className="font-16 text-bold">{icon.name}</IonLabel>
                            </div>
                          </IonRouterLink>
                        </div>
                      </IonCol>
                    )
                  } else {
                    return (
                      <IonCol sizeLg="3" size="4" key={index}>
                        <div className="top-link-item">
                          <IonRouterLink routerLink={icon.link} className="top-link-item-tab" style={style}>
                            <div className="d-flex align-items-center justify-content-center">
                              <IonImg src={icon.icon_url} className="icon-class mb-10" />
                            </div>
                            <div className="ion-text-center">
                              <IonLabel className="font-16 text-bold">{icon.name}</IonLabel>
                            </div>
                          </IonRouterLink>
                        </div>
                      </IonCol>
                    )
                  }
                })}
              </IonRow>
            </IonGrid>
          </IonContent>
				</>
      ) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Top);

