import { IonPage, IonContent, IonLoading } from '@ionic/react';
import { useParams, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { fetchApps, updateAppStatus, showLoading, updateAuthUser } from '../../actions';
import { connect } from 'react-redux';
import * as API from '../../apis/api';

const Start = (props) => {
	const { appInfo } = props.appReducer
  const { app, unique_id } = useParams()
  let history = useHistory();

	const startApp = async () => {
    props.showLoading(true)
    try {
      let response = await API.post_request({
        endPoint: 'auth/start',
        params: {
          unique_id
        }
      })
      
      props.showLoading(false)

      response = response.data
      const user = response.user
      sessionStorage.setItem('auth_token', response.token)
      props.updateAuthUser(user)
      
      history.push(`/${response.slug}/top`)
    } catch (error) {
      props.showLoading(false)
      history.push(`/`)
    }
	}

  useEffect(() => {
    if (appInfo.slug != app) history.push(`${appInfo.slug}/login`)
    startApp()
  }, []);

	return (
		<IonPage>) : (
      <IonContent fullscreen>
        <IonLoading
          cssClass="custom-loading-css"
          isOpen={true}
          spinner="crescent" />
      </IonContent>
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
		showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Start);

