import { IonContent, IonPage, IonIcon, IonGrid, IonCol, IonRow, IonButton, IonImg, IonLoading } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, showLoading } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import { cloudDownloadSharp } from 'ionicons/icons';
import Header from '../../components/Header';
import Loading from '../../components/Loading';

const Index = (props) => {
  const { id } = useParams()
  let history = useHistory();
  const { appInfo } = props.appReducer
  const { me } = props.userReducer
  const contentRef = useRef()
  const [ library, setLibrary ] = useState({})
  const [ loading, setLoading ] = useState({ status: false, text: '読込中…' })

  const loadLibrary = async () => {
    try {
      setLoading({ status: true, text: '読込中…' })

      let response = await API.post_request({
        endPoint: `libraries/${id}/show`,
        params: {}
      })

      setLoading({ status: false, text: '' })
      response = response.data

      setLibrary(response.library)
    } catch (error) {
      history.push(`/${appInfo.slug}/library`)
    }
  }

  const downloadFile = () => {
    window.open(`${library.file.full_url}&d=1`)
  }

  const init = async () => {
    if (me.id) {
      loadLibrary()
    }
  }

  const deleteLibrary = async () => {
    Swal.fire({  
      title: `<strong>役員引継資料を削除</strong>`,
      text: '自分が登録した役員引継資料を、本当に削除してよろしいですか？',
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-cancel-button' //insert class here
      },
      cancelButtonColor: "#2178C3",
      allowEscapeKey: false,
      confirmButtonText: 'いいえ',
      cancelButtonText: 'はい',
      allowOutsideClick: false
    }).then(async (result) => {
      if (!result.isConfirmed) {
        props.showLoading(true)
        try {
          let response = await API.post_request({
            endPoint: `libraries/${id}/destroy`,
            params: {}
          })
          
          response = response.data

          props.showLoading(false)
          Swal.fire({  
            icon: 'success',  
            title: `<strong class="sweet-alert-success">${response.title}</strong>`,
            text: response.message, 
            showConfirmButton: true,
            allowEscapeKey: false,
            confirmButtonColor: "#2178C3",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`/${appInfo.slug}/library`)
            }
          })

        } catch (error) {
          props.showLoading(false)

          Swal.fire({  
            icon: 'error',  
            text: error.message, 
            showConfirmButton: false,
            timer: 3000 
          }); 
        }

      }
    })
  }

  useEffect(() => {
    init()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          <Header title="役員引継書庫"></Header>
          <IonContent ref={contentRef} fullscreen>
            <IonGrid className="ion-padding px-15 py-15">
              <IonRow>
                <Loading loading={loading}></Loading>
                
                {library.id && (
                  <>
                    <IonCol size="12" className="d-flex align-items-top ion-justify-content-between mb-10">
                      <IonImg src={library.file.thumb_url} className="width-100 height-100 mr-12 img-cover" />
                      <div className="flex-1">
                        <div className="color-black font-18 text-bold mb-10">{library.title}</div>
                        <div className="color-black font-14"><span className="text-bold">登録者名</span>：{library.user_name}</div>
                        <div className="color-black font-14"><span className="text-bold">登録日時</span>：{library.created_at_label}</div>
                      </div>
                    </IonCol>
                    <IonCol size="12" className="color-black font-16 html-code mb-20" innerHTML={library.content}></IonCol>
                    <IonCol size="12" className={`${me.id === library.user_id ? 'mb-10' : 'mb-40'}`}>
                      <IonButton className="btn btn-orange height-60 border-radius-30 btn-block font-20 line-height-60 text-bold" color="transparent" onClick={() => downloadFile()}>ダウンロード<IonIcon icon={cloudDownloadSharp} className="ml-10" /></IonButton>
                    </IonCol>
                    {me.id === library.user_id && (
                      <IonCol size="12" className="mb-40">
                        <IonButton className="btn btn-black height-60 border-radius-30 btn-block font-20 line-height-60 text-bold" color="transparent" onClick={() => deleteLibrary()}>削除</IonButton>
                      </IonCol>
                    )}
                  </>
                )}
              </IonRow>
            </IonGrid>
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
    showLoading: (value) => dispatch(showLoading(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);

