import { IonTitle, IonContent, IonPage } from '@ionic/react';

import React from 'react';

const Error404 = () => {
	
	return (
		<IonPage>
			<IonContent fullscreen>
				<div className="home-content">
          <IonTitle className="font-30 text-bold">ページはありません。</IonTitle>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Error404;

