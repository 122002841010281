import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
} from '../config/constant';

const initialState = {
  me: {},
  fetchingUser: false,
  error: false
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        me: {},
        fetchingUser: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        fetchingUser: false,
        me: action.me,
      };
    case FETCH_USER_FAIL:
      return {
        ...state,
        fetchingUser: false,
        error: true,
      };
    default:
      return state;
  }
}
