import {
  FETCH_APP,
  FETCH_USER,
  UPDATE_APP_STATUS,
  SET_LOADING,
  FETCH_USER_SUCCESS,
  FETCH_APP_SUCCESS,
  UPDATE_TOP_BANNER,
  UPDATE_DARK_MODE
} from '../config/constant';

export function fetchApps(slug = '') {
  return {
    type: FETCH_APP,
    slug: slug
  };
}

export function fetchUser() {
  return {
    type: FETCH_USER
  };
}

export function updateAppStatus(status = {}) {
  return {
    type: UPDATE_APP_STATUS,
    status: status
  }
}

export function showLoading(showLoading = false) {
  return {
    type: SET_LOADING,
    showLoading: showLoading
  }
}

export function updateAuthUser(user = {}) {
  return {
    type: FETCH_USER_SUCCESS,
    me: user
  }
}

export function updateAppInfo(appInfo = {}) {
  return {
    type: FETCH_APP_SUCCESS,
    appInfo: appInfo,
  }
}

export function updateTopBanner(topBanner = null) {
  return {
    type: UPDATE_TOP_BANNER,
    topBanner: topBanner
  }
}

export function updateDarkMode(darkMode = false) {
  return {
    type: UPDATE_DARK_MODE,
    darkMode: darkMode
  }
}