import { IonContent, IonPage, IonGrid, IonCol, IonRow, IonLabel, IonLoading, IonImg } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, updateAppStatus, showLoading, updateAuthUser } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import Header from '../../components/Header';
import styles from './Index.module.scss';

const Register = (props) => {
  const [ businesses, setBusinesses ] = useState(false);
  let history = useHistory();
  const contentRef = useRef()
	
  const { appInfo } = props.appReducer
  const { me } = props.userReducer

	const loadBusinesses = async () => {
    props.showLoading(true)
    try {
      let response = await API.post_request({
        endPoint: 'business/index',
        params: {}
      })
      props.showLoading(false)

      setBusinesses(response.data)
    } catch (error) {
      props.showLoading(false)

      Swal.fire({  
        icon: 'error',  
        text: error.message, 
        showConfirmButton: false,
        timer: 3000 
      }); 
    }
  }

  const gotoDetail = (business) => {
    history.push(`/${appInfo.slug}/business/${business.id}`)
  }

  useEffect(() => {
    if (appInfo.valid_businesses > 0) {
      loadBusinesses()
    } else {
      history.push(`/${appInfo.slug}/top`)
    }
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          <Header title="地域サポート事業者"></Header>
          <IonContent fullscreen ref={contentRef}>
            <IonGrid className="ion-padding px-0 py-0">
              <IonRow>
                <IonCol size="12" className={styles.businessBanner}>
                  <IonLabel>
                    まちにてぃを利用した地域運営は、<br/>
                    地元企業や事業者のキモチによって、<br/>
                    日々支えられています。
                  </IonLabel>
                </IonCol>
                {businesses && (
                  <>
                    {businesses.diamonds.length > 0 && (
                      <IonCol size="12" className={styles.diamond}>
                        <IonRow>
                          <IonCol size="12" className="d-flex align-items-center justify-content-center mb-20">
                            <IonLabel className={styles.line}></IonLabel>
                            <IonLabel className="font-24 text-bold mx-10 text-center">
                              Diamond Class<br/>
                              Supporters
                            </IonLabel>
                            <IonLabel className={styles.line}></IonLabel>
                          </IonCol>

                          {businesses.diamonds.map((business, index) => {
                            return (
                              <IonCol key={`platium_${index}`} className="mb-10 cursor transition" size="12" onClick={() => gotoDetail(business)}>
                                <div className={styles.businessLogo}>
                                  <IonImg src={business.image_url} />
                                </div>
                              </IonCol>
                            )
                          })}
                        </IonRow>
                      </IonCol>
                    )}

                    {businesses.platiums.length > 0 && (
                      <IonCol size="12" className={styles.platium}>
                        <IonRow>
                          <IonCol size="12" className="d-flex align-items-center justify-content-center mb-20">
                            <IonLabel className={styles.line}></IonLabel>
                            <IonLabel className="font-24 text-bold mx-10 text-center">
                              Platium Class<br/>
                              Supporters
                            </IonLabel>
                            <IonLabel className={styles.line}></IonLabel>
                          </IonCol>

                          {businesses.platiums.map((business, index) => {
                            return (
                              <IonCol key={`platium_${index}`} className="mb-10 cursor transition" size="6" onClick={() => gotoDetail(business)}>
                                <div className={styles.businessLogo}>
                                  <IonImg src={business.image_url} />
                                </div>
                              </IonCol>
                            )
                          })}
                        </IonRow>
                      </IonCol>
                    )}

                    {businesses.golds.length > 0 && (
                      <IonCol size="12" className={styles.gold}>
                        <IonRow>
                          <IonCol size="12" className="d-flex align-items-center justify-content-center mb-20">
                            <IonLabel className={styles.line}></IonLabel>
                            <IonLabel className="font-24 text-bold mx-10 text-center">
                              Gold Class<br/>
                              Supporters
                            </IonLabel>
                            <IonLabel className={styles.line}></IonLabel>
                          </IonCol>

                          {businesses.golds.map((business, index) => {
                            return (
                              <IonCol key={`platium_${index}`} className="mb-10 cursor transition" size="6" onClick={() => gotoDetail(business)}>
                                <div className={styles.businessLogo}>
                                  <IonImg src={business.image_url} />
                                </div>
                              </IonCol>
                            )
                          })}
                        </IonRow>
                      </IonCol>
                    )}

                    {businesses.silvers.length > 0 && (
                      <IonCol size="12" className={styles.silver}>
                        <IonRow>
                          <IonCol size="12" className="d-flex align-items-center justify-content-center mb-20">
                            <IonLabel className={styles.line}></IonLabel>
                            <IonLabel className="font-24 text-bold mx-10 text-center">
                              Silver Class<br/>
                              Supporters
                            </IonLabel>
                            <IonLabel className={styles.line}></IonLabel>
                          </IonCol>

                          {businesses.silvers.map((business, index) => {
                            return (
                              <IonCol key={`platium_${index}`} className="mb-10 cursor transition" size="6" onClick={() => gotoDetail(business)}>
                                <div className={styles.businessLogo}>
                                  <IonImg src={business.image_url} />
                                </div>
                              </IonCol>
                            )
                          })}
                        </IonRow>
                      </IonCol>
                    )}
                  </>
                )}
              </IonRow>
            </IonGrid>
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);

