import { IonContent, IonPage, IonGrid, IonCol, IonRow, IonButton, IonLoading } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, updateAppStatus, showLoading, updateAuthUser } from '../../actions';
import {connect} from 'react-redux';
import CustomField from '../../components/CustomField';
import { useBusinessRegisterFields } from '../../data/fields';
import { validateForm, formInputed } from '../../data/utils';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import Header from '../../components/Header';

const Register = (props) => {
  const fields = useBusinessRegisterFields();
  const [ errors, setErrors ] = useState(false);
  let history = useHistory();
  const contentRef = useRef()
	
  const { appInfo } = props.appReducer
  const { me } = props.userReducer

	const registerBusiness = async () => {
    const errors = validateForm(fields);

    setErrors(errors);
		if (!errors.length) {
      let params = {
        user_id: me.id,
        name: fields[0].input.state.value,
        address: fields[1].input.state.value,
        content: fields[2].input.state.value,
        rep_name: fields[3].input.state.value,
        rep_phone: fields[4].input.state.value,
        url: fields[5].input.state.value,
      }

      props.showLoading(true)
			try {
				let response = await API.post_request({
					endPoint: 'business/store',
					params: params
				})
        props.showLoading(false)

        if (response.status === 'validation_error') {
          setErrors(response.errors)
          return;
        } else {
          response = response.data
        }

        const user = response.user

        props.updateAuthUser(user)
        Swal.fire({  
          icon: 'success',  
          title: `<strong class="sweet-alert-success">${response.title}</strong>`,
          text: response.message, 
          showConfirmButton: true,
          confirmButtonColor: "#2178C3",
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`/${appInfo.slug}/top`)
          }
        })
			} catch (error) {
        props.showLoading(false)

				Swal.fire({  
					icon: 'error',  
					text: error.message, 
					showConfirmButton: false,
					timer: 3000 
				}); 
      }
    } else {
      contentRef.current && contentRef.current.scrollToTop(500);
    }
  }

  useEffect(() => {
    if (me && me.business) history.push(`/${appInfo.slug}/top`)
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          <Header title="事業者登録"></Header>
          <IonContent fullscreen ref={contentRef}>
            <IonGrid className="ion-padding px-30 py-30">
              <IonRow>
                <IonCol size="12">
                  { fields.map((field, index) => {
                    return <CustomField field={ field } key={ index } errors={ errors } />;
                  }) }
                  <IonButton className={`btn btn-green height-60 mt-20 border-radius-30 btn-block font-20 line-height-60 text-bold ${formInputed(fields) ? '' : 'btn-disabled'}`} disabled={!formInputed(fields)} onClick={registerBusiness} color="transparent">登録</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);

