import { IonContent, IonPage, IonImg, IonTitle, IonHeader, IonToolbar, IonFooter, IonGrid, IonCol, IonRow, IonLabel, IonLoading } from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { showLoading } from '../../actions';
import {connect} from 'react-redux';
import { Action } from '../../components/Action';
import CustomField from '../../components/CustomField';
import { usePasswordResetFields } from '../../data/fields';
import { validateForm, formInputed } from '../../data/utils';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import * as moment from 'moment';

const PasswordReset = (props) => {
	const fields = usePasswordResetFields();
  const [ errors, setErrors ] = useState(false);
  const [ checking, setChecking ] = useState(true)
  const [ userId, setUserId ] = useState('')
  const [ invalideCode, setInvalidCode ] = useState(false)

  let history = useHistory();
	
  const { appInfo, darkMode } = props.appReducer
  
  const { token } = useParams()

	const updatePassword = async () => {

		const errors = validateForm(fields);
		setErrors(errors);

		if (!errors.length) {
			props.showLoading(true)
			try {
				let response = await API.post_request({
					endPoint: 'auth/password/reset',
					params: {
            password: fields[0].input.state.value,
            password_confirmation: fields[1].input.state.value,
            user_id: userId
					}
        })

        props.showLoading(false)
				if (response.status == 'validation_error') {
					setErrors(response.errors)

          return;
				} else {
          response = response.data
        }

        Swal.fire({  
          icon: 'success',  
          title: `<strong class="sweet-alert-success">${response.title}</strong>`,
          text: response.message, 
          showConfirmButton: true,
          allowEscapeKey: false,
          confirmButtonColor: "#2178C3",
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`/${appInfo.slug}/login`)
          }
        })
			} catch (error) {
        props.showLoading(false)
        setErrors([{
          id: 'email',
          message: error.message
        }])
			}
		}
  }
  
  const checkToken = async () => {
    props.showLoading(true)
    try {
      let response = await API.post_request({
        endPoint: 'auth/passwor/reset/token_check',
        params: {
          token,
        }
      })
      props.showLoading(false)

      if (response.status == 'validation_error') {
        setInvalidCode(true)
        setChecking(false)

        return
      }

      setUserId(response.data.id)
      setChecking(false)
    } catch (error) {
      props.showLoading(false)
      history.push(`/${appInfo.slug}/login`)
    }
  }

  useEffect(() => {
    checkToken()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color) ? ( 
				<>
					<IonHeader>
						<IonToolbar color="transparent" style={{backgroundColor: darkMode ? '#343A40' : appInfo.color.background_color}}>
							<IonTitle className="ion-text-center font-20 text-bold" style={{color: darkMode ? '#5ECBF7' : appInfo.color.color}}>{appInfo.name}</IonTitle>
						</IonToolbar>
					</IonHeader>
					<IonContent fullscreen>
            {!checking && (
              <>
                {invalideCode ? (
                  <IonGrid className="ion-padding main-container d-flex ion-align-items-center ion-justify-content-center">
                    <IonLabel className="ion-text-center font-18 text-center px-10">パスワードリセットコードの有効期限が過ぎているか、無効です。再度メールアドレスへパスワードリセットコードを送信することをおすすめします。</IonLabel>
                  </IonGrid>
                ) : (
                  <IonGrid className="ion-padding main-container d-flex ion-align-items-center ion-justify-content-center">
                    <IonRow className="w-100">
                      <IonCol size="12" className="d-flex ion-align-items-center ion-justify-content-center mb-24">
                        <IonImg src={appInfo.icon_url} className="height-75 width-75 app-icon" />
                      </IonCol>
                      <IonCol size="12">
                        { fields.map((field, index) => {
                          return <CustomField field={ field } key={ index } errors={ errors } />;
                        }) }
                        <button type="button" className={`btn btn-blue height-60 mt-20 border-radius-30 btn-block font-20 line-height-60 text-bold ${formInputed(fields) ? '' : 'btn-disabled'}`} disabled={!formInputed(fields)} onClick={updatePassword}>パスワードを更新</button>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}
              </>
            )}

						<IonFooter>
							<IonGrid className="ion-no-margin ion-no-padding d-flex ion-justify-content-center">
								<Action text="新規登録" className="custom-link" link={`/${appInfo.slug}/register`} />
								<Action text="ログイン" className="custom-link ml-20" link={`/${appInfo.slug}/login`} />
							</IonGrid>
						</IonFooter>
					</IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
		appReducer: state.appReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		showLoading: (value) => dispatch(showLoading(value))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

