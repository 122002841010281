import { IonContent, IonPage, IonIcon, IonTitle, IonGrid, IonCol, IonRow, IonButton, IonImg, IonBadge, IonSearchbar, IonModal, IonLabel, IonTextarea, IonLoading } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, showLoading, updateAuthUser } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import { pencilOutline, closeCircleSharp } from 'ionicons/icons';
import Swal from "sweetalert2";  
import Zoom from '../../components/Zoom';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import * as moment from 'moment';
import styles from './Detail.module.scss';

const Detail = (props) => {
  const { category, id } = useParams()
  let history = useHistory();
  const { appInfo } = props.appReducer
  const { me } = props.userReducer
  const [ loading, setLoading ] = useState({ status: false, text: '読込中…' })
  const [ article, setArticle ] = useState({})
  const [ categoryItem, setCategoryItem ] = useState({})
  const [ searchText, setSearchText ] = useState('')
  const [ showCommentModal, setShowCommentModal ] = useState(false);
  const [ comment, setComment ] = useState('')
  const [ comments, setComments ] = useState([])
  const [ commentsCount, setCommentsCount ] = useState(0)
  const [ commentLoading, setCommentLoading ] = useState({ status: false, text: '読込中…' })
  const [ page, setPage ] = useState(1)
  const [ hasMore, setHasMore ] = useState(true)
  const contentRef = useRef()
  const [ showZoomer, setShowZoomer ] = useState({ show: false, id: undefined })
  const [ images, setImages ] = useState([])
  const [ banner, setBanner ] = useState(undefined)

	const loadArticle = async () => {
    try {
      setLoading({ status: true, text: '読込中…' })

      let response = await API.post_request({
        endPoint: `articles/${category}/${id}`,
        params: {}
      })

      setLoading({ status: false, text: '' })
      response = response.data

      setArticle(response.article)
      setCommentsCount(parseInt(response.comments_count))

      setBanner(response.banner)

      const articleId = parseInt(response.article.id)

      if (!me.article_read_ids.includes(articleId)) {
        props.updateAuthUser({...me, ...{ article_read_ids: [...me.article_read_ids, ...[articleId]]}})

        API.post_request({
          endPoint: `articles/${category}/${articleId}/read`,
          params: {}
        })
      }

      if (response.article.files.length > 0) {
        let tempPostImages = []
        response.article.files.map(file => {
          if (file.type.indexOf('image/') >= 0 && !file.deleted_at) tempPostImages.push(file)
        })
        setImages(tempPostImages)
      }

      if (response.article.can_comment === 1) {
        if (parseInt(response.comments_count) > 0) {
          setCommentLoading({ status: true, text: comments.length === 0 ? '読込中…' : 'さらに読込中…' })
          loadArticleComments()
        }
      } else {
        setHasMore(false)
      }
    } catch (error) {
      history.push(`/${appInfo.slug}/articles/${category}`)
    }
  }

  const loadArticleComments = async () => {
    try {
      setCommentLoading({ status: true, text: comments.length === 0 ? '読込中…' : 'さらに読込中…' })
      let lastCommentAt = undefined
      if (comments.length > 0) lastCommentAt = moment(comments[comments.length - 1].created_at).format('YYYY-MM-DD HH:mm:ss')

      let response = await API.post_request({
        endPoint: `articles/${category}/${id}/comments`,
        params: {
          page: 1,
          last_comment_at: lastCommentAt
        }
      })

      setCommentLoading({ status: false, text: '' })
      response = response.data

      setComments([...comments, ...response.data])

      setPage(response.current_page + 1)
      setHasMore(response.last_page > response.current_page)
    } catch (error) {
      setLoading({ status: false, text: '' })
    }
  }

  const init = async () => {
    if (me.id) {
      let categories = me.profile.my_article_categories.filter(articleCategory => articleCategory.id === parseInt(category))
      if (categories.length > 0) {
        setCategoryItem({ ...categories[0] })
        loadArticle()
      } else {
        history.push(`/${appInfo.slug}/top`)
      }
    }
  }

  const renderArticleHead = () => {
    const style = {
      backgroundColor: `${categoryItem.background_color}`
    }
    return (
      <IonCol size="12" className="d-flex align-items-top ion-justify-content-between mb-40 px-20">
        <div className="d-flex align-items-top flex-1 ion-justify-content-between">
          <IonImg src={categoryItem.icon_url} style={style} className="width-50 height-50 px-10 py-10 mr-12 border-radius-round" />
          {/* <IonImg src={categoryItem.icon_url} style={style} className="width-50 height-50 px-10 py-10 mr-12" /> */}
          <div className="flex-1">
            <div className="color-black font-16 mb-10"><span className="text-bold">投稿日</span>：{article.created_at_label}</div>
            <div className="color-black font-16"><span className="text-bold">投稿者</span>：{article.user_name}</div>
          </div>
        </div>
        <div>
          <div className="ml-10 article-title max-height-40 overflow-hidden">
            {me.article_read_ids.includes(article.id) ? (
              <IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-light-gray color-black vertical-align-middle">既読</IonBadge>
            ) : (
              <IonBadge color="success" className="px-8 py-4 border-radius-12 font-12 back-green vertical-align-middle color-white">未読</IonBadge>
            )}
          </div>
        </div>
      </IonCol>
    )
  }

  const renderFiles = (article) => {
    if (article.files.length === 0) {
      return ''
    }
    return (
      <IonCol size="12" className="d-flex align-items-center px-20 py-10">
        {article.files.map((file, index) => {
          return (
            <IonImg key={index} className="height-45 width-45 mr-10 border-gray-1 cursor img-cover" src={file.thumb_url} onClick={() => handleFileClick(file)} />
          )
        })}
      </IonCol>
    )
  }

  const handleFileClick = (file) => {
    if (file.deleted_at) {
      Swal.fire({  
        icon: 'warning',  
        text: 'ファイルが管理画面から削除されました。',
        showConfirmButton: false,
      })
      return;
    }
    if (file.type.indexOf('video/') >= 0) {
      let html = `<video class="object-contain" controls controlsList="nodownload" style="max-width: 100%;"><source src="${file.full_url}" /></video>`
      Swal.fire({  
        html: html,
        showConfirmButton: true,
        confirmButtonColor: '#2178C3',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: '閉じる',
      })
    } else if (file.type.indexOf('image/') >= 0) {
      setShowZoomer({
        show: true,
        id: file.id
      })
    } else {
      let fileType = 'その他'
      if (file.type.indexOf('application/pdf') >= 0) 
        fileType = 'PDF'
      else if (file.type.indexOf('officedocument.wordprocessingml') >= 0)
        fileType = 'DOC'
      else if (file.type.indexOf('officedocument.spreadsheetml') >= 0)
        fileType = 'EXCEL'
      else if (file.type.indexOf('application/zip') >= 0)
        fileType = 'ZIP'
      else if (file.type.indexOf('audio/') >= 0)
        fileType = '画像'

      Swal.fire({  
        title: `<strong>添付ファイルの保存</strong>`,
        text: `お使いの端末に${fileType}ファイルをダウンロードしてよろしいですか？`,
        showConfirmButton: true,
        showCancelButton: true,
        customClass: {
          confirmButton: 'custom-cancel-button' //insert class here
        },
        cancelButtonColor: "#2178C3",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'いいえ',
        cancelButtonText: 'はい'
      }).then(async (result) => {
        if (!result.isConfirmed) {
          window.open(`${file.full_url}&d=1`)
        }
      })
    }
  }

  const handleSearch = async (e) => {
    const tempValue = await e.currentTarget.value;
    setSearchText(tempValue);
  }

  const changeComment = async (e) => {
    const tempValue = await e.currentTarget.value;
    setComment(tempValue);
  }

  const sendComment = async (e) => {
    if (comment !== '') {
      props.showLoading(true)
      try {
        let response = await API.post_request({
          endPoint: `articles/${category}/${id}/comment`,
          params: {
            content: comment
          }
        })
        
        setComments([...[response.data.comment], ...comments])
        setCommentsCount(response.data.comments_count)
        props.showLoading(false)
        setComment('')
        setShowCommentModal(false)

        Swal.fire({  
          icon: 'success',  
          title: `<strong class="sweet-alert-success">コメント投稿！</strong>`,
          text: 'またひとつ、まちの活性化に貢献しました！',
          showConfirmButton: true,
          confirmButtonColor: "#2178C3",
        })
      } catch (error) {
        props.showLoading(false)

        Swal.fire({  
          icon: 'error',  
          text: error.message,
          showConfirmButton: false,
        })
      }
    }
  }

  const deleteComment = async (comment) => {
    Swal.fire({  
      title: `<strong>コメントを削除</strong>`,
      text: '自分が投稿したコメントを、本当に削除してよろしいですか？',
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-cancel-button' //insert class here
      },
      cancelButtonColor: "#2178C3",
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'いいえ',
      cancelButtonText: 'はい'
    }).then(async (result) => {
      if (!result.isConfirmed) {
        let response = await API.post_request({
          endPoint: `articles/${category}/${id}/${comment.id}/delete`,
          params: {}
        })

        setComments(comments.map(item => {
          if (item.id === comment.id) comment.deleted_at = response.data
          return item
        }))
      }
    })
  }

  const toggleCommentFavorite = async (comment) => {
    try {
      let response = await API.post_request({
        endPoint: `articles/${category}/${id}/${comment.id}/favorite`,
        params: {}
      })

      let newComments = comments.map(item => {
        if (item.id == comment.id) item.favorite_user_ids = response.data
        return item
      })
      
      setComments(newComments)
    } catch (error) {
    }
  }

  const toggleSave = async () => {
    let ids = [...me.article_save_ids]
    if (ids.includes(article.id)) {
      ids = ids.filter(item => item !== article.id)

      Swal.fire({  
        icon: 'success',  
        title: `<strong class="sweet-alert-success">保存をやめました。</strong>`,
        showConfirmButton: true,
        confirmButtonColor: "#2178C3",
      })
    } else {
      ids.push(article.id)

      Swal.fire({  
        icon: 'success',  
        title: `<strong class="sweet-alert-success">記事を保存しました。</strong>`,
        showConfirmButton: true,
        confirmButtonColor: "#2178C3",
      })
    }

    props.updateAuthUser({...me, ...{ article_save_ids: [...ids]}})

    API.post_request({
      endPoint: `articles/${category}/${id}/toggle/save`,
      params: {}
    })
  }

  const toggleFavorite = async () => {
    let ids = [...me.article_favorite_ids]
    if (ids.includes(article.id)) {
      ids = ids.filter(item => item !== article.id)
    } else {
      ids.push(article.id)
    }

    props.updateAuthUser({...me, ...{ article_favorite_ids: [...ids]}})

    try {
      let response = await API.post_request({
        endPoint: `articles/${category}/${id}/toggle/favorite`,
        params: {}
      })

      setArticle({...article, ...{favorite_user_ids: response.data}})
    } catch (error) {

    }
  }

  const onScrollHandler = async (e) => {
    if (commentLoading.status) return;

    const scrollElement = await e.target.getScrollElement();

    const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
    const currentScrollDepth = scrollElement.scrollTop;
    const targetPercent = 80;

    let triggerDepth = ((scrollHeight / 100) * targetPercent);

    if(currentScrollDepth > triggerDepth) {
      loadArticleComments()
    }
  }

  const getBreakpoints = () => {
    const { innerHeight: height } = window;
    return [
      0,
      420 / height
    ]
  }

  const getInitialBreakpoint = () => {
    const { innerHeight: height } = window;
    return 420 / height
  }

  const clickBanner = (banner) => {
    if (banner.url) {
      window.open(banner.url, '_system')
    } else {
      history.push(`/${appInfo.slug}/business/${banner.business_id}`)
    }
  }

  useEffect(() => {
    init()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? ( 
				<>
          {showZoomer.show === false && (
            <Header title={categoryItem.name}></Header>
          )}
          <IonContent ref={contentRef} fullscreen onIonScrollEnd={(e) => onScrollHandler(e)} scrollEvents={hasMore}>
            <IonGrid className="ion-padding px-0 py-0">
              <IonRow>
                <Loading loading={loading}></Loading>
                {(article && article.id) && (
                  <>
                    <IonCol size="12" className="font-22 text-bold color-black px-20 py-20 mb-20 border-bottom-1" expands="block">{article.title}</IonCol>
                    
                    {renderArticleHead()}

                    {banner && (
                      <IonCol size="12" className="mb-20 px-20 py-0">
                        <div onClick={() => clickBanner(banner)} className={styles.bannerImage}>
                          <img src={banner.image_url} alt="バナー広告" />
                        </div>
                      </IonCol>
                    )}

                    <IonCol size="12" className="mb-20 html-code px-20" innerHTML={article.content}></IonCol>

                    {renderFiles(article)}

                    <IonCol size="12" className="d-flex mb-10 align-items-center ion-justify-content-center px-20">
                      {me.article_save_ids.includes(article.id) ? (
                        <IonButton color="transparent" class="btn btn-yellow height-45 line-height-45 flex-1 mr-10 font-20 text-bold border-radius-24" onClick={() => toggleSave()}>保存をやめる</IonButton>
                      ) : (
                        <IonButton color="transparent" class="btn btn-yellow height-45 line-height-45 flex-1 mr-10 font-20 text-bold border-radius-24" onClick={() => toggleSave()}>記事を保存</IonButton>
                      )}
                      {article.user_id === me.id ? (
                        <IonButton color="transparent" class="btn btn-light-blue btn-block height-45 line-height-45 flex-1 font-20 text-bold border-radius-24" disabled={true}>
                          いいね！
                          {article.favorite_user_ids.length > 0 && <IonBadge color="secondary" className="back-blue border-radius-round ml-10 width-24 height-24 text-center line-height-24 py-0 px-0">{article.favorite_user_ids.length}</IonBadge>}
                        </IonButton>
                      ) : (
                        <IonButton color="transparent" class="btn btn-light-blue btn-block height-45 line-height-45 flex-1 font-20 text-bold border-radius-24" onClick={() => toggleFavorite()}>
                          いいね！
                          {article.favorite_user_ids.length > 0 && <IonBadge color="secondary" className="back-blue border-radius-round ml-10 width-24 height-24 text-center line-height-24 py-0 px-0">{article.favorite_user_ids.length}</IonBadge>}
                        </IonButton>
                      )}
                    </IonCol>

                    {article.can_comment === 0 ? (
                      <IonCol size="12" className="px-20 mb-40">
                        <IonRow>
                          <IonCol size="12" className="back-light-yellow px-20 py-20">
                            <div expands="block" className="color-black ion-text-center font-18">※コメントは無効化されています。</div>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    ) : (
                      <>
                        {commentsCount === 0 ? (
                          <>
                            <IonCol size="12" className="px-20">
                              <IonRow>
                                <IonCol size="12" className="back-light-blue px-20 py-20">
                                  <IonRow>
                                    <IonCol size="12" className="color-black text-bold mb-12 ion-text-center font-18">
                                      最初のコメントをしましょう！
                                    </IonCol>
                                    <IonCol size="12" className="my-0">
                                      <IonButton className="btn btn-blue btn-block height-60 line-height-60 py-0 ion-text-center border-radius-30 font-20 text-bold" color="transparent" onClick={() => setShowCommentModal(true)}>コメントを書く</IonButton>
                                    </IonCol>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </>
                        ) : (
                          <>
                            <IonCol size="12" className="font-24 text-bold color-black px-20">記事へのコメント</IonCol>
                            <IonCol size="12" className="px-20 py-10 back-light-gray mb-10 search-bar">
                              <IonSearchbar value={searchText} onIonChange={e => handleSearch(e)} showCancelButton="never" placeholder="返信をキーワード検索" color="light" className="mx-0 px-0 py-0 height-45"></IonSearchbar>
                            </IonCol>
                            {comments.map((comment, index) => {
                              if (searchText !== '') {
                                if (comment.deleted_at) return ''

                                if (comment.content.indexOf(searchText) < 0 && comment.user_name.indexOf(searchText) < 0 && comment.created_at_label.indexOf(searchText) < 0) {
                                  return ''
                                }
                              }
                              if (comment.deleted_at) {
                                if (comment.delete_by == 1) {
                                  return (
                                    <IonCol key={index} size="12" className="px-20 py-20 back-light-gray ion-text-center my-4 deleted-item">このコメントは管理者により削除されました。</IonCol>
                                  )
                                }
                                return (
                                  <IonCol key={index} size="12" className="px-20 py-20 back-light-gray ion-text-center my-4 deleted-item">このコメントは投稿者により削除されました。</IonCol>
                                )
                              }

                              return (
                                <IonCol key={index} size="12" className="px-20 py-10">
                                  <IonRow>
                                    <IonCol size="12" className="ion-text-right">
                                      {(comment.read_user_ids && comment.read_user_ids.includes(me.id)) ? (
                                        <IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-light-gray color-black vertical-align-middle">既読</IonBadge>
                                      ) : (
                                        <IonBadge color="success" className="px-8 py-4 border-radius-12 font-12 back-green vertical-align-middle color-white">未読</IonBadge>
                                      )}
                                    </IonCol>
                                    <IonCol size="12" className="color-black pre-wrap" innerHTML={comment.content}></IonCol>
                                    <IonCol size="7" className="d-flex align-items-center">
                                      {comment.user_id !== me.id && (
                                        <div className="max-width-120 mr-8">
                                          <IonButton color="transparent" class="btn btn-light-blue btn-block height-30 line-height-30 flex-1 font-14 text-bold border-radius-24" onClick={() => toggleCommentFavorite(comment)}>
                                            いいね！
                                            {(comment.favorite_user_ids && comment.favorite_user_ids.length > 0) && <IonBadge color="secondary" className="back-blue border-radius-round ml-10 width-20 height-20 text-center line-height-20 py-0 px-0 font-12">{comment.favorite_user_ids.length}</IonBadge>}
                                          </IonButton>
                                        </div>
                                      )}
                                      {comment.user_id === me.id && (
                                        <>
                                          <div className="max-width-120 mr-8">
                                            <IonButton color="transparent" class="btn btn-blue btn-block height-30 line-height-30 flex-1 font-14 text-bold border-radius-24" disabled={true}>
                                              いいね！
                                              {(comment.favorite_user_ids && comment.favorite_user_ids.length > 0) && <IonBadge color="secondary" className="back-blue border-radius-round ml-10 width-20 height-20 text-center line-height-20 py-0 px-0 font-12">{comment.favorite_user_ids.length}</IonBadge>}
                                            </IonButton>
                                          </div>
                                          <div className="max-width-60">
                                            <IonButton color="transparent" class="btn btn-orange btn-block height-30 line-height-30 flex-1 font-14 text-bold border-radius-24" onClick={() => deleteComment(comment)}>削除</IonButton>
                                          </div>
                                        </>
                                      )}
                                    </IonCol>
                                    <IonCol size="5" className="ion-text-right color-black font-11">
                                      <strong>投稿者</strong>：{comment.user_name}<br/>
                                      <strong>日時</strong>:{comment.created_at_label}<br/>
                                    </IonCol>
                                  </IonRow>
                                </IonCol>
                              )
                            })}
                            <Loading loading={commentLoading}></Loading>
                            {(!commentLoading.status && !hasMore) && (
                              <>
                                {comments.length === 0 ? (
                                  <IonCol size="12" className="ion-text-center py-20 px-20">表示できるコメントが、見つかりませんでした。</IonCol>
                                ) : (
                                  <IonCol size="12" className="ion-text-center py-20 px-20">表示できるコメントが、これ以上見つかりませんでした。</IonCol>
                                )}
                              </>
                            )}
                            {hasMore && (
                              <div className="height-140">&nbsp;</div>
                            )}
                          </>
                        )}
                      </>
                    )}

                  </>
                )}
              </IonRow>
              {(article.can_comment === 1 && commentsCount > 0 && showZoomer.show === false) && <IonButton className="btn btn-blue border-radius-round width-56 height-56 ion-text-center py-0 line-height-56 font-10 text-bold btn-comment" color="transparent" onClick={() => setShowCommentModal(true)}>コメント</IonButton>}
            </IonGrid>

            <IonModal
              isOpen={showCommentModal}
              cssClass='my-custom-modal'
              swipeToClose={true}
              onDidDismiss={() => setShowCommentModal(false)}
              onIonModalDidDismiss={() => setComment('')}
              breakpoints={getBreakpoints()}
              initialBreakpoint={getInitialBreakpoint()}
              handle={false}>
              <IonRow>
                <IonCol size="12" className="d-flex align-items-center justify-content-center px-20 py-10 main-text-color">
                  <IonIcon icon={pencilOutline} className="mr-10 font-28" />
                  <IonLabel className="font-20 text-bold flex-1">この記事にコメント</IonLabel>
                  <IonIcon icon={closeCircleSharp} className="ml-10 font-28 color-light-gray mx-0 px-0" onClick={() => setShowCommentModal(false)} />
                </IonCol>
                <IonCol size="12" className="flex-1">
                  <IonRow>
                    <IonCol size="12" className="color-black text-bold">コメント</IonCol>
                    <IonCol size="12">
                      <IonTextarea placeholder="コメントを入力..." rows={10} className="border-gray-1 color-black px-10" value={comment} onIonChange={e => changeComment(e)} onKeyUp={e => changeComment(e)}></IonTextarea>
                    </IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size="12" className="d-flex mt-20 ion-justify-content-end">
                  <IonButton className={`btn btn-blue height-45 line-height-45 py-0 px-20 ion-text-center border-radius-24 font-20 text-bold ${comment !== '' ? '' : 'btn-disabled'}`} disabled={comment === ''} color="transparent" onClick={(e) => sendComment(e)}>コメントを投稿</IonButton>
                </IonCol>
              </IonRow>
            </IonModal>

            {showZoomer.show && (
              <Zoom onHideClick={() => setShowZoomer({ show: false, id: undefined})} images={images} id={showZoomer.id}/>
            )}
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Detail);

