import { IonContent, IonPage, IonIcon, IonGrid, IonCol, IonRow, IonText, IonRouterLink, IonBadge, IonLoading } from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, showLoading } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import { chevronForwardOutline, starOutline } from 'ionicons/icons';
import Header from '../../components/Header';
import Loading from '../../components/Loading';

const SaveIndex = (props) => {
  const { appInfo } = props.appReducer
  const { me } = props.userReducer
  const [ loading, setLoading ] = useState({ status: false, text: '読込中…' })
  const [ page, setPage ] = useState(1)
  const [ hasMore, setHasMore ] = useState(true)
  const [ articles, setArticles ] = useState([])
  const contentRef = useRef()

	const loadArticles = async () => {
    try {
      setLoading({ status: true, text: articles.length === 0 ? '読込中…' : 'さらに読込中…' })

      let response = await API.post_request({
        endPoint: `saved/articles`,
        params: {
          page: page
        }
      })

      setLoading({ status: false, text: '' })
      response = response.data

      setArticles([...articles, ...response.data])

      setPage(response.current_page + 1)
      setHasMore(response.last_page > response.current_page)
    } catch (error) {
      setLoading({ status: false, text: '' })

      Swal.fire({  
        icon: 'error',  
        text: error.message, 
        showConfirmButton: false,
        timer: 3000 
      }); 
    }
  }

  const onScrollHandler = async (e) => {
    if (loading.status) return;

    const scrollElement = await e.target.getScrollElement();

    const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
    const currentScrollDepth = scrollElement.scrollTop;
    const targetPercent = 80;

    let triggerDepth = ((scrollHeight / 100) * targetPercent);

    if(currentScrollDepth > triggerDepth) {
      loadArticles()
    }
  }

  const init = async () => {
    if (me.id) {
      setArticles([])
      setPage(1)
      setHasMore(false)

      loadArticles()
    }
  }

  const renderArticle = (article) => {
    const style = {
      backgroundColor: `#DDD237`
    }
    return (
      <IonRouterLink routerLink={`/${appInfo.slug}/articles/${article.category_id ? article.category_id : appInfo.public_category_id}/${article.id}`}>
        <div className="d-flex align-items-center ion-justify-content-between border-bottom-1 py-10">
          <div className="d-flex align-items-top flex-1 ion-justify-content-between">
            <IonIcon icon={starOutline} style={style} className="width-50 height-50 px-10 py-10 mr-12 border-radius-round color-white" />
            <div className="flex-1">
              <div className="mb-10 article-title max-height-40 overflow-hidden">
                {me.article_read_ids.includes(article.id) ? (
                  <IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-light-gray color-black vertical-align-middle">既読</IonBadge>
                ) : (
                  <IonBadge color="success" className="px-8 py-4 border-radius-12 font-12 back-green vertical-align-middle color-white">未読</IonBadge>
                )}
                <IonText className="ml-8 text-bold font-16 color-black word-break-all line-height-20">{article.title}</IonText>
              </div>
              <div className="d-flex align-items-center justify-content-between color-black-light font-12 w-100">
                <div className="flex-1">投稿日：{article.created_at_label}</div>
                {article.category && (
                  <div>投稿者：{article.user_name}</div>
                )}
              </div>
            </div>
          </div>
          <div>
            <IonIcon icon={chevronForwardOutline} className="height-32 width-32 color-light-gray ml-10" />
          </div>
        </div>
      </IonRouterLink>
    )
  }

  useEffect(() => {
    init()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          <Header title="保存した記事"></Header>
          <IonContent ref={contentRef} fullscreen onIonScrollEnd={(e) => onScrollHandler(e)} scrollEvents={hasMore}>
            <IonGrid className="ion-padding px-10 py-10">
              <IonRow>
                {articles.map((article, index) => {
                  return (
                    <IonCol size="12" key={index} className="mb-16">
                      {renderArticle(article)}
                    </IonCol>
                  )
                })}
                <Loading loading={loading}></Loading>
                {(!loading.status && !hasMore) && (
                  <>
                    {articles.length === 0 ? (
                      <IonCol size="12" className="ion-text-center py-20 px-20">表示できる記事が、見つかりませんでした。</IonCol>
                    ) : (
                      <IonCol size="12" className="ion-text-center py-20 px-20">表示できる記事が、これ以上見つかりませんでした。</IonCol>
                    )}
                  </>
                )}
                {hasMore && (
                  <div className="height-100">&nbsp;</div>
                )}
              </IonRow>
            </IonGrid>
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
    showLoading: (value) => dispatch(showLoading(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SaveIndex);

