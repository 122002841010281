import { IonContent, IonPage, IonHeader, IonIcon, IonToolbar, IonButtons, IonButton } from '@ionic/react';
import React, { useEffect } from 'react';
import { fetchApps, updateAppStatus, showLoading, updateAuthUser, updateTopBanner } from '../../actions';
import {connect} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { closeOutline } from 'ionicons/icons';
import styles from './Top.module.scss';

const Top = (props) => {
  const { appInfo, topBanner, darkMode } = props.appReducer
  let history = useHistory();

  const openNewTab = () => {
    window.open(topBanner.url, '_system')
  }

  const moveToDetail = () => {
    history.push(`/${appInfo.slug}/business/${topBanner.business_id}`)
    props.updateTopBanner(undefined)
  }

  const closeBanner = () => {
    history.push(`/${appInfo.slug}/top`)
    props.updateTopBanner(undefined)
  }

  useEffect(() => {
    if (!topBanner) history.push(`/${appInfo.slug}/top`)
  }, []);

	return (
		<IonPage>
			{topBanner && (
				<>
          <IonHeader>
            <IonToolbar color="transparent" style={{backgroundColor: darkMode ? '#343A40' : appInfo.color.background_color}}>
              <IonButtons slot="start">
                {topBanner.url ? (
                  <IonButton onClick={openNewTab} color="transparent" class="btn btn-blue height-60 line-height-60 font-22 text-bold border-radius-30 w-100 px-20">公式サイトへ</IonButton>
                ) : (
                  <IonButton onClick={moveToDetail} color="transparent" class="btn btn-blue height-60 line-height-60 font-22 text-bold border-radius-30 w-100 px-20">事業者ページへ</IonButton>
                )}
              </IonButtons>
              <IonButtons slot="end">
                <IonIcon onClick={closeBanner} icon={closeOutline} className="height-32 width-32" style={{color: darkMode ? '#5ECBF7' : appInfo.color.color}} />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <div className={styles.bannerTopPage}>
              <div className={styles.bannerImage}>
                <img src={topBanner.image_url} alt="" />
              </div>
            </div>
          </IonContent>
				</>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
    showLoading: (value) => dispatch(showLoading(value)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
    updateTopBanner: (value) => dispatch(updateTopBanner(value))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Top);

