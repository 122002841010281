import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonMenuButton, IonRouterLink, IonImg, IonLabel } from "@ionic/react";
import { chevronBackOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import {connect} from 'react-redux';

const Header = (props) => {
  let history = useHistory();
  const { appInfo, darkMode } = props.appReducer

  return (
    <>
      {(appInfo.slug && appInfo.color) && (
        <IonHeader>
          <IonToolbar color="transparent" style={{backgroundColor: darkMode ? '#343A40' : appInfo.color.background_color}}>
            {!props.isTop ? (
              <>
                {props.href ? (
                  <IonButtons slot="start">
                    <IonRouterLink href={props.href}>
                      <IonImg src={appInfo.icon_url} className="height-32 width-32 app-icon" />
                    </IonRouterLink>
                  </IonButtons>
                ) : (
                  <IonButtons slot="start">
                    <IonButton onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}>
                      <IonIcon icon={chevronBackOutline} className="height-32 width-32" style={{color: darkMode ? '#5ECBF7' : appInfo.color.color}} />
                    </IonButton>
                  </IonButtons>
                )}
              </>
            ) : (
              <IonButtons slot="start">
                <IonRouterLink href={`/${appInfo.slug}/top`}>
                  <IonImg src={appInfo.icon_url} className="height-32 width-32 app-icon" />
                </IonRouterLink>
              </IonButtons>
            )}
            <IonTitle className="ion-text-center font-20 text-bold" style={{color: darkMode ? '#5ECBF7' : appInfo.color.color}}>{props.title}</IonTitle>
            {props.href ? (
              <IonButtons slot="end">
                <IonLabel className="height-32 width-32"></IonLabel>
              </IonButtons>
            ) : (
              <IonMenuButton slot="end" style={{color: darkMode ? '#5ECBF7' : appInfo.color.color}} />
            )}
          </IonToolbar>
        </IonHeader>
      )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);

