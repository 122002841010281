import React, { useEffect, useState } from 'react';
import { Redirect, useParams, useLocation, useHistory } from 'react-router-dom';
import { fetchApps, showLoading, updateAuthUser, updateAppStatus, updateAppInfo } from '../actions';
import {connect} from 'react-redux';
import {
  IonLoading,
  IonContent,
  IonPage
} from '@ionic/react';
import * as API from '../apis/api';

const AppCheck = (props) => {
  const { app } = useParams()
  const { needAuth, both } = props
  const auth_token = sessionStorage.getItem('auth_token')
  const location = useLocation()
  let history = useHistory();

  const { appInfo, showLoading, topBanner, darkMode } = props.appReducer;

  let { app_status } = props.appReducer
	app_status = app_status ?? {}
  const { me } = props.userReducer;

  const checkAuth = async () => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
    if (!both) {
      if (needAuth) {
        if (!auth_token) {
          history.push(`/${app}/login`)
          return false
        }
        if (me.status == 'register') {
          history.push(`/${app}/register/info`)
          return false
        }
        if (me.status == 'app_setting') {
          history.push(`/${app}/register/organization`)
          return false
        }

        if (location.pathname.indexOf('register/info') > 0 || location.pathname.indexOf('register/organization') > 0) {
          history.push(`/${app}/top`)
          return false
        }
      } else {
        if (auth_token) {
          history.push(`/${app}/top`)
          return false
        } else {
          return false
        }
      }
    }

    if (location.pathname.indexOf('start') < 0) {
      try {
        const response = await API.post_request({
          endPoint: 'auth/check',
          params: {
            slug: app
          }
        })
        if (response.status == 'failed') {
          throw new Error('Not Authenticated')
        }
        props.updateAuthUser(response.data)

        if (topBanner) history.push(`/${appInfo.slug}/banner/top`)
      } catch (error) {
        sessionStorage.removeItem('auth_token')
        props.updateAuthUser({})
        app_status = Object.assign(app_status, { can_register: false })
        props.updateAppStatus(app_status)
        history.push(`/${app}/login`)
      }
    }
  }

  const checkApp = async () => {
    if (!appInfo.slug || (appInfo.slug && appInfo.slug != app)) {
      try {
        let response = await API.get_request({
          endPoint: 'apps',
          params: {
            slug: app
          }
        })

        if (response.slug != app) {
          props.updateAppInfo({})
          history.push('/')
        } else {
          props.updateAppInfo(response)
        }
      } catch (error) {
        history.push('/')
        props.updateAppInfo({})
      }
    } else {
      props.fetchApps(app);
    }
  }

  useEffect(() => {
    props.showLoading(false)
    checkAuth()
    checkApp()
  }, []);

	return (
		<IonPage>
      <IonContent fullscreen>
        <IonLoading
          cssClass="custom-loading-css"
          isOpen={showLoading}
          spinner="crescent" />
      </IonContent>
    </IonPage>
  );
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchApps: (slug) => dispatch(fetchApps(slug)),
    showLoading: (value) => dispatch(showLoading(value)),
		updateAppStatus: (status) => dispatch(updateAppStatus(status)),
    updateAuthUser: (value) => dispatch(updateAuthUser(value)),
    updateAppInfo: (value) => dispatch(updateAppInfo(value))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AppCheck);
