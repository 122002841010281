import { IonContent, IonPage, IonIcon, IonGrid, IonCol, IonRow, IonLoading, IonText, IonRouterLink, IonBadge, IonImg } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { fetchApps, showLoading } from '../../actions';
import {connect} from 'react-redux';
import * as API from '../../apis/api';
import Swal from "sweetalert2";  
import { chevronForwardOutline } from 'ionicons/icons';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import styles from './Index.module.scss';

const Index = (props) => {
  const { category } = useParams()
  let history = useHistory();
  const { appInfo } = props.appReducer
  const { me } = props.userReducer
  const [ loading, setLoading ] = useState({ status: false, text: '読込中…' })
  const [ page, setPage ] = useState(1)
  const [ hasMore, setHasMore ] = useState(true)
  const [ categoryItem, setCategoryItem ] = useState({})
  const [ meetings, setMeetings ] = useState([])
  const contentRef = useRef()
  const [ categoryItemStyle, setCategoryItemStyle ] = useState({})
  const [ banners, setBanners ] = useState([])

	const loadMeetings = async () => {
    try {
      setLoading({ status: true, text: meetings.length === 0 ? '読込中…' : 'さらに読込中…' })

      let response = await API.post_request({
        endPoint: `meetings/${category}`,
        params: {
          page: page
        }
      })

      setLoading({ status: false, text: '' })
      response = response.data

      setMeetings([...meetings, ...response.data])

      setPage(response.current_page + 1)
      setHasMore(response.last_page > response.current_page)
    } catch (error) {
      setLoading({ status: false, text: '' })

      Swal.fire({  
        icon: 'error',  
        text: error.message, 
        showConfirmButton: false,
        timer: 3000 
      }); 
    }
  }

  const onScrollHandler = async (e) => {
    if (loading.status) return;

    const scrollElement = await e.target.getScrollElement();

    const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
    const currentScrollDepth = scrollElement.scrollTop;
    const targetPercent = 80;

    let triggerDepth = ((scrollHeight / 100) * targetPercent);

    if(currentScrollDepth > triggerDepth) {
      loadMeetings()
    }
  }

  const init = async () => {
    if (me.id) {
      let categories = me.profile.my_meeting_categories.filter(meeting => meeting.id == category)
      if (categories.length > 0) {
        setCategoryItem({ ...categories[0] })
        setMeetings([])
        setPage(1)
        setHasMore(false)

        setCategoryItemStyle({
          backgroundColor: `${categories[0].background_color}`
        })
        
        setLoading({ status: true, text: meetings.length === 0 ? '読込中…' : 'さらに読込中…' })

        loadMeetings()
      } else {
        history.push(`/${appInfo.slug}/top`)
      }
    }
  }

  const renderMeeting = (meeting) => {
    const style = {
      backgroundColor: `${meeting.category ? meeting.category.background_color : categoryItem.background_color}`
    }
    return (
      <IonRouterLink routerLink={`/${appInfo.slug}/meetings/${meeting.meeting_category_id}/${meeting.id}`}>
        <div className="d-flex align-items-center ion-justify-content-between border-bottom-1 py-10">
          <div className="d-flex align-items-top flex-1 ion-justify-content-between">
            <IonImg src={meeting.category ? meeting.category.icon_url : categoryItem.icon_url} style={style} className="width-50 height-50 px-10 py-10 mr-12 border-radius-round" />
            {/* <IonImg src={meeting.category ? meeting.category.icon_url : categoryItem.icon_url} style={style} className="width-50 height-50 px-10 py-10 mr-12" /> */}
            <div className="flex-1">
              <div className="mb-10 article-title max-height-40 overflow-hidden">
                { renderStatusBadge(meeting) }
                <IonText className="ml-8 text-bold font-16 color-black word-break-all line-height-20">{meeting.title}</IonText>
              </div>
              <div className="d-flex align-items-center justify-content-between color-black-light font-12 w-100">
                <div className="flex-1">投稿日：{meeting.publish_at_label}</div>
                <div>投稿者：{meeting.user_name}</div>
              </div>
              <div className="d-flex align-items-center justify-content-between color-black-light font-12 w-100">
                <div className="flex-1">返答期限：{meeting.reply_at_label}</div>
              </div>
            </div>
          </div>
          <div>
            <IonIcon icon={chevronForwardOutline} className="height-32 width-32 color-light-gray ml-10" />
          </div>
        </div>
      </IonRouterLink>
    )
  }

  const renderStatusBadge = (meeting) => {
    let type = 'unread'
    me.meeting_answers.map(answer => {
      if (answer.meeting_id == meeting.id) {
        if (answer.type === 'delegation') {
          type = 'delegation'
        } else {
          type = 'read'
        }
      }
    })

    if (type === 'read') {
      return <IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-light-gray color-black vertical-align-middle">回答済</IonBadge>
    } else if (type === 'delegation') {
      return <IonBadge color="medium" className="px-8 py-4 border-radius-12 font-12 back-library color-black vertical-align-middle color-white">委　任</IonBadge>
    }

    return <IonBadge color="success" className="px-8 py-4 border-radius-12 font-12 back-pink vertical-align-middle color-white">未回答</IonBadge>
  }

  useEffect(() => {
    init()
  }, []);

	return (
		<IonPage>
			{(appInfo.slug && appInfo.color && me.id) ? (
				<>
          <Header title={categoryItem.name}></Header>
          <IonContent ref={contentRef} fullscreen onIonScrollEnd={(e) => onScrollHandler(e)} scrollEvents={hasMore}>
            <IonGrid className="ion-padding px-10 py-10">
              <IonRow>
                {(meetings.length > 0) && (
                  <IonRouterLink size="12" routerLink={`/${appInfo.slug}/meetings/${meetings[0].meeting_category_id}/${meetings[0].id}`}>
                    <IonRow className="border-bottom-1 mb-0 py-10">
                      <IonCol size="12" className="d-flex align-items-top ion-justify-content-between">
                        {meetings[0].category ? (
                          <IonImg src={meetings[0].category.icon_url} className="width-100 height-100 mr-10 px-10 py-10" style={{backgroundColor: `${meetings[0].category.background_color}`}} />
                        ) : (
                          <IonImg src={categoryItem.icon_url} className="width-100 height-100 mr-10 px-10 py-10" style={categoryItemStyle} />
                        )}
                        <IonRow className="flex-1">
                          <IonCol size="12" className="mb-10 article-title max-height-40 height-40 overflow-hidden px-0 py-0">
                            { renderStatusBadge(meetings[0]) }
                            <IonText className="ml-8 text-bold font-16 color-black word-break-all line-height-20">{meetings[0].title}</IonText>
                          </IonCol>
                          <IonCol size="12" className="color-black font-14 mb-0 px-0 py-0"><strong>投稿日</strong>：{meetings[0].publish_at_label}</IonCol>
                          <IonCol size="12" className="color-black font-14 mb-0 px-0 py-0"><strong>投稿者</strong>：{meetings[0].user_name}</IonCol>
                          <IonCol size="12" className="color-black font-14 mb-0 px-0 py-0"><strong>返答期限</strong>：{meetings[0].reply_at_label}</IonCol>
                        </IonRow>
                      </IonCol>
                      <IonCol size="12" className="html-brief mb-0 color-black">{meetings[0].brief}</IonCol>
                    </IonRow>
                  </IonRouterLink>
                )}
                {meetings.filter((meeting, index) => index > 0).map((meeting, index) => {
                  return (
                    <IonCol size="12" className={styles.articleItem} key={index}>
                      {renderMeeting(meeting)}
                    </IonCol>
                  )
                })}
                <Loading loading={loading}></Loading>
                {(!loading.status && !hasMore) && (
                  <>
                    {meetings.length === 0 ? (
                      <IonCol size="12" className="ion-text-center py-20 px-20">表示できる記事が、見つかりませんでした。</IonCol>
                    ) : (
                      <IonCol size="12" className="ion-text-center py-20 px-20">表示できる記事が、これ以上見つかりませんでした。</IonCol>
                    )}
                  </>
                )}
                {hasMore && (
                  <div className="height-100">&nbsp;</div>
                )}
              </IonRow>
            </IonGrid>
          </IonContent>
				</>
			) : (
        <IonContent fullscreen>
          <IonLoading
            cssClass="custom-loading-css"
            isOpen={true}
            spinner="crescent" />
        </IonContent>
      )}
		</IonPage>
	);
};

// export default Home;
function mapStateToProps(state) {
  return {
    appReducer: state.appReducer,
    userReducer: state.userReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
		fetchApps: () => dispatch(fetchApps()),
    showLoading: (value) => dispatch(showLoading(value)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);

